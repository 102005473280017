import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../global/redux/action";
import { config } from "../../../config/config";
import { apiList, invokeApi } from "../../../services/apiServices";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;
  // const [email, setEmail] = useState("");
  // const [emailError, setEmailError] = useState(false);
  // const [emailHelperText, setEmailHelperText] = useState("");

  const [previousPassword, setPreviousPassword] = useState("");

  const [previousPasswordError, setPreviousPasswordError] = useState(false);

  const [previousPasswordHelperText, setPreviousPasswordHelperText] =
    useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordHelperText, setNewPasswordHelperText] = useState("");

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [confirmNewPasswordHelperText, setConfirmNewPasswordHelperText] =
    useState("");

  const handleLogout = () => {
    navigate("/Logout");
  };
  const handleHome = () => {
    navigate("/");
  };

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;

    // Validate password
    const hasUppercase = /[A-Z]/.test(newPasswordValue);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(newPasswordValue);
    const hasNumber = /\d/.test(newPasswordValue);
    const isLengthValid = newPasswordValue.length >= 8;

    setNewPassword(newPasswordValue);

    if (hasUppercase && hasSpecialCharacter && hasNumber && isLengthValid) {
      setNewPasswordError(false);
      setNewPasswordHelperText("Password is valid!");
    } else {
      setNewPasswordError(true);
      setNewPasswordHelperText(
        "Password must include an uppercase letter, a special character, at least one number, and be at least 8 characters long."
      );
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;

    // Validate that the confirm password matches the new password
    const isMatch = confirmPasswordValue === newPassword;

    setConfirmNewPassword(confirmPasswordValue);

    if (isMatch) {
      setConfirmNewPasswordError(false);
      setConfirmNewPasswordHelperText("Passwords match!");
    } else {
      setConfirmNewPasswordError(true);
      setConfirmNewPasswordHelperText("Passwords do not match.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var validationError = true;
    // if (!email) {
    //   setEmailError(true);
    //   setEmailHelperText("Please Enter Your Registered Email");
    //   validationError = false;
    // }
    if (!previousPassword) {
      setPreviousPasswordError(true);
      setPreviousPasswordHelperText("Please Enter Your Previous Password");
      validationError = false;
    }
    if (!newPassword) {
      setNewPasswordHelperText("Please Enter New Password");
      setNewPasswordError(true);
      validationError = false;
    }
    if (!confirmNewPassword) {
      setConfirmNewPasswordHelperText("Please Enter Confirm Password");
      setConfirmNewPasswordError(true);
      validationError = false;
    }
    if (validationError) {
      let params = {
        // email: email,
        password: previousPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.userChangePwd,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          alert("Your Password Was Changed Successfully");
          navigate("/dashboard");
        } else {
          alert(
            "Something went wrong while Changing Your Password. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        if (
          response.data.responseMessage ===
          "password - is an empty or not in valid format"
        ) {
          alert("Please Check Your Previous Password");
        } else {
          alert(
            "Something went wrong while Changing Your Password. Please try again later!!"
          );
        }
      }
    }
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography variant="h4" noWrap component="div">
                Hi, {userData?.users.name.toUpperCase()}
              </Typography>
              <Typography variant="h5" noWrap component="div">
                Welcome To Get My Studies
              </Typography>
            </Box>
            <Box>
              <Button
                size="large"
                aria-label="search"
                color="inherit"
                onClick={handleHome}
              >
                Home
              </Button>
              <Button
                size="large"
                aria-label="search"
                color="inherit"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography variant="h4">Reset Your Password</Typography>
          <Typography variant="h6">
            Your Email : {userData?.users.email}
          </Typography>
        </Box>
        <Card
          sx={{
            width: "500px",

            maxHeight: "500px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <form onSubmit={handleSubmit}>
            <CardContent sx={{ display: "flex", flexDirection: "column" }}>
              {/* <TextField
                label="Email"
                sx={{ my: 1 }}
                value={email}
                error={emailError}
                helperText={emailHelperText}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              /> */}
              <TextField
                label="Previous Password"
                sx={{ my: 1 }}
                value={previousPassword}
                fullWidth
                error={previousPasswordError}
                helperText={previousPasswordHelperText}
                onChange={(e) => setPreviousPassword(e.target.value)}
              />
              <TextField
                label="New Password"
                sx={{ my: 1 }}
                value={newPassword}
                fullWidth
                error={newPasswordError}
                helperText={newPasswordHelperText}
                onChange={handleNewPasswordChange}
              />
              <TextField
                label="Confirm Your New Password"
                sx={{ my: 1 }}
                value={confirmNewPassword}
                fullWidth
                error={confirmNewPasswordError}
                helperText={confirmNewPasswordHelperText}
                onChange={handleConfirmPasswordChange}
              />
              <Button variant="contained" type="submit">
                Update
              </Button>
            </CardContent>
          </form>
        </Card>
      </Box>
    </>
  );
};

export default ChangePassword;
