import React from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { mobileNoValidation } from "../../../../common/common";
import SideBar from "./SideBar";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: { md: "50%", xs: "50%" },
  transform: "translate(-50%, -50%)",
  width: { md: "30%", sm: "50%", xs: "80%" },
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

const CourseFee = () => {
  const [cookies] = useCookies();
  const [openLeadModal, setLeadModalOpen] = useState(false);
  const [college, setCollege] = useState([]);

  const handleIsAddModal = () => {
    setLeadModalOpen((adding) => !adding);
  };

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [fullNameHelperText, setFullNameHelperText] = useState(false);

  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [emailIdHelperText, setEmailIdHelperText] = useState(false);

  const [course, setCourse] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [mobileNumberHelperText, setMobileNumberHelperText] = useState(false);
  const mobileNumberValidate = mobileNoValidation(mobileNumber);

  const handleApplyButtonClick = (id) => {
    // Check if the id is already in the array
    const isIdInArray = course.includes(`${id}`);

    // Update the state based on whether the id is in the array or not
    if (!isIdInArray) {
      setCourse((prevIds) => [...prevIds, `${id}`]);
    } else {
      setCourse((prevIds) =>
        prevIds.filter((courseId) => courseId !== `${id}`)
      );
    }
  };

  //Save the data
  const saveLead = async (e) => {
    e.preventDefault();

    if (mobileNumberValidate) {
      let validationError = false;

      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
        validationError = true;
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
        validationError = true;
      }
      if (!validationError) {
        let params = {
          studentName: fullName,
          coursesList: course,
          mobileNumber: mobileNumber,
          email: emailId,
          collegeId: college.id,
        };
        let response = await invokeApi(
          config.getMyCollege + apiList.saveLead,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          if (response.data.responseCode === "200") {
            handleIsAddModal();
            toast.success("Applied Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFullName("");
            setFullNameHelperText("");
            setFullNameError(false);
            setCourse("");
            setCourse("");

            setMobileNumber("");
            setMobileNumberError(false);
            setMobileNumberHelperText("");

            setEmailId("");
            setEmailIdError(false);
            setEmailIdHelperText("");
          } else {
            alert(
              "Something went wrong while saving the Leading Details. Please try again later!"
            );
          }
        } else {
          alert(
            "Something went wrong while saving the Leading Details. Please try again later!!"
          );
        }
      }
    } else {
      setMobileNumberHelperText("Please Enter Valid Mobile Number");
      setMobileNumberError(true);
      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
      }
    }
  };

  // Group specialization names by course names
  const groupedCourses = [];
  college.collegeCourses?.forEach((row) => {
    const existingCourse = groupedCourses.find(
      (course) => course.courseName === row.courseName
    );
    if (!existingCourse) {
      groupedCourses.push({
        courseName: row.courseName,
        specializations: [row.courseSpecializationName], // Initialize array for specialization names
      });
    } else {
      existingCourse.specializations.push(row.courseSpecializationName); // Add specialization name to existing course
    }
  });

  return (
    <>
      <CollegeDetailHeader activeTab={1} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3 }}>
              <Typography
                sx={{
                  backgroundColor: "#F2F2F2",
                  p: 1,
                  fontWeight: "bold",
                  color: "#000",
                  fontSize: { xs: "14px", md: "18px" },
                  textAlign: { xs: "center", md: "initial" },
                }}
                variant="subtitle1"
              >
                Course Fee Structure And List Of Courses Summary
              </Typography>
              {college.courseFeeSummary ? (
                <Box
                  dangerouslySetInnerHTML={{ __html: college.courseFeeSummary }}
                  sx={{ my: 2 }}
                />
              ) : null}
              <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#162E7C" }}>
                      <TableCell></TableCell>
                      <TableCell align="left" sx={{ color: "#fff" }}>
                        Courses
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#fff" }}>
                        Average Fee
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#fff" }}>
                        Capacity
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#fff" }}>
                        Duration
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#fff" }}>
                        Apply
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {college.collegeCourses?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">
                          <Checkbox
                            onChange={() => handleApplyButtonClick(row.id)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {row.courseName} ({row.courseSpecializationName})
                        </TableCell>
                        <TableCell align="left">
                          {new Intl.NumberFormat("en-IN", {
                            maximumSignificantDigits: 3,
                            style: "currency",
                            currency: "INR",
                          }).format(row.courseFee)}
                        </TableCell>
                        <TableCell align="left">{row.batchCapacity}</TableCell>
                        <TableCell align="left">
                          {row.years + "Y " + "/ " + row.months + "M"}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ backgroundColor: "#F5374D" }}
                            onClick={() => {
                              setCourse([...course, `${row.id}`]);
                              handleIsAddModal();
                            }}
                          >
                            Apply
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Button
                          size="small"
                          variant="contained"
                          disabled={!course}
                          sx={{ backgroundColor: "#F5374D" }}
                          onClick={() => {
                            handleIsAddModal();
                          }}
                        >
                          Apply
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              {college.collegeCourses && college.collegeCourses.length > 0 ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      height: "45px",
                      width: "auto",
                      backgroundColor: "#F2F2F2",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      display="flex"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ fontSize: { md: "18px", xs: "14px" } }}
                    >
                      College Courses and Specializations
                    </Typography>
                  </Typography>
                  <Table
                    sx={{
                      width: "100%",
                      overflowX: "auto", // Enable horizontal scrolling on small screens
                      border: "1px solid #ddd",
                      my: 2,
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#162E7C" }}>
                        <TableCell sx={{ color: "#fff" }}>Course</TableCell>
                        <TableCell sx={{ color: "#fff" }}>
                          Specialization
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupedCourses.map((rows) => (
                        <TableRow
                          key={rows.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              borderBottom: "1px solid #ddd",
                              borderRight: "1px solid #ddd",
                              fontSize: "16px",
                              "@media (max-width:700px)": {
                                fontSize: "14px", // Adjust font size for small screens
                                width: "100%", // Adjust width to take the full screen
                              },
                            }}
                          >
                            {rows.courseName}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "1px solid #ddd",
                              width: "300px",
                              fontSize: "16px",
                              "@media (max-width:700px)": {
                                fontSize: "14px", // Adjust font size for small screens
                                width: "100%", // Adjust width to take the full screen
                              },
                            }}
                          >
                            {rows.specializations.join(", ")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {college.collegeCourses?.map((item) => (
                    <>
                      <Typography
                        variant="h6"
                        sx={{
                          marginBottom: "10px",
                          fontSize: { xs: "14px", md: "18px" },
                          fontWeight: "600",
                        }}
                      >
                        {item.courseName} Specialization:
                      </Typography>
                      <Typography
                        paddingLeft={"30px"}
                        sx={{ fontSize: { xs: "14px", md: "18px" } }}
                        height="6"
                      >
                        <ul sx={{ paddingLeft: "20px" }}>
                          <li>{item.courseSpecializationName}</li>
                        </ul>
                      </Typography>
                    </>
                  ))}
                </>
              ) : null}
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
      <Modal open={openLeadModal} onClose={handleIsAddModal}>
        <Box sx={style}>
          <IconButton
            onClick={() => {
              handleIsAddModal();
            }}
            sx={{ float: "right", marginTop: { xs: "-12px" } }}
          >
            <CloseIcon fontSize="mediam" color="black" />
          </IconButton>
          <DialogTitle sx={{ marginTop: { xs: "-30px" } }}>Apply</DialogTitle>

          <DialogContent>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Name"
                  value={fullName}
                  style={{ marginTop: { xs: "10px" } }}
                  onChange={(ev) => {
                    setFullName(ev.target.value);
                    setFullNameError(false);
                    setFullNameHelperText("");
                  }}
                  helperText={fullNameHelperText}
                  error={fullNameError}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Mobile Number"
                  value={mobileNumber}
                  onChange={(ev) => {
                    setMobileNumber(ev.target.value);
                    setMobileNumberError(false);
                    setMobileNumberHelperText("");
                  }}
                  helperText={mobileNumberHelperText}
                  error={mobileNumberError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <>
                          {/* flag and country code */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "0px",
                              gap: "5px",
                            }}
                          >
                            <Box
                              component={"img"}
                              sx={{ width: "24px", height: "24px" }}
                              alt="india"
                              src="/media/svg/indianflag.svg"
                            />
                            <Typography
                              variant="bodyparagraph"
                              sx={{
                                color: "text.primary",
                              }}
                            >
                              +91
                            </Typography>
                          </Box>
                          {/* vertical line */}
                          <Box
                            sx={{
                              width: { md: "26px", xs: "20px" },
                              height: "0px",
                              border: "0.5px solid #AAACAE",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 10 }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email Id"
                  value={emailId}
                  onChange={(ev) => {
                    setEmailId(ev.target.value);
                    setEmailIdError(false);
                    setEmailIdHelperText("");
                  }}
                  helperText={emailIdHelperText}
                  error={emailIdError}
                ></TextField>
              </Grid>
              <Button type="submit" onClick={saveLead} variant="contained">
                Submit
              </Button>
            </Grid>
          </DialogContent>
        </Box>
      </Modal>
    </>
  );
};

export default CourseFee;
