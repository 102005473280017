import React, { useEffect, useState } from "react";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import TextEditor from "../colleges/TextEditor";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  apiList,
  invokeApi,
  invokeFormDataApi,
} from "../../../services/apiServices";
import { config } from "../../../config/config";
import DashboardNav from "../home/DashboardNav";
import DashboardHeader from "../home/DashboardHeader";
import { Bounce, toast } from "react-toastify";

const gender = ["Boys", "Girls", "Co-Ed"];

const eduType = [
  "Play Home",
  "Nursary",
  "Primary School",
  "Middle School",
  "High School",
  "Senior Secondary",
];

const boardsList = ["CBSE", "IGCSE", "State Board", "IB", "NIOS", "AISSCE"];

const EditSchool = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const { schoolId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [subjectDrawer, setSubjectDrawer] = useState(false);
  const [school, setSchool] = useState({
    name: "",
    error: false,
    helperText: "",
  });

  const [schoolSlug, setSchoolSlug] = useState({
    value: "",
    error: false,
    helperText: "",
  });
  const [establishedYear, setEstablishedYear] = useState({
    year: "",
    error: false,
    helperText: "",
  });
  const [description, setDescription] = useState("");
  const [board, setBoard] = useState({
    name: "",
    error: false,
    helperText: "",
  });
  const eduTypeObject = {
    educationType: "",
    subjects: [""],
    educationTypeFee: "",
    firstLanguage: "",
    secondLanguage: "",
    thirdLanguage: "",
  };
  const [eduTypeDetails, setEduTypeDetails] = useState([eduTypeObject]);
  const [programList, setProgramList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [genderClassify, setGenderClassifty] = useState({
    value: "",
    error: false,
    helperText: "",
  });

  // gallery image, preview, gallery images path and validation
  const [previewImages, setPreviewImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImageURLs, setGalleryImageURLs] = useState([]);
  console.log("gallery", galleryImageURLs);
  const [galleryImageError, setGalleryImageError] = useState(false);
  const [galleryImageHelperText, setGalleryImageHelperText] = useState("");

  // college logo, preview, college logo path and validation
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [schoolLogoError, setSchoolLogoError] = useState(false);
  const [schoolLogoHelperText, setSchoolLogoHelperText] = useState("");
  const [previewSchoolLogo, setPreviewSchoolLogo] = useState(null);
  const [schoolLogomaxFileSizeErr, setSchoolLogoMaxFileSizeErr] = useState("");
  const [schoolLogoPath, setSchoolLogoPath] = useState(null);

  // country selected and validation
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [countryHelperText, setCountryHelperText] = useState("");

  // state selected and validation
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);
  const [stateHelperText, setStateHelperText] = useState("");

  // district selected and validation
  const [district, setDistrict] = useState("");
  const [districtError, setDistrictError] = useState(false);
  const [districtHelperText, setDistrictHelperText] = useState("");

  // area and validation
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [addressHelperText, setAddressHelperText] = useState("");

  // pincode selected and validation
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState(false);
  const [pincodeHelperText, setPincodeHelperText] = useState("");

  // below the fuction related to add and remove edu type

  const filterEduTypeDetails = () => {
    const filteredDetails = eduTypeDetails.filter((item) => {
      // Check if educationType and educationTypeFee are not empty
      return !(item.educationType === "" && item.educationTypeFee === "");
    });

    // Update state with the filtered array
    setEduTypeDetails(filteredDetails);
  };

  const handleAddEduType = () => {
    setEduTypeDetails((prevEduType) => [...prevEduType, eduTypeObject]);
  };

  const handleRemoveEduType = (index) => {
    const eduTypeAll = [...eduTypeDetails];
    eduTypeAll.splice(index, 1);
    setEduTypeDetails(eduTypeAll);
  };

  const handleAddSubject = (index) => {
    const updatedEduTypeDetails = [...eduTypeDetails];
    updatedEduTypeDetails[index].subjects.push("");
    setEduTypeDetails(updatedEduTypeDetails);
  };

  const handleRemoveSubject = (eduTypeIndex, subIndex) => {
    const updatedEduTypeDetails = [...eduTypeDetails];
    updatedEduTypeDetails[eduTypeIndex].subjects.splice(subIndex, 1);
    setEduTypeDetails(updatedEduTypeDetails);
  };

  // below the code is for slug url based on school name
  const handleSchoolNameChange = (e) => {
    const newValue = e.target.value;
    setSchool({ name: newValue, error: false, helperText: "" });
    const formattedValue = newValue.replace(/ /g, "-").toLowerCase();
    setSchoolSlug({
      value: formattedValue,
      error: false,
      helperText: "",
    });
  };

  // pincode dropdown options function
  const pincodeList = (
    addressList
      .find((item) => item.countryName === country)
      ?.states.find((item) => item.stateName === state)
      ?.districts.find((item) => item.districtName === district)?.pincodes || []
  )?.map((pincode) => pincode.pincode);

  // district options function
  const districtList = (
    addressList
      .find((item) => item.countryName === country)
      ?.states.find((item) => item.stateName === state)?.districts || []
  )?.map((district) => district.districtName);

  // gallery image validation
  const handleImageGalleryUpload = async (ev) => {
    setGalleryImageError(false);
    setGalleryImageHelperText("");
    if (ev.target.files) {
      const images = ev.target.files;
      const compressedImages = [];

      for (let i = 0; i < images.length; i++) {
        const img = images[i];
        if (!img.type.match(/^image\/(jpe?g|png|gif)/)) {
          alert("Please upload a valid image in jpeg/jpg/png/gif format");
        } else {
          const compressedImg =
            img.size > 512 * 512 ? await compressImage(img) : img;
          compressedImages.push(compressedImg);
        }
      }

      setPreviewImages((prevImages) =>
        prevImages
          .slice(0, 14)
          .concat(compressedImages?.map((img) => URL.createObjectURL(img)))
      );
      setGalleryImages((prevImages) =>
        prevImages.slice(0, 14).concat(compressedImages)
      );
    }
  };

  // gallery image comperss each
  const compressImage = (image) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_SIZE = 1024 * 1024;
          let width = img.width;
          let height = img.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], image.name, {
                type: image.type,
              });
              resolve(compressedFile);
            },
            image.type,
            0.8
          );
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(image);
    });
  };

  // Remove gallery image
  const deleteImageGallery = (idx) => {
    let gllryImages = galleryImages;
    gllryImages.splice(idx, 1);
    setGalleryImages([...gllryImages]);

    let prvwImages = previewImages;
    prvwImages.splice(idx, 1);
    setPreviewImages([...prvwImages]);
  };
  // Remove prev gallery image
  const deletePrevImageGallery = (idx) => {
    let gllryImages = galleryImageURLs;
    gllryImages.splice(idx, 1);
    setGalleryImageURLs([...gllryImages]);
  };

  const handleFileUpload = (ev) => {
    ev.preventDefault();
    const fileUploaded = ev.target.files[0];
    let acceptProfileFileTypes = fileUploaded.type.match(
      /^image\/(jpe?g|png|gif)/
    );

    if (fileUploaded && acceptProfileFileTypes) {
      if (fileUploaded.size < 512 * 512) {
        setSchoolLogo(fileUploaded);
        setPreviewSchoolLogo(URL.createObjectURL(fileUploaded));
        setSchoolLogoMaxFileSizeErr("");
        handleUploadFile(fileUploaded);
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_SIZE = 512 * 512;
            let width = image.width;
            let height = image.height;

            while (width * height > MAX_SIZE) {
              width *= 0.9;
              height *= 0.9;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], fileUploaded.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });
                setSchoolLogo(compressedFile);
                setPreviewSchoolLogo(URL.createObjectURL(compressedFile));
                setSchoolLogoMaxFileSizeErr("");
                handleUploadFile(compressedFile);
              },
              "image/jpeg",
              0.8
            );
          };
        };
        reader.readAsDataURL(fileUploaded);
      }
    } else {
      setSchoolLogoMaxFileSizeErr(
        "Please upload a valid image in jpeg/jpg/png/gif format"
      );
    }
  };

  const handleUploadFile = async (ev) => {
    let formData = new FormData();
    formData.append("file", ev);
    formData.append("path", "school_logo");

    let response = await invokeFormDataApi(
      config.getMyCollege + apiList.uploadFile,
      formData,
      cookies
    );
    if (response?.status >= 200 && response?.status < 300) {
      if (response.data.responseCode === "200") {
        setSchoolLogoPath(response.data.url);
      } else {
        alert(
          "Something went wrong while uploading shool picture. Please try again later!"
        );
      }
    } else if (response?.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while uploading shool picture. Please try again later!!"
      );
    }
  };

  const handleSaveGallery = async (e) => {
    e.preventDefault();
    let validate = true;
    if (!school.name) {
      validate = false;
      setSchool({
        name: "",
        error: true,
        helperText: "Please Enter School Name",
      });
    }

    if (!schoolSlug.value) {
      validate = false;
      setSchoolSlug({
        value: "",
        error: true,
        helperText: "Please Enter School Name for auto fill field",
      });
    }
    if (!establishedYear.year) {
      validate = false;
      setEstablishedYear({
        year: "",
        error: true,
        helperText: "Please Enter School Established Year",
      });
    }
    if (!country) {
      validate = false;
      setCountryError(true);
      setCountryHelperText("Please Select the Country");
    }
    if (!state) {
      validate = false;
      setStateError(true);
      setStateHelperText("Please Select the State");
    }
    if (!district) {
      validate = false;
      setDistrictError(true);
      setDistrictHelperText("Please Select the City");
    }
    if (!pincode) {
      validate = false;
      setPincodeError(true);
      setPincodeHelperText("Please Select the Postal Code");
    }
    if (!description) {
      validate = false;
      alert("Please Enter About Your School in Description Field");
    }
    if (!eduTypeDetails[0].educationType) {
      validate = false;
      alert("Please Enter Min 1 Education Type Detail");
    }
    if (validate) {
      for (let i = 0; i < galleryImages.length; i++) {
        let formDataGallery = new FormData();
        formDataGallery.append("file", galleryImages[i]);
        formDataGallery.append("path", "School_Gallery_Image");

        let responseGall = await invokeFormDataApi(
          config.getMyCollege + apiList.uploadFile,
          formDataGallery,
          cookies
        );

        if (responseGall?.status >= 200 && responseGall?.status < 300) {
          if (responseGall.data.responseCode === "200") {
            setGalleryImageURLs((prevState) => [
              ...prevState,
              responseGall.data.url,
            ]);
          } else {
            alert(
              "Something went wrong while uploading Gallery images. Please try again later!"
            );
          }
        } else if (responseGall?.status === 401) {
          navigate("/logout");
        } else {
          alert(
            "Something went wrong while uploading Gallery images. Please try again later!!"
          );
        }
      }

      handleUpdateSchool();
    }
  };

  const handleUpdateSchool = async () => {
    filterEduTypeDetails();
    let params = {
      id: schoolId,
      schoolName: school.name,
      schoolSlug: schoolSlug.value,
      schoolBoard: board.name,
      schoolGenderClassification: genderClassify.value,
      country: country,
      state: state,
      city: district,
      address: address,
      pincode: pincode,
      establishedYear: establishedYear.year,
      description: description,
      schoolLogo: schoolLogoPath,
      schoolGalleryImages: galleryImageURLs,
      eduTypeDetails: eduTypeDetails,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateSchool,
      params,
      cookies
    );
    if (response?.status >= 200 && response?.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("SuccessFully Updated!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        navigate("/school-list");
      } else {
        alert(
          "Something went wrong while getting the School Details Update. Please try again later!"
        );
      }
    } else if (response?.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while getting the School Details Update. Please try again later!!"
      );
    }
  };

  useEffect(() => {
    const getAllProgram = async () => {
      let params = {
        status: "Active",
        instituteType: "school",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setProgramList(response.data.programs);
        } else {
          alert(
            "Something went wrong while getting the Program Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Program Details. Please try again later!!"
        );
      }
    };
    const getAllAddress = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAddress,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setAddressList(response.data.countries);
        } else {
          alert(
            "Something went wrong while getting the address Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the address Details. Please try again later!!"
        );
      }
    };
    getAllAddress();
    getAllProgram();
  }, [cookies]);

  // search address and auto fill location
  const [markerPosition, setMarkerPosition] = useState({
    lat: config.defaultLocation.lat,
    lng: config.defaultLocation.lng,
  });

  // below the code is for google map
  useEffect(() => {
    let url =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      markerPosition.lat +
      "," +
      markerPosition.lng +
      "&key=" +
      config.googleMapKey;

    function updateState(type, state, setter, longName) {
      if (type) {
        setter(longName);
      }
    }

    fetch(url, {
      method: "get",
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.results.length > 0) {
          const data = res.results[0].address_components;
          for (let i = 0; i < data.length; i++) {
            const object = data[i];
            const types = object.types;

            updateState(
              types.includes("country"),
              country,
              setCountry,
              object.long_name
            );
            updateState(
              types.includes("administrative_area_level_1"),
              state,
              setState,
              object.long_name
            );
            updateState(
              types.includes("administrative_area_level_3"),
              district,
              setDistrict,
              object.long_name
            );
            updateState(
              types.includes("postal_code"),
              pincode,
              setPincode,
              object.long_name
            );
            updateState(
              // types.includes("sublocality") ||
              types.includes("sublocality"),
              // types.includes("sublocality_level_1"),
              address,
              setAddress,
              object.long_name
            );
          }
        }
      });
  }, [markerPosition]);

  useEffect(() => {
    const getSchoolDetail = async () => {
      let params = {
        id: schoolId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getSchool,
        params,
        cookies
      );

      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          const schoolDetails = response.data.school;
          setSchool((prevSchool) => ({
            ...prevSchool, // Preserve existing state values
            name: schoolDetails?.schoolName, // Update the name
          }));
          setSchoolSlug((prevSlug) => ({
            ...prevSlug,
            value: schoolDetails?.schoolSlug,
          }));
          setAddress(schoolDetails?.address);
          setDistrict(schoolDetails?.city);
          setCountry(schoolDetails?.country);
          setDescription(schoolDetails?.description);
          setEduTypeDetails(
            schoolDetails?.eduTypeDetails.concat(eduTypeObject)
          );
          setEstablishedYear((prev) => ({
            ...prev,
            year: schoolDetails?.establishedYear,
          }));
          setPincode(schoolDetails?.pincode);
          setBoard((prev) => ({
            ...prev,
            name: schoolDetails?.schoolBoard,
          }));
          setGenderClassifty((prev) => ({
            ...prev,
            value: schoolDetails?.schoolGenderClassification,
          }));
          setGalleryImageURLs(schoolDetails?.schoolGalleryImages);
          // setPreviewImages(schoolDetails?.schoolGalleryImages);
          // setPreviewImages(schoolDetails?.schoolGalleryImages);
          setSchoolLogoPath(schoolDetails?.schoolLogo);
          setSchoolLogo(schoolDetails?.schoolLogo);
          setPreviewSchoolLogo(schoolDetails?.schoolLogo);
          setState(schoolDetails?.state);
        } else {
          alert(
            "Something went wrong while getting the School Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the School Details. Please try again later!!"
        );
      }
    };
    if (fetching) {
      getSchoolDetail();
      setFetching(false);
    }
  }, [schoolId, fetching]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{ flexGrow: 1, p: 2, backgroundColor: "#F6F8FB" }}
        >
          <DashboardHeader />
          <form onSubmit={handleSaveGallery}>
            <Paper sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Enter School Name"
                    autoComplete="off"
                    margin="normal"
                    value={school.name}
                    error={school.error}
                    helperText={school.helperText}
                    onChange={handleSchoolNameChange}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled
                    margin="normal"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    error={schoolSlug.error}
                    helperText={schoolSlug.helperText}
                    value={schoolSlug.value}
                    placeholder="school slug automatic based on school name"
                  />
                </Grid>
                <Grid item md={4}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    value={board.name}
                    options={boardsList}
                    onChange={(event, newValue) => {
                      setBoard({
                        name: newValue,
                        error: false,
                        helperText: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={board.error}
                        helperText={board.helperText}
                        {...params}
                        label="Select Board"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={gender}
                    value={genderClassify.value}
                    onChange={(event, newValue) =>
                      setGenderClassifty({
                        value: newValue,
                        error: false,
                        helperText: "",
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Gender Classify" />
                    )}
                  />
                </Grid>
                <Grid item md={4} sx={{ textAlign: "center" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => setSubjectDrawer(true)}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Add Subjects & Education Type & Fee
                  </Button>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              {/* below the container have address fields  */}
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography variant="h4">Address</Typography>
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    fullWidth
                    freeSolo={true}
                    value={country}
                    options={addressList?.map((item) => item.countryName)}
                    onChange={(e, newValue) => {
                      setCountry(newValue);
                      setCountryError(false);
                      setCountryHelperText("");
                    }}
                    error={countryError}
                    helperText={countryHelperText}
                    renderInput={(params) => (
                      <TextField
                        error={countryError}
                        helperText={countryHelperText}
                        {...params}
                        label="Country"
                        margin="dense"
                        variant="outlined"
                        onChange={(event, newValue) => {
                          if (!newValue) {
                            setCountry(event.target.value);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    fullWidth
                    onChange={(e, newValue) => {
                      setState(newValue);
                      setStateError(false);
                      setStateHelperText("");
                    }}
                    error={stateError}
                    helperText={stateHelperText}
                    value={state}
                    options={(
                      addressList.find((item) => item.countryName === country)
                        ?.states || []
                    )?.map((state) => state.stateName)}
                    freeSolo={true}
                    disabled={!country}
                    renderInput={(params) => (
                      <TextField
                        error={stateError}
                        margin="dense"
                        helperText={stateHelperText}
                        {...params}
                        label="State"
                        variant="outlined"
                        // value={state}
                        onChange={(event, newValue) => {
                          if (!newValue) {
                            setState(event.target.value);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    fullWidth
                    options={districtList}
                    value={districtList.includes(district) ? district : ""}
                    disabled={!state}
                    freeSolo
                    onChange={(e, newValue) => {
                      setDistrict(newValue);
                      setDistrictError(false);
                      setDistrictHelperText("");
                    }}
                    error={districtError}
                    helperText={districtHelperText}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        error={districtError}
                        helperText={districtHelperText}
                        label={
                          districtList.includes(district)
                            ? "District"
                            : "Please Select District(City)"
                        }
                        variant="outlined"
                        onChange={(event, newValue) => {
                          if (!districtList.includes(newValue)) {
                            setDistrict(newValue);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    fullWidth
                    value={pincodeList.includes(pincode) ? pincode : ""}
                    options={pincodeList}
                    disabled={!district}
                    freeSolo
                    onChange={(e, newValue) => {
                      setPincode(newValue);
                      setPincodeError(false);
                      setPincodeHelperText("");
                    }}
                    error={pincodeError}
                    helperText={pincodeHelperText}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        value={pincode}
                        label={
                          pincodeList.includes(pincode)
                            ? "Pincode"
                            : "Please Select Postal Code"
                        }
                        onChange={(event, newValue) => {
                          if (!pincodeList.includes(newValue)) {
                            setPincode(newValue);
                          }
                        }}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    type="text"
                    label="Enter Address"
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setAddressError(false);
                      setAddressHelperText("");
                    }}
                    value={address}
                    margin="dense"
                    error={addressError}
                    helperText={addressHelperText}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="Established Year"
                    value={establishedYear.year}
                    onChange={(e) => {
                      setEstablishedYear({
                        year: e.target.value,
                        error: false,
                        helperText: "",
                      });
                    }}
                    autoComplete="off"
                    error={establishedYear.error}
                    helperText={establishedYear.helperText}
                    inputProps={{
                      maxLength: 4,
                    }}
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <TextEditor
                    initialValue={description}
                    onEditorChange={(e) => setDescription(e)}
                  />
                </Grid>
              </Grid>
              {/* below the grid container for gallery image upload and preview  */}
              <Grid container spacing={2}>
                {/* school logo  */}
                <Grid item md={6}>
                  <Box
                    sx={{
                      border: schoolLogoError
                        ? "2px dashed red"
                        : "2px dashed #009688",
                      borderRadius: "15px",
                      padding: "20px",
                      backgroundColor: "#f5f5f5",
                      textAlign: "center",
                      cursor: "pointer",
                      transition: "border 0.3s",
                      ":hover": {
                        border: "2px dashed #005058",
                      },
                    }}
                  >
                    <label htmlFor="logo-pic-school">
                      <input
                        id="logo-pic-school"
                        type="file"
                        accept=".png, .jpg, .jpeg, .webp"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileUpload(e)}
                      />
                      {schoolLogo ? (
                        <img
                          src={previewSchoolLogo}
                          alt="school Logo"
                          style={{
                            width: 200,
                            height: 200,
                            objectFit: "cover",
                            borderRadius: "10px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      ) : (
                        <>
                          <CloudUploadIcon
                            sx={{
                              fontSize: 80,
                              color: schoolLogoError ? "#d32f2f" : "#009688",
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{
                              color: schoolLogoError ? "#d32f2f" : "#009688",
                              marginTop: "10px",
                            }}
                          >
                            {schoolLogoError
                              ? schoolLogoHelperText
                              : "Upload School Logo *"}
                          </Typography>
                          <Typography
                            variant="bodyparagraph"
                            sx={{ color: "#757575" }}
                          >
                            {schoolLogomaxFileSizeErr || "Max size: 1MB"}
                          </Typography>
                        </>
                      )}
                    </label>
                  </Box>
                </Grid>
                {/* gallery image input  */}
                <Grid item md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#0077B6",
                        }}
                      >
                        Image Gallery
                      </Typography>
                      <Typography
                        variant="bodyregular"
                        sx={{
                          fontSize: "16px",
                          textAlign: "center",
                          color: "#333",
                        }}
                      >
                        You can upload multiple images (max 15)
                      </Typography>
                    </Box>
                    {previewImages?.length !== 15 && (
                      <label htmlFor="image-gallery">
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="image-gallery"
                          multiple
                          type="file"
                          onClick={(ev) => (ev.target.value = "")}
                          onChange={handleImageGalleryUpload}
                        />
                        <Button
                          variant="contained"
                          component="span"
                          sx={{
                            background: galleryImageError
                              ? "#d32f2f"
                              : "#405189",
                            borderRadius: 5,
                            border: 0,
                            color: "white",
                            height: 48,
                            padding: "0 30px",

                            boxShadow: "#405189",
                            "&:hover": {
                              //you want this to be the same as the backgroundColor above
                              backgroundColor: galleryImageError
                                ? "#d32f2f"
                                : "#405189",
                            },
                          }}
                        >
                          {galleryImageError
                            ? galleryImageHelperText
                            : "Upload Images *"}
                          {/* <AddAPhotoIcon sx={{ mr: 2 }} /> */}
                        </Button>
                      </label>
                    )}
                  </Box>
                </Grid>

                {/* gallery image preview  */}
                <Grid item md={6}>
                  <Typography variant="h5">Previous Gallery Images</Typography>
                  <Box sx={{ overflowX: "auto", display: "flex" }}>
                    <ImageList
                      cols={3}
                      sx={{
                        flexWrap: "nowrap",
                      }}
                    >
                      {galleryImageURLs?.map((items, idx) => (
                        <>
                          <ImageListItem
                            key={idx}
                            sx={{
                              margin: "8px",
                              cursor: "pointer",
                              transition: "transform 0.3s",
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                            }}
                          >
                            <img
                              src={items}
                              loading="lazy"
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                              alt="gallery-img"
                            />
                            <ImageListItemBar
                              sx={{
                                background:
                                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                              }}
                              position="top"
                              actionPosition="right"
                              actionIcon={
                                <IconButton
                                  onClick={() => deletePrevImageGallery(idx)}
                                >
                                  <CloseIcon
                                    sx={{ color: "white" }}
                                  ></CloseIcon>
                                </IconButton>
                              }
                            />
                          </ImageListItem>
                        </>
                      ))}
                    </ImageList>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="h5">New Gallery Images</Typography>
                  <Box sx={{ overflowX: "auto", display: "flex" }}>
                    <br />
                    <ImageList
                      cols={3}
                      sx={{
                        flexWrap: "nowrap",
                      }}
                    >
                      {previewImages?.map((items, idx) => (
                        <>
                          <ImageListItem
                            key={idx}
                            sx={{
                              margin: "8px",
                              cursor: "pointer",
                              transition: "transform 0.3s",
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                            }}
                          >
                            <img
                              src={items}
                              loading="lazy"
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                              alt="gallery-img"
                            />
                            <ImageListItemBar
                              sx={{
                                background:
                                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                              }}
                              position="top"
                              actionPosition="right"
                              actionIcon={
                                <IconButton
                                  onClick={() => deleteImageGallery(idx)}
                                >
                                  <CloseIcon
                                    sx={{ color: "white" }}
                                  ></CloseIcon>
                                </IconButton>
                              }
                            />
                          </ImageListItem>
                        </>
                      ))}
                    </ImageList>
                  </Box>
                </Grid>
              </Grid>
              {/* belwo the grid container for submit button  */}
              <Grid container spacing={2}>
                <Grid item md={12} sx={{ textAlign: "center" }}>
                  <Button
                    sx={{ background: "gray", mr: 2 }}
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={() => {
                      navigate("/school-list");
                    }}
                  >
                    Back
                  </Button>
                  <Button variant="contained" size="large" type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Box>
      </Box>
      {/* below the drawer for add subjects  */}
      <Dialog
        fullScreen
        open={subjectDrawer}
        onClose={() => setSubjectDrawer(false)}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => setSubjectDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Education Type and Fees and Language
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setSubjectDrawer(false)}
            >
              Submit
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 5 }}>
          {eduTypeDetails?.map((item, index) => (
            <Paper elevation={3} sx={{ p: 2, mb: 2 }} key={index}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={eduType}
                    value={item?.educationType}
                    onChange={(e, newValue) => {
                      const updatedEduTypeDetails = [...eduTypeDetails];
                      updatedEduTypeDetails[index].educationType = newValue;
                      setEduTypeDetails(updatedEduTypeDetails);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={item?.educationType}
                        label="Education Type"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="Fees"
                    fullWidth
                    value={item?.educationTypeFee}
                    size="small"
                    onChange={(e) => {
                      const updatedEduTypeDetails = [...eduTypeDetails];
                      updatedEduTypeDetails[index].educationTypeFee =
                        e.target.value;
                      setEduTypeDetails(updatedEduTypeDetails);
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="First Language"
                    value={item?.firstLanguage}
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      const updatedEduTypeDetails = [...eduTypeDetails];
                      updatedEduTypeDetails[index].firstLanguage =
                        e.target.value;
                      setEduTypeDetails(updatedEduTypeDetails);
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Second Language"
                    value={item?.secondLanguage}
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      const updatedEduTypeDetails = [...eduTypeDetails];
                      updatedEduTypeDetails[index].secondLanguage =
                        e.target.value;
                      setEduTypeDetails(updatedEduTypeDetails);
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    label="Third Language"
                    fullWidth
                    size="small"
                    value={item?.thirdLanguage}
                    onChange={(e) => {
                      const updatedEduTypeDetails = [...eduTypeDetails];
                      updatedEduTypeDetails[index].thirdLanguage =
                        e.target.value;
                      setEduTypeDetails(updatedEduTypeDetails);
                    }}
                  />
                </Grid>
                {item?.subjects?.map((subjectItem, subIndex) => (
                  <Grid item md={3} key={subIndex}>
                    <TextField
                      label="Subject Name"
                      fullWidth
                      value={subjectItem}
                      size="small"
                      onChange={(e) => {
                        const updatedEduTypeDetails = [...eduTypeDetails];
                        updatedEduTypeDetails[index].subjects[subIndex] =
                          e.target.value;
                        setEduTypeDetails(updatedEduTypeDetails);
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <IconButton
                        sx={{ backgroundColor: "green" }}
                        onClick={() => handleAddSubject(index)}
                      >
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        sx={{ backgroundColor: "red" }}
                        onClick={() => handleRemoveSubject(index, subIndex)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ mt: 3, display: "flex" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: "green",
                    },
                  }}
                  onClick={handleAddEduType}
                >
                  Add
                </Button>
                {eduTypeDetails.length > 1 && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: "red",
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                    onClick={() => handleRemoveEduType(index)}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Paper>
          ))}
        </Box>
      </Dialog>
    </>
  );
};

export default EditSchool;
