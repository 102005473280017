import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";
import { mobileNoValidation } from "../../../../common/common";
import { toast } from "react-toastify";

const LeadModal = ({
  collegeId,
  btnValue,
  brochurePath,
  openLeadModal,
  handleClose,
}) => {
  const [cookies] = useCookies();
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [courseNameList, setCourseNameList] = useState([]);

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [fullNameHelperText, setFullNameHelperText] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [mobileNumberHelperText, setMobileNumberHelperText] = useState("");
  const mobileNumberValidate = mobileNoValidation(mobileNumber);
  const [isCourseFetching, setIsCourseFetching] = useState(true);
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [emailIdHelperText, setEmailIdHelperText] = useState("");

  // console.log("lead")

  const handleRemoveCourse = (prev) => {
    setSelectedCourse(selectedCourse.filter((r) => r !== prev.id));
  };

  const handleCourseChange = (event, newValue) => {
    const stringIds = newValue.map((company) => company.id.toString());

    setSelectedCourse(stringIds);
  };

  const downloadPDF = (pdfUrl) => {
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.target = "_blank";
    anchor.download = "lead_information.pdf";
    anchor.click();
  };

  useEffect(() => {
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseNameList(response.data.courses);
        } else {
          alert(
            "Something went wrong while getting the College Types Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the College Types Details. Please try again later!!"
        );
      }
    };
    if (isCourseFetching) {
      getAllCourseName();
      setIsCourseFetching(false);
    }
  }, [cookies]);

  const saveLead = async (e) => {
    e.preventDefault();

    if (mobileNumberValidate) {
      let validationError = false;

      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
        validationError = true;
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
        validationError = true;
      }
      if (!validationError) {
        let params = {
          studentName: fullName,
          mobileNumber: mobileNumber,
          coursesList: selectedCourse,
          email: emailId,
          collegeId: collegeId,
        };
        let response = await invokeApi(
          config.getMyCollege + apiList.saveLead,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          if (response.data.responseCode === "200") {
            handleClose();
            toast.success("Lead Created Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFullName("");
            setFullNameHelperText("");
            setFullNameError(false);

            setMobileNumber("");
            setMobileNumberError(false);
            setMobileNumberHelperText("");

            setEmailId("");
            setEmailIdError(false);
            setEmailIdHelperText("");

            setSelectedCourse("");
            if (btnValue === "brochure") {
              downloadPDF(brochurePath);
            }
          } else {
            alert(
              "Something went wrong while saving the Leading Details. Please try again later!"
            );
          }
        } else {
          alert(
            "Something went wrong while saving the Leading Details. Please try again later!!"
          );
        }
      }
    } else {
      setMobileNumberHelperText("Please Enter Valid Mobile Number");
      setMobileNumberError(true);
      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
      }
    }
  };

  return (
    <>
      <Dialog open={openLeadModal} onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Apply
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="dense"
                label="Name"
                value={fullName}
                style={{ marginTop: { xs: "10px" } }}
                onChange={(ev) => {
                  setFullName(ev.target.value);
                  setFullNameError(false);
                  setFullNameHelperText("");
                }}
                helperText={fullNameHelperText}
                error={fullNameError}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Mobile Number"
                value={mobileNumber}
                onChange={(ev) => {
                  setMobileNumber(ev.target.value);
                  setMobileNumberError(false);
                  setMobileNumberHelperText("");
                }}
                helperText={mobileNumberHelperText}
                error={mobileNumberError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <>
                        {/* flag and country code */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "0px",
                            gap: "5px",
                          }}
                        >
                          <Box
                            component={"img"}
                            sx={{ width: "24px", height: "24px" }}
                            alt="india"
                            src="/media/svg/indianflag.svg"
                          />
                          <Typography
                            variant="bodyparagraph"
                            sx={{
                              color: "text.primary",
                            }}
                          >
                            +91
                          </Typography>
                        </Box>
                        {/* vertical line */}
                        <Box
                          sx={{
                            width: { md: "26px", xs: "20px" },
                            height: "0px",
                            border: "0.5px solid #AAACAE",
                            transform: "rotate(90deg)",
                          }}
                        />
                      </>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Email Id"
                value={emailId}
                onChange={(ev) => {
                  setEmailId(ev.target.value);
                  setEmailIdError(false);
                  setEmailIdHelperText("");
                }}
                helperText={emailIdHelperText}
                error={emailIdError}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="scholarship-autocomplete"
                options={courseNameList}
                getOptionLabel={(option) => option.courseName}
                value={courseNameList.filter((company) =>
                  selectedCourse.includes(company.id.toString())
                )}
                fullWidth
                onChange={handleCourseChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Course"
                    fullWidth
                    margin="normal"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      label={option.courseName}
                      {...getTagProps({ index })}
                      onDelete={() => handleRemoveCourse(option)}
                    />
                  ))
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", my: 2 }}
            >
              <Button type="submit" onClick={saveLead} variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(LeadModal);
