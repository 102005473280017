import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import { emailValidation, mobileNoValidation } from "../../../common/common";
import { toast } from "react-toastify";

const SignUp = ({ close, open, loginOpen }) => {
  const [cookies] = useCookies();
  const [signUpLoading, setSignUpLoading] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");

  const [phone, setPhone] = useState("");
  const mobileValidation = mobileNoValidation(phone);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneHelperText, setPhoneHelperText] = useState("");

  const [email, setEmail] = useState("");
  const emailValidate = emailValidation(email);
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");

  const [passwordValidation, setPasswordValidation] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;

    // Validate password
    const hasUppercase = /[A-Z]/.test(newPasswordValue);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(newPasswordValue);
    const hasNumber = /\d/.test(newPasswordValue);
    const isLengthValid = newPasswordValue.length >= 8;

    setPassword(newPasswordValue);

    if (hasUppercase && hasSpecialCharacter && hasNumber && isLengthValid) {
      setPasswordError(false);
      setPasswordHelperText("Password is valid!");
      setPasswordValidation(true);
    } else {
      setPasswordError(true);
      setPasswordHelperText(
        "Password must include an uppercase letter, a special character, at least one number, and be at least 8 characters long."
      );
      setPasswordValidation(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setSignUpLoading(true);
    var validationError = true;
    if (!emailValidate) {
      setEmailError(true);
      setEmailHelperText("Please enter valid email address");
      validationError = false;
      setSignUpLoading(false);
    }
    if (!passwordValidation) {
      setPasswordError(true);
      setPasswordHelperText("Please Enter Password OR Strong Password");
      validationError = false;
      setSignUpLoading(false);
    }
    if (!name) {
      setNameError(true);
      setNameHelperText("Please Enter Your Name");
      validationError = false;
      setSignUpLoading(false);
    }
    if (!mobileValidation) {
      setPhoneError(true);
      setPhoneHelperText("Please Enter Valid Phone Number");
      validationError = false;
      setSignUpLoading(false);
    }

    if (validationError) {
      let params = {
        email: email,
        name: name,
        phoneNumber: phone,
        password: password,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.userSignUp,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setSignUpLoading(false);
          setEmailError(false);
          setEmailHelperText("");
          toast.success("SignUp successFully Please Login");
          setPasswordError(false);
          setPasswordHelperText("");
          setPhoneError(false);
          setPhoneHelperText("");
          setNameError(false);
          setNameHelperText("");
          close();
        } else if (response.data.responseCode === "GM001") {
          setEmailError(true);
          setEmailHelperText("This Email Already Exist");
          setSignUpLoading(false);
        } else {
          alert("Something went wrong while login. Please try again later!");
          setSignUpLoading(false);
        }
      } else {
        alert("Something went wrong while login. Please try again later!!");
        setSignUpLoading(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth maxWidth={"md"}>
        <Grid
          container
          sx={{
            py: { xs: "60px", md: "0px" },
            px: { xs: "20px", md: "0px" },
            position: "relative",
          }}
        >
          <Grid
            item
            md={5}
            sx={{
              backgroundColor: "#FBFBFB",
              padding: "75.923px 45.322px",
              display: { xs: "none", md: "block" },
            }}
          >
            <Box sx={{ width: "207px", pb: "18px" }}>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "#282828",
                  fontFamily: "Open Sans",
                }}
              >
                Signup
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "400",
                  color: "#5B5B5B",
                  fontFamily: "Open Sans",
                }}
              >
                Get access to College Admissions brochures, favourites and
                dashboard
              </Typography>
            </Box>
            <Box sx={{ pt: "18px" }}>
              <img
                src="/media/svg/loginImage.svg"
                alt="Login"
                width="239px"
                height="219px"
              />
            </Box>
          </Grid>
          <Grid
            item
            md={7}
            xs={12}
            sx={{
              display: { xs: "block", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <form onSubmit={handleSignUp}>
              <Box
                component={"img"}
                sx={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                  mt: "5px",
                  position: "absolute",
                  right: { md: "20px", xs: "10px" },
                  top: "20px",
                  mr: "5px",
                }}
                onClick={close}
                alt="close icon"
                src="/media/svg/cross-circled.svg"
              />
              <Box sx={{ width: { xs: "100%", md: "317px" } }}>
                <Typography
                  sx={{
                    color: "#282828",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Please enter your information to sign up
                </Typography>
                <TextField
                  fullWidth
                  error={nameError}
                  helperText={nameHelperText}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(false);
                    setNameHelperText("");
                  }}
                  autoComplete="off"
                  variant="outlined"
                  placeholder="Name"
                  sx={{ mt: "12px" }}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={email}
                  error={emailError}
                  helperText={emailHelperText}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                    setEmailHelperText("");
                  }}
                  variant="outlined"
                  placeholder="Email"
                  sx={{ mt: "12px" }}
                />
                <TextField
                  fullWidth
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setPhoneError(false);
                    setPhoneHelperText("");
                  }}
                  error={phoneError}
                  helperText={phoneHelperText}
                  autoComplete="off"
                  variant="outlined"
                  placeholder="Phone"
                  sx={{ mt: "12px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <>
                          {/* flag and country code */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "0px",
                              gap: "5px",
                            }}
                          >
                            <Box
                              component={"img"}
                              sx={{ width: "24px", height: "24px" }}
                              src="/media/svg/indianflag.svg"
                              alt="india"
                            />
                            <Typography
                              variant="bodyparagraph"
                              sx={{
                                color: "text.primary",
                              }}
                            >
                              +91
                            </Typography>
                          </Box>
                          {/* vertical line */}
                          <Box
                            sx={{
                              width: "26px",
                              height: "0px",
                              border: "0.5px solid #AAACAE",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 10 }}
                />
                <TextField
                  fullWidth
                  error={passwordError}
                  autoComplete="off"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  helperText={passwordHelperText}
                  onChange={handleNewPasswordChange}
                  placeholder="Password"
                  sx={{ mt: "12px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{ mt: "28px", py: "12px" }}
                  disabled={signUpLoading}
                >
                  SIGN UP
                  {signUpLoading && (
                    <CircularProgress sx={{ ml: 2 }} size={20} />
                  )}
                </Button>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "#282828",
                    mt: "16px",
                  }}
                >
                  Already have an account?
                  <Box
                    component="span"
                    sx={{
                      color: "#F5374D",
                      fontWeight: "600",
                      fontSize: "13px",
                      cursor: "pointer",
                      ml: "5px",
                    }}
                    onClick={() => {
                      close();
                      loginOpen();
                    }}
                  >
                    Login.
                  </Box>
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default SignUp;
