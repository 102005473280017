import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import { getUser } from "../../../global/redux/action";

const openedMixin = (theme) => ({
  width: 240,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 240,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashboardNav = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [cookies] = useCookies();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleTabChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const currentPath = window.location.pathname;
  const userRole = userData?.users?.roles || [];

  const getMenuItemsBasedOnRole = (role) => {
    return [
      {
        text: "Dashboard",
        icon:
          currentPath === "/dashboard" ? (
            <img
              src="/media/svg/active-dashboard.svg"
              width="18.508px"
              height="18.508px"
              alt="active"
            />
          ) : (
            <DashboardIcon />
          ),
        path: "/dashboard",
      },
      {
        text: "College Metadata",
        icon:
          currentPath === "/institute-types" ? (
            <img
              src="/media/svg/active-collegemeta.svg"
              width="25px"
              height="25px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/college-meta.svg"
              width="25px"
              height="25px"
              alt="college meta"
            />
          ),
        path: "/institute-types",
      },
      {
        text: "Colleges",
        icon:
          currentPath === "/college-list" ? (
            <img
              src="/media/svg/active-college.svg"
              width="23.491px"
              height="23.491px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/college.svg"
              width="23.491px"
              height="23.491px"
              alt="college"
            />
          ),
        path: "/college-list",
      },
      {
        text: "School",
        icon:
          currentPath === "/add-school" ? (
            <img
              src="/media/svg/active-college.svg"
              width="23.491px"
              height="23.491px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/college.svg"
              width="23.491px"
              height="23.491px"
              alt="college"
            />
          ),
        path: "/add-school",
      },
      {
        text: "Training Institute",
        icon:
          currentPath === "/add-training-institute" ? (
            <img
              src="/media/svg/active-college.svg"
              width="23.491px"
              height="23.491px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/college.svg"
              width="23.491px"
              height="23.491px"
              alt="college"
            />
          ),
        path: "/add-training-institute",
      },
      {
        text: "Lead Manager",
        icon:
          currentPath === "/manage-leads" ? (
            <img
              src="/media/svg/active-lead-manager.svg"
              width="20.045px"
              height="18.508px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/lead-manager.svg"
              width="20.045px"
              height="18.508px"
              alt="lead Manager"
            />
          ),
        path: "/manage-leads",
      },
      {
        text: "Employee Manager",
        icon:
          currentPath === "/user-list" ? (
            <img
              src="/media/svg/active-employee-manager.svg"
              width="24.165px"
              height="24.736px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/employee-manager.svg"
              width="24.165px"
              height="24.736px"
              alt="employee manager"
            />
          ),
        path: "/user-list",
      },
      {
        text: "Blog",
        icon:
          currentPath === "/manage-blogs" ? (
            <img
              src="/media/svg/active-blog.svg"
              width="17.583px"
              height="17.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/blog.svg"
              width="17.583px"
              height="17.583px"
              alt="Blog"
            />
          ),
        path: "/manage-blogs",
      },
      {
        text: "College Mapping",
        icon:
          currentPath === "/team-leader-mapping-list" ? (
            <img
              src="/media/svg/active-mapping.svg"
              width="17.818px"
              height="21.914px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/mapping.svg"
              width="17.818px"
              height="21.914px"
              alt="Mapping"
            />
          ),
        path: "/team-leader-mapping-list",
      },
      {
        text: "Marketing Executive Mapping",
        icon:
          currentPath === "/marketing-executive-mapping-list" ? (
            <img
              src="/media/svg/active-mapping.svg"
              width="17.818px"
              height="21.914px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/mapping.svg"
              width="17.818px"
              height="21.914px"
              alt="Mapping"
            />
          ),
        path: "/marketing-executive-mapping-list",
      },
      {
        text: "Reviews",
        icon:
          currentPath === "/reviews" ? (
            <img
              src="/media/svg/active-mapping.svg"
              width="17.818px"
              height="21.914px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/mapping.svg"
              width="17.818px"
              height="21.914px"
              alt="Reviews"
            />
          ),
        path: "/reviews",
      },
      {
        text: "Address Manager",
        icon:
          currentPath === "/address-manager" ? (
            <img
              src="/media/svg/active-mapping.svg"
              width="17.818px"
              height="21.914px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/mapping.svg"
              width="17.818px"
              height="21.914px"
              alt="Reviews"
            />
          ),
        path: "/address-manager",
      },
      // Add more items as needed
    ].filter((item) => {
      switch (true) {
        case userRole.includes("Admin"):
          return true; // Include all menu items for admin
        case userRole.includes("College Staff"):
          return ["Dashboard", "Lead Manager", "Reviews"].includes(item.text);
        case userRole.includes("College Admin"):
          return ["Lead Manager", "Employee Manager", "Reviews"].includes(
            item.text
          );
        case userRole.includes("Marketing Executive"):
          return ["Colleges", "College Metadata", "Employee Manager"].includes(
            item.text
          );
        case userRole.includes("Team Leader"):
          return ["Leads Mapping", "Dashboard", "Lead Manager"].includes(
            item.text
          );
        case userRole.includes("Author"):
          return ["Blog", "Dashboard"].includes(item.text);
        case userRole.includes("Telecaller"):
          return ["Lead Manager"].includes(item.text);
        default:
          return false; // Default case: exclude all items for unknown userRole
      }
    });
  };

  const menuItems = getMenuItemsBasedOnRole(userRole);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {theme.direction === "rtl" ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <>
              {open && <Typography variant="h5">Get My Studies</Typography>}
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => handleTabChange(item.path)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default DashboardNav;
