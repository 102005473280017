import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {
  Typography,
  Box,
  useMediaQuery,
  Rating,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  InputAdornment,
  Autocomplete,
  Chip,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link } from "react-router-dom";
import LeadModal from "./LeadModal";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close";
import { mobileNoValidation } from "../../../../common/common";
import { toast } from "react-toastify";

function SingleCollegeCard(props) {
  const [cookies] = useCookies();
  const [openLeadModal, setLeadModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleClose = () => {
    setLeadModalOpen((close) => !close);
  };
  const [btnValue, setBtnValue] = useState("apply");
  const {
    collegeLogoPath,
    collegeName,
    district,
    state,
    id,
    seoOgUrl,
    courseFee,
    brochurePath,
    courseNameList,
  } = props;

  function formatNumber(value) {
    // Convert the value to a number
    const numericValue = parseFloat(value);

    // Check if the value is greater than or equal to 1000
    if (numericValue >= 1000) {
      // Divide the value by 1000 and round to one decimal place
      const formattedValue = (numericValue / 1000).toFixed(1);

      // Add 'k' as a suffix
      return `${formattedValue}k`;
    } else if (numericValue >= 100000) {
      const formattedValue = (numericValue / 100000).toFixed(2);
      return `${formattedValue}L`;
    }

    // If the value is less than 1000, return the original value
    return value;
  }

  const [selectedCourse, setSelectedCourse] = useState([]);

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [fullNameHelperText, setFullNameHelperText] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [mobileNumberHelperText, setMobileNumberHelperText] = useState("");
  const mobileNumberValidate = mobileNoValidation(mobileNumber);

  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [emailIdHelperText, setEmailIdHelperText] = useState("");

  const handleRemoveCourse = (prev) => {
    setSelectedCourse(selectedCourse?.filter((r) => r !== prev.id));
  };

  const handleCourseChange = (event, newValue) => {
    const stringIds = newValue.map((company) => company.id.toString());

    setSelectedCourse(stringIds);
  };

  const downloadPDF = (pdfUrl) => {
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.target = "_blank";
    anchor.download = "lead_information.pdf";
    anchor.click();
  };

  const saveLead = async (e) => {
    e.preventDefault();

    if (mobileNumberValidate) {
      let validationError = false;

      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
        validationError = true;
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
        validationError = true;
      }
      if (!validationError) {
        let params = {
          studentName: fullName,
          mobileNumber: mobileNumber,
          coursesList: selectedCourse,
          email: emailId,
          collegeId: id,
        };
        let response = await invokeApi(
          config.getMyCollege + apiList.saveLead,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          if (response.data.responseCode === "200") {
            handleClose();
            toast.success("Lead Created Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFullName("");
            setFullNameHelperText("");
            setFullNameError(false);

            setMobileNumber("");
            setMobileNumberError(false);
            setMobileNumberHelperText("");

            setEmailId("");
            setEmailIdError(false);
            setEmailIdHelperText("");

            setSelectedCourse("");
            if (btnValue === "brochure") {
              downloadPDF(brochurePath);
            }
          } else {
            alert(
              "Something went wrong while saving the Leading Details. Please try again later!"
            );
          }
        } else {
          alert(
            "Something went wrong while saving the Leading Details. Please try again later!!"
          );
        }
      }
    } else {
      setMobileNumberHelperText("Please Enter Valid Mobile Number");
      setMobileNumberError(true);
      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
      }
    }
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Card
        sx={{
          maxWidth: { xs: "auto", sm: "385px" },
          height: "auto",
          borderRadius: "40px",
          border: "1px solid #7C7E80",
          boxShadow: "0px 0px 3px rgba(124, 126, 128, 1)",
          justifyContent: "center",
          alignItems: "center",
          mb: 5,
        }}
      >
        <Box display={"flex"} marginTop={"15px"} height={"102px"}>
          <Link to={`/institute/${seoOgUrl}/info/${id}`}>
            <Box
              padding={"15px"}
              sx={{ cursor: "pointer", overflow: "hidden" }}
            >
              <img
                src={collegeLogoPath}
                alt={collegeName}
                width={isSmallScreen ? "70.661px" : "90.112px"}
                height={isSmallScreen ? "70.661px" : "90.112px"}
                style={{ borderRadius: "50%" }}
              />
            </Box>
          </Link>
          <Box
            sx={{
              overflow: "hidden",
              cursor: "pointer",
              whiteSpace: "nowrap",
              display: "grid",
              placeItems: "center",
              textOverflow: "ellipsis",
              height: "100px",
              width: "213px",
            }}
          >
            <Link
              to={`/institute/${seoOgUrl}/info/${id}`}
              style={{ textDecoration: "none", color: "#000" }}
            >
              <Typography sx={{ fontSize: { md: "20px", xs: "10px" } }}>
                {collegeName}
              </Typography>
              <Typography sx={{ fontSize: "13px" }}>
                {district + "," + state}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Approx Fee:{formatNumber(courseFee)}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Rating
                  name="size-small"
                  defaultValue={2}
                  disabled
                  size="small"
                  style={{ fontSize: 15 }}
                />
                <Typography sx={{ fontSize: "9px" }}>(432 reviews)</Typography>
              </Box>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px",
            width: { sm: "344px", xs: "auto" },
            height: "auto",
            gap: "5px",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "none" /* Hide scrollbar for Chrome, Safari, and Edge */,
            },
            scrollbarWidth: "none" /* Hide scrollbar for Firefox */,
          }}
        >
          <Link to={`/institute/${seoOgUrl}/admission/${id}`}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000",
                width: "auto",
                height: "37px",
                fontSize: { xs: "9.985px", sm: "13px" },
                textTransform: "none",
                whiteSpace: "nowrap",
                borderColor: "#95959B",
              }}
            >
              <FiberManualRecordIcon
                style={{
                  fontSize: 16,
                  color: "#717D82",
                  paddingRight: "5px",
                  marginBottom: "3px",
                }}
              />
              Admissions 2024
            </Button>
          </Link>
          <Link to={`/institute/${seoOgUrl}/cutoff/${id}`}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000000",
                height: "37px",
                fontSize: { xs: "9.985px", sm: "13px" },
                textTransform: "none",
                whiteSpace: "nowrap",
                borderColor: "#95959B",
              }}
            >
              <FiberManualRecordIcon
                style={{
                  fontSize: 16,
                  color: "#717D82",
                  paddingRight: "5px",
                  marginBottom: "3px",
                }}
              />
              Cut Off
            </Button>
          </Link>
          <Link to={`/institute/${seoOgUrl}/placement/${id}`}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000000",
                width: "103px",
                height: "37px",
                fontSize: { xs: "9.985px", sm: "13px" },
                borderColor: "#95959B",
                textTransform: "none",
              }}
            >
              <FiberManualRecordIcon
                style={{
                  fontSize: 16,
                  color: "#717D82",
                  paddingRight: "5px",
                  marginBottom: "3px",
                }}
              />
              Placements
            </Button>
          </Link>
        </Box>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            widt: "317px",
            height: "60x",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#F5F5F5",
              color: "#F5374D",
              borderRadius: "40px",
              border: "1px solid #7C7E80",
              boxShadow: "1px 1px 2px #7C7E80",
              fontWeight: "bold",
              width: "150px",
              height: "40x",
              fontSize: isMobile ? "15px" : "20px",
              textTransform: "uppercase",
            }}
            onClick={() => {
              setBtnValue("brochure");
              setLeadModalOpen(true);
            }}
          >
            Brochure
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#405189",
              color: "white",
              borderRadius: "40px",
              fontWeight: "bold",
              width: "150px",
              height: "40x",
              fontSize: isMobile ? "15px" : "20px",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: "#405189",
              },
            }}
            onClick={() => {
              setBtnValue("apply");
              setLeadModalOpen(true);
            }}
          >
            APPLY NOW
          </Button>
        </CardActions>
      </Card>
      <Dialog open={openLeadModal} onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Apply
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="dense"
                label="Name"
                value={fullName}
                style={{ marginTop: { xs: "10px" } }}
                onChange={(ev) => {
                  setFullName(ev.target.value);
                  setFullNameError(false);
                  setFullNameHelperText("");
                }}
                helperText={fullNameHelperText}
                error={fullNameError}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Mobile Number"
                value={mobileNumber}
                onChange={(ev) => {
                  setMobileNumber(ev.target.value);
                  setMobileNumberError(false);
                  setMobileNumberHelperText("");
                }}
                helperText={mobileNumberHelperText}
                error={mobileNumberError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <>
                        {/* flag and country code */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "0px",
                            gap: "5px",
                          }}
                        >
                          <Box
                            component={"img"}
                            sx={{ width: "24px", height: "24px" }}
                            alt="india"
                            src="/media/svg/indianflag.svg"
                          />
                          <Typography
                            variant="bodyparagraph"
                            sx={{
                              color: "text.primary",
                            }}
                          >
                            +91
                          </Typography>
                        </Box>
                        {/* vertical line */}
                        <Box
                          sx={{
                            width: { md: "26px", xs: "20px" },
                            height: "0px",
                            border: "0.5px solid #AAACAE",
                            transform: "rotate(90deg)",
                          }}
                        />
                      </>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Email Id"
                value={emailId}
                onChange={(ev) => {
                  setEmailId(ev.target.value);
                  setEmailIdError(false);
                  setEmailIdHelperText("");
                }}
                helperText={emailIdHelperText}
                error={emailIdError}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="scholarship-autocomplete"
                options={courseNameList}
                getOptionLabel={(option) => option.courseName}
                value={courseNameList?.filter((company) =>
                  selectedCourse.includes(company.id.toString())
                )}
                fullWidth
                onChange={handleCourseChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Course"
                    fullWidth
                    margin="normal"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      label={option.courseName}
                      {...getTagProps({ index })}
                      onDelete={() => handleRemoveCourse(option)}
                    />
                  ))
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", my: 2 }}
            >
              <Button type="submit" onClick={saveLead} variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default React.memo(SingleCollegeCard);
