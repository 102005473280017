import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import DataTable from "react-data-table-component";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import MarketingExecutiveMappingTabs from "./MarketingExecutiveMappingTabs";
import { useNavigate } from "react-router-dom";

const MarketingExecutiveMappingAdd = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [mktngExecList, setMktngExecList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [mktngExecId, setMktngExecId] = useState("");
  const [userError1, setUserError] = useState(false);
  const [userHelperText, setUserHelperText] = useState("");
  const [search, setSearch] = useState("");

  const enableButton = mktngExecId && selectedRows > 0;

  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies();

  // const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Name",
      selector: (row) => row.collegeName,
      sortable: true,
    },
  ];

  const saveMktngExecMapping = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    var validationError = false;
    if (!mktngExecId) {
      setUserError(true);
      setUserHelperText("Please Select Marketing Executive");
      validationError = true;
    }
    if (!validationError) {
      let params = {
        collegeIds: selectedRows,
        mktngUserId: mktngExecId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.addMarketingExecutiveMapping,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setSelectedRows([]);
          setSaveLoading(false);
          setMktngExecId("");
          alert("Colleges Successfull Assigned to Marketing Executive");
        } else {
          alert(
            "Something went wrong while Add College Team Leader Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else {
        alert(
          "Something went wrong while College Team Leader Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows.map((row) => row.id));
  };

  useEffect(() => {
    const getAllColleges = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setData(response.data.colleges);
          setFilter(response.data.colleges);
          setLoading(false);
        } else {
          alert(
            "Something went wrong while getting the Colleges Details. Please try again later!"
          );
          setLoading(false);
        }
      } else {
        alert(
          "Something went wrong while getting the Colleges Details. Please try again later!!"
        );
        setLoading(false);
      }
    };
    const getAllTeamLeader = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getUsers,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setMktngExecList(
            response.data.users.filter(
              (user) => user.roles && user.roles.includes("Marketing Executive")
            )
          );
        } else {
          alert(
            "Something went wrong while getting the Team Leader Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Team Leader Details. Please try again later!!"
        );
      }
    };
    getAllTeamLeader();
    getAllColleges();
  }, [cookies]);

  useEffect(() => {
    const result = data.filter((item) => {
      return item.collegeName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);
  return (
    <>
      <MarketingExecutiveMappingTabs tabActive={1}>
        <Box>
          <form onSubmit={saveMktngExecMapping}>
            <FormControl sx={{ my: 1 }} fullWidth>
              <InputLabel id="select-team-leader">
                Select Team Leader
              </InputLabel>
              <Select
                labelId="select-team-leader"
                id="select-team-leader"
                value={mktngExecId}
                error={userError1}
                helperText={userHelperText}
                label="Team Leader"
                onChange={(e) => setMktngExecId(e.target.value)}
              >
                {mktngExecList
                  ?.filter((item) => item.status === "Active")
                  ?.map((e) => (
                    <MenuItem value={e.id} key={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <DataTable
              columns={columns}
              data={filter}
              pagination
              selectableRows
              fixedHeader
              highlightOnHover
              subHeaderAlign="left"
              onSelectedRowsChange={handleChange}
              progressPending={loading}
              subHeader
              subHeaderComponent={
                <TextField
                  type="text"
                  placeholder="Search....."
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              }
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={saveLoading || !enableButton}
              >
                Assign
                {saveLoading && <CircularProgress size={20} />}
              </Button>
            </Box>
          </form>
        </Box>
      </MarketingExecutiveMappingTabs>
    </>
  );
};

export default MarketingExecutiveMappingAdd;
