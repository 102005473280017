import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import { apiList, invokeApi } from "../../../services/apiServices";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import DeleteModal from "./DeleteModal";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const ScholarshipList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;

  const [scholarship, setScholarship] = useState([]);
  const [scholarshipFilter, setScholarshipFilter] = useState([]);

  const [search, setSearch] = useState("");

  const [isScholarshipFetching, setIsScholarshipFetching] = useState(true);

  const [scholarshipName, setScholarshipName] = useState("");
  const [scholarshipHelperText, setScholarshipHelperText] = useState("");
  const [scholarshipError, setScholarshipError] = useState(false);

  const [isAddScholarshipDialogOpen, setIsAddScholarshipDialogOpen] =
    useState(false);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [id, setId] = useState("");
  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const handleIsEditModal = () => {
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  const handleIsDeleteModal = () => {
    setdeleteConfirmation(!deleteConfirmation);
  };

  const handleIsAddModal = () => {
    setIsAddScholarshipDialogOpen(!isAddScholarshipDialogOpen);
  };

  const clearAllCache = () => {
    setSaveLoading(false);
    setUpdateLoading(false);
    setScholarshipName("");
    setScholarshipError(false);
    setScholarshipHelperText("");
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const validateScholarship = () => {
    let validationErrors = false;
    if (!scholarshipName) {
      setScholarshipError(true);
      setScholarshipHelperText("Please enter the course");
      setUpdateLoading(false);
      setSaveLoading(false);
      validationErrors = true;
    }

    if (!validationErrors) {
      return true;
    } else {
      return false;
    }
  };

  //get scholarship
  useEffect(() => {
    const getScholarship = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getScholorShips,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setScholarship(response.data.scholarships);
          setScholarshipFilter(response.data.scholarships);
          setIsScholarshipFetching(false);
        } else {
          alert(
            "Something went wrong while getting scholorship Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting scholorship Details. Please try again later!!"
        );
      }
    };
    if (isScholarshipFetching) {
      getScholarship();
    }
  }, [cookies, isScholarshipFetching]);

  const saveScholarship = async () => {
    let validate = validateScholarship();
    if (validate) {
      let params = {
        scholarshipName: scholarshipName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveScholarship,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Scholarship Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleIsAddModal();
          setIsScholarshipFetching(true);
          clearAllCache();
        } else if (response.data.responseCode === "GM003") {
          handleIsAddModal(false);
          toast.info("Scholarship Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          clearAllCache();
        } else {
          alert(
            "Something went wrong while saving the scholorship Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while saving the scholorship Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  //update scholarship
  const updateScholarship = async () => {
    var validationError = validateScholarship();
    if (validationError) {
      let params = {
        id: id,
        scholarshipName: scholarshipName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateScholarship,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Scholarship Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setId("");
          setIsScholarshipFetching(true);
          setIsEditDialogOpen(false);
          clearAllCache();
        } else if (response.data.responseCode === "GM003") {
          setIsEditDialogOpen(false);
          toast.info("Scholarship Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          clearAllCache();
        } else {
          alert(
            "Something went wrong while Edit scholarship Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Edit scholarship Details. Please try again later!!"
        );
      }
    }
  };

  //delete Scholarship
  const deleteScholarship = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateScholarship,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Scholarship Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleIsDeleteModal();
        setIsScholarshipFetching(true);
        setId("");
      } else if (response.data.responseCode === "GM002") {
        handleIsDeleteModal();
        toast.info(
          "This Scholarship Mapped to Some College You Can't Delete This Scholarship!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        alert(
          "Something went wrong while delete scholorship Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete scholorship Details. Please try again later!!"
      );
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Scholarship Name",
      selector: (row) => row.scholarshipName,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setIsEditDialogOpen(true);
                  setId(row.id);
                  setScholarshipName(row.scholarshipName);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setIsEditDialogOpen(true);
                  setId(row.id);
                  setScholarshipName(row.scholarshipName);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setId(row.id);
                  setdeleteConfirmation(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = scholarship.filter((item) => {
      return item.scholarshipName
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    });
    setScholarshipFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CollegeMetadataTabs tabActive={6}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={() => setIsAddScholarshipDialogOpen(true)}
        >
          <AddIcon />
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={scholarshipFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isScholarshipFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      <DeleteModal
        Open={deleteConfirmation}
        DeleteFunction={deleteScholarship}
        handleIsDeleteModal={handleIsDeleteModal}
      />
      {/* add  */}
      <Dialog
        open={isAddScholarshipDialogOpen}
        onClose={() => {
          handleIsAddModal();
          clearAllCache();
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            handleIsAddModal();
            clearAllCache();
          }}
        />
        <DialogTitle>Add Scholarship</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Name *"
            error={scholarshipError}
            helperText={scholarshipHelperText}
            name="name"
            value={scholarshipName}
            onChange={(ev) => {
              setScholarshipName(ev.target.value);
              setScholarshipError(false);
              setScholarshipHelperText("");
            }}
          />
          <LoadingButton
            loading={saveLoading}
            onClick={() => {
              setSaveLoading(true);
              saveScholarship();
            }}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </DialogContent>
      </Dialog>
      {/* edit  */}
      <Dialog
        open={isEditDialogOpen}
        onClose={() => {
          handleIsEditModal();
          clearAllCache();
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            handleIsEditModal();
            clearAllCache();
          }}
        />
        <DialogTitle>Edit Scholarship</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Scholarship Name *"
            error={scholarshipError}
            helperText={scholarshipHelperText}
            name="name"
            value={scholarshipName}
            onChange={(ev) => {
              setScholarshipName(ev.target.value);
              setScholarshipError(false);
              setScholarshipHelperText("");
            }}
          />
          <LoadingButton
            loading={updateLoading}
            variant="contained"
            onClick={() => {
              setUpdateLoading(true);
              updateScholarship();
            }}
          >
            Submit
          </LoadingButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScholarshipList;
