import React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../../../config/config";
import { useEffect } from "react";
import Header from "./../../../general-components/ui-components/Header";
import Footer from "./../../../general-components/ui-components/Footer";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { apiList, invokeApi } from "../../../../services/apiServices";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Rating,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Login from "../../../auth/login/Login";
import SignUp from "../../../auth/signup/SignUp";
import SingleCollegeCard from "../home-sections/SingleCollegeCard";
import LeadModal from "../home-sections/LeadModal";
import { Helmet } from "react-helmet";

const CollegeDetailHeader = ({ children, activeTab, transferCollegeData }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [cookies] = useCookies();
  const [openLogin, setOpenLogin] = useState(false);
  const [btnValue, setBtnValue] = useState("apply");
  const [openSignUp, setOpenSignUp] = useState(false);
  const { collegeId, slugUrl } = useParams();
  const [openLeadModal, setLeadModalOpen] = useState(false);
  const [college, setCollege] = useState({});
  const [tabValue, setTabValue] = useState(activeTab || 0);
  const [isSticky, setIsSticky] = useState(false);
  const [isFavorite, setIsFavorite] = useState("Inactive");
  const [activeBreadCrumbs, setActiveBreadCrumbs] = useState("info");
  const [fetching, setFetching] = useState(true);
  const [collegeList, setCollegesList] = useState([]);
  const [courseNameList, setCourseNameList] = useState([]);
  const [isCourseFetching, setIsCourseFetching] = useState(true);
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleLocationClick = () => {
    // Construct the Google Maps URL with the college's latitude and longitude
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${college.latitude},${college.longitude}`;

    // Open the URL in a new tab/window
    window.open(googleMapsUrl, "_blank");
  };

  const handleIsLoginModal = () => {
    setOpenLogin(!openLogin);
  };

  const handleIsSignUpModal = () => {
    setOpenSignUp(!openSignUp);
  };

  const formhandleClose = () => setOpenLogin(false);

  const handleIsLeadModal = () => {
    setLeadModalOpen((modal) => !modal);
  };

  const handleFavoriteClick = () => {
    if (
      cookies[config.cookieName]?.loginUserId &&
      cookies[config.cookieName]?.token
    ) {
      setIsFavorite((prevState) =>
        prevState === "Active" ? "Inactive" : "Active"
      );
    } else {
      handleIsLoginModal();
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setActiveBreadCrumbs(event.target.innerText);
    switch (newValue) {
      case 0:
        navigate(`/institute/${slugUrl}/info/${collegeId}`);
        break;
      case 1:
        navigate(`/institute/${slugUrl}/courseandfee/${collegeId}`);
        break;
      case 2:
        navigate(`/institute/${slugUrl}/admission/${collegeId}`);
        break;
      case 3:
        navigate(`/institute/${slugUrl}/cutoff/${collegeId}`);
        break;
      case 4:
        navigate(`/institute/${slugUrl}/placement/${collegeId}`);
        break;
      case 5:
        navigate(`/institute/${slugUrl}/scholarship/${collegeId}`);
        break;
      case 6:
        navigate(`/institute/${slugUrl}/gallery/${collegeId}`);
        break;
      case 7:
        navigate(`/institute/${slugUrl}/results/${collegeId}`);
        break;
      case 8:
        navigate(`/institute/${slugUrl}/facility/${collegeId}`);
        break;
      case 9:
        navigate(`/institute/${slugUrl}/reviews/${collegeId}`);
        break;
      case 10:
        navigate(`/institute/${slugUrl}/questionandanswer/${collegeId}`);
        break;
      default:
        setTabValue(0);
        navigate(`/institute/${slugUrl}/info/${collegeId}`);
        break;
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setTimeout(() => {
      if (!openLeadModal) {
        setLeadModalOpen(true);
      }
    }, 20000);
  }, [cookies]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getCollegeDetail = async () => {
      let params = {
        id: collegeId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCollege,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCollege(response.data.colleges);
          // setCollegeId(response.data.colleges.id);
          transferCollegeData(response.data.colleges);
          setFetching(false);
        } else {
          alert(
            "Something went wrong while delete course Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while delete course Details. Please try again later!!"
        );
      }
    };
    const getAllColleges = async () => {
      let params = {
        district: "Bangalore",
        status: "Active",
        brandId: college.brandId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCollegesList(response.data.colleges);
          setFetching(false);
        } else {
          alert(
            "Something went wrong while getting the College Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the College Details. Please try again later!!"
        );
      }
    };
    if (fetching) {
      getCollegeDetail();
      getAllColleges();
    }
  }, [fetching, collegeId]);

  const infoCollge = `/institute/${slugUrl}/info/${collegeId}`;

  useEffect(() => {
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseNameList(response.data.courses);
        } else {
          alert(
            "Something went wrong while getting the College Types Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the College Types Details. Please try again later!!"
        );
      }
    };
    if (isCourseFetching) {
      getAllCourseName();
      setIsCourseFetching(false);
    }
  }, [cookies]);

  useEffect(() => {
    const handleScroll = () => {
      const stickyPosition = document.getElementById("stickyPaper").offsetTop;

      if (window.pageYOffset >= stickyPosition) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header />
      <Helmet>
        <title>{college?.collegeName}</title>
        <meta name="description" content={college?.metaDescription} />
        <meta name="keywords" content={college?.metaKeywords} />
        <meta property="og:title" content={college?.collegeName} />
        <meta property="og:image" content={college?.collegeLogoPath} />
        <meta property="og:description" content={college?.metaDescription} />
      </Helmet>
      <Box
        sx={{
          p: { md: 4, xs: 0 },
          py: { md: 0, xs: 2 },
          backgroundColor: "#D9D9D9",
        }}
      >
        <Stack>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", fontSize: "13px" }}
              color="dark"
              href="/"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", fontSize: "13px" }}
              color="inherit"
              href={infoCollge}
            >
              {college.collegeName}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", fontSize: "13px" }}
              color="inherit"
              href="/"
              disabled
            >
              {activeBreadCrumbs}
            </Link>
          </Breadcrumbs>
        </Stack>
        <Container sx={{ mt: 3 }} maxWidth={"xl"}>
          {college.bannerImagePath ? (
            <Box sx={{ maxheight: "200px" }}>
              <img
                src={college.bannerImagePath}
                alt={college.collegeName}
                width="100%"
                height="170px"
                style={{ objectFit: "cover" }}
              />
            </Box>
          ) : null}
          <Paper sx={{ my: 2, position: "relative", py: 2 }}>
            <Grid container spacing={2} sx={{ overflow: "hidden" }}>
              <Grid
                item
                md={2}
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "0px",
                  overflow: "hidden",
                }}
              >
                {fetching && !college.collegeLogoPath ? (
                  <Skeleton variant="circular" width={145} height={145} />
                ) : (
                  <img
                    src={college.collegeLogoPath}
                    alt={college.collegeName}
                    style={{
                      borderRadius: "50%",
                      width: "100px",
                      height: "90px",
                      objectFit: "cover",
                      maxWidth: "100%", // Ensures the image scales down for smaller screens
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                md={7}
                xs={7}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {fetching ? (
                  <Skeleton variant="rectangular" width={310} height={80} />
                ) : (
                  <>
                    <Typography sx={{ fontSize: { md: "25px", xs: "16px" } }}>
                      {college.collegeName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "15px", md: "18px" },
                        textTransform: "capitalize",
                        cursor: "pointer",
                      }}
                      onClick={handleLocationClick}
                    >
                      <LocationOnIcon
                        color="#717D82"
                        sx={{ fontSize: { md: "18px", xs: "11px" } }}
                      />
                      {college.district},{college.state}
                      {college.establishedYear ? (
                        <Box component={"span"} className="established-year">
                          Establish Year :{college.establishedYear}
                        </Box>
                      ) : null}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { md: "15px", xs: "9px" },
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      5.0
                      <Rating
                        name="read-only"
                        sx={{ fontSize: { md: "20px", xs: "8px" } }}
                        value={5}
                        readOnly
                      />
                      (542 Reviews)
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
                sx={{
                  position: { md: "sticky", xs: "fixed" },
                  bottom: { md: null, xs: 0 },
                  left: { md: null, xs: 0 },
                  // width: { md: null, xs: "100%" },
                  zIndex: { md: null, xs: 1000 },
                  backgroundColor: "#ffff",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { md: "column", xs: "row" },
                  justifyContent: "center",
                  gap: { md: "none", xs: "8px" },
                  width: { xs: "100%" },
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "#F5374D",
                    mb: { xs: "0px", md: "10px" },
                    borderColor: "#F5374D",
                    width: "155px",
                    "&:hover": {
                      borderColor: "#F5374D",
                    },
                  }}
                  onClick={() => {
                    setBtnValue("brochure");
                    setLeadModalOpen(true);
                  }}
                  size="large"
                >
                  Brochure
                  <img
                    src="/media/svg/broucher.svg"
                    alt="broucher-icon"
                    width="27px"
                    height="24px"
                  />
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    backgroundColor: "#F5374D",
                    width: "155px",
                    "&:hover": {
                      backgroundColor: "#F5374D",
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    setBtnValue("apply");
                    setLeadModalOpen(true);
                  }}
                >
                  Apply Now
                  <img
                    src="/media/svg/apply-now.svg"
                    alt="apply-icon"
                    width="20px"
                    height="20px"
                  />
                </Button>
              </Grid>
              <Grid item md={1} xs={1} textAlign={"center"}>
                {/* favorite icon  */}
                <Box
                  component="img"
                  onClick={handleFavoriteClick}
                  sx={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    position: { xs: "absolute", md: "none" },
                    top: "10px",
                    right: "10px",
                  }}
                  src={
                    isFavorite === "Active"
                      ? "/media/svg/favorite-selected.svg"
                      : "/media/svg/favorite.svg"
                  }
                ></Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            id="stickyPaper"
            sx={{
              my: 2,
              overflowX: "auto",
              position: isSticky ? "fixed" : "static",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 1000,
              boxShadow: isSticky ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
            }}
          >
            <Tabs
              value={tabValue}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}
            >
              <Tab
                label="Info"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Courses & Fees"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Admission"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Cutoff"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Placements"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Scholarship"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Gallery"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Results"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Facility"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab
                label="Review"
                sx={{ textTransform: "capitalize" }}
                disabled={fetching}
              />
              <Tab label="Q&A" disabled={fetching} />
            </Tabs>
          </Paper>
          <TabPanel value={tabValue} index={activeTab}>
            {children}
          </TabPanel>
          {collegeList.length <= 0 ? null : (
            <Paper
              sx={{ backgroundColor: { md: "#fff", xs: "#fff" }, mt: 3, p: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "space-between" },
                  m: 2,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: { md: "25px", xs: "20px" } }}>
                    Related Colleges
                  </Typography>
                  <Typography
                    sx={{ fontSize: { md: "13px", xs: "15px" } }}
                    color={"#a3adb9"}
                  >
                    Top Colleges Picked For You
                  </Typography>
                </Box>
                {!isMd ? (
                  <Button variant="outlined" sx={{ borderRadius: "25px" }}>
                    View All
                  </Button>
                ) : null}
              </Box>
              <Grid
                container
                spacing={2}
                py={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  overflowX: "auto", // Enable horizontal scrolling
                  flexWrap: { md: "wrap", xs: "nowrap" }, // Do not allow items to wrap
                  "&::-webkit-scrollbar": {
                    height: "5px ", // Adjust the width of the scrollbar
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#405189;", // Set the color of the scrollbar thumb
                    borderRadius: "8px", // Round the corners of the thumb
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f1f1f1", // Set the color of the scrollbar track
                    borderRadius: "10px", // Round the corners of the track
                  },
                }}
              >
                {/* under this code is saved in task-27  */}
                {collegeList.slice(0, 6).map((colleges) => (
                  <Grid item key={colleges.id} xs={12} sm={6} lg={4}>
                    <SingleCollegeCard
                      {...colleges}
                      courseNameList={courseNameList}
                    />
                  </Grid>
                ))}
              </Grid>
              {isMd ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <Button variant="outlined">View All</Button>
                </Box>
              ) : null}
            </Paper>
          )}
        </Container>
      </Box>
      <Footer />
      {/* Leads fields */}
      <LeadModal
        collegeId={college.id}
        btnValue={btnValue}
        brochurePath={college.brochurePath}
        openLeadModal={openLeadModal}
        handleClose={handleIsLeadModal}
      />
      <Login
        open={openLogin}
        close={formhandleClose}
        signUpOpen={handleIsSignUpModal}
      />
      <SignUp
        open={openSignUp}
        close={handleIsSignUpModal}
        loginOpen={handleIsLoginModal}
      />
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default CollegeDetailHeader;
