import React, { useEffect, useState } from "react";
import TrainingInstituteTabs from "./TrainingInstituteTabs";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import {
  apiList,
  invokeApi,
  invokeFormDataApi,
} from "../../../services/apiServices";
import { config } from "../../../config/config";
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Box,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import TextEditor from "../colleges/TextEditor";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { durationOptions } from "../../../common/common";
import { Bounce, toast } from "react-toastify";

function TrainingInstitute() {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  const [training, setTraining] = useState({
    name: "",
    error: false,
    helperText: "",
  });
  const [trainingSlug, setTrainingSlug] = useState("");
  const [emails, setEmails] = useState("");
  const [contactNumbers, setContactNumbers] = useState("");
  const [description, setDescription] = useState("");

  //Add Benefits and Key Features....
  const [benefitsKeyDrawer, setBenefitsKeyDrawer] = useState(false);
  const [benefits, setBenefits] = useState([""]);
  const [keyFeature, setKeyFeature] = useState([""]);

  //Add Coures Fees....
  let courseAndFeeObject = {
    trainingInstituteProgramId: null,
    courseName: "",
    offlineFee: "",
    onlineFee: "",
    offlineDuration: "",
    onlineDuration: "",
    offlineFeatures: [""],
    onlineFeatures: [""],
  };

  const [courseDetails, setCourseDetails] = useState([courseAndFeeObject]);
  const [courseFeeDrawer, setCourseFeeDrawer] = useState(false);

  // gallery image, preview, gallery images path and validation
  const [previewImages, setPreviewImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImageURLs, setGalleryImageURLs] = useState([]);
  const [allImagesUploaded, setAllImagesUploaded] = useState(false);
  const [galleryImageError, setGalleryImageError] = useState(false);
  const [galleryImageHelperText, setGalleryImageHelperText] = useState("");

  const [
    isTrainingInstituteProgramFetching,
    setIsTrainingInstituteProgramFetching,
  ] = useState(true);
  const [trainingInstituteProgramList, setTrainingInstituteProgramList] =
    useState([]);

  // college logo, preview, college logo path and validation
  const [trainingLogo, setTrainingLogo] = useState(null);
  const [trainingLogoError, setTrainingLogoError] = useState(false);
  const [trainingLogoHelperText, setTrainingLogoHelperText] = useState("");
  const [previewTrainingLogo, setPreviewTrainingLogo] = useState(null);
  const [trainingLogomaxFileSizeErr, setTrainingLogoMaxFileSizeErr] =
    useState("");
  const [trainingLogoPath, setTrainingLogoPath] = useState(null);

  // Add Feaculty....
  const [facultyDrawer, setFacultyDrawer] = useState(false);
  const facultyObject = {
    name: "",
    profession: "",
    experience: "",
    description: "",
  };
  const [faculty, setFaculty] = useState([facultyObject]);

  const [establishedYear, setEstablishedYear] = useState({
    year: "",
    error: false,
    helperText: "",
  });

  // country selected and validation
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [countryHelperText, setCountryHelperText] = useState("");

  // state selected and validation
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);
  const [stateHelperText, setStateHelperText] = useState("");

  // district selected and validation
  const [district, setDistrict] = useState("");
  const [districtError, setDistrictError] = useState(false);
  const [districtHelperText, setDistrictHelperText] = useState("");

  // area and validation
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [addressHelperText, setAddressHelperText] = useState("");

  // pincode selected and validation
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState(false);
  const [pincodeHelperText, setPincodeHelperText] = useState("");

  // pincode dropdown options function
  const pincodeList = (
    addressList
      .find((item) => item.countryName === country)
      ?.states.find((item) => item.stateName === state)
      ?.districts.find((item) => item.districtName === district)?.pincodes || []
  ).map((pincode) => pincode.pincode);

  // district options function
  const districtList = (
    addressList
      .find((item) => item.countryName === country)
      ?.states.find((item) => item.stateName === state)?.districts || []
  ).map((district) => district.districtName);

  const clearCache = () => {
    setTraining({
      name: "",
      error: false,
      helperText: "",
    });
    setTrainingSlug("");
    setEmails("");
    setContactNumbers("");
    setDescription("");
    setKeyFeature([""]);
    setBenefits([""]);
    setCourseDetails([courseAndFeeObject]);
    setPreviewImages([]);
    setGalleryImages([]);
    setGalleryImageURLs([]);
    setAllImagesUploaded(false);
    setGalleryImageError(false);
    setGalleryImageHelperText("");
    setTrainingLogo(null);
    setTrainingLogoHelperText("");
    setTrainingLogoError(false);
    setPreviewTrainingLogo(null);
    setTrainingLogoMaxFileSizeErr("");
    setTrainingLogoPath(null);
    setFaculty([facultyObject]);
  };

  function handleAddBenefits() {
    setBenefits((prevFetures) => [...prevFetures, ""]);
  }

  function handleRemoveBenefits(ben) {
    let initialBen = [...benefits];
    initialBen.splice(ben, 1);
    setBenefits(initialBen);
  }

  function handleAddKeyFeature1() {
    setKeyFeature((prevFetures) => [...prevFetures, ""]);
  }

  function handleRemoveKeyFeature1(index) {
    let initialValue = [...keyFeature];

    initialValue.splice(index, 1);

    setKeyFeature(initialValue);
  }

  const handleChangeCourseName = (e, index) => {
    const value = e.target.value;

    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Update the courseName at the specific index
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      courseName: value,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleChangeOfflineFee = (e, index) => {
    const value = e.target.value;

    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Update the offlineFee at the specific index
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      offlineFee: value,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleChangeOnlineFee = (e, index) => {
    const value = e.target.value;

    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Update the online at the specific index
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      onlineFee: value,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleChangeOfflineDuration = (e, index) => {
    const value = e.target.value;

    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Update the offlineDuration at the specific index
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      offlineDuration: value,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleChangeOnlineDuration = (e, index) => {
    const value = e.target.value;

    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Update the onlineDuration at the specific index
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      onlineDuration: value,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleChangeOfflineFeatures = (e, offlineIndex, index) => {
    const value = e.target.value;

    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Create a copy of the offlineFeatures array for the course at the given index
    const updatedOfflineFeatures = [
      ...updatedCourseDetails[index].offlineFeatures,
    ];

    // Update the specific feature at offlineIndex
    updatedOfflineFeatures[offlineIndex] = value;

    // Update the course with the modified offlineFeatures array
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      offlineFeatures: updatedOfflineFeatures,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleChangeOnlineFeatures = (e, onlineIndex, index) => {
    const value = e.target.value;

    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Create a copy of the onlineFeatures array for the course at the given index
    const updatedonlineFeatures = [
      ...updatedCourseDetails[index].onlineFeatures,
    ];

    // Update the specific feature at onlineIndex
    updatedonlineFeatures[onlineIndex] = value;

    // Update the course with the modified onlineFeatures array
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      onlineFeatures: updatedonlineFeatures,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleAddOfflineFeatures = (index) => {
    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Create a copy of the offlineFeatures array for the course at the given index
    const updatedOfflineFeatures = [
      ...updatedCourseDetails[index].offlineFeatures,
    ];

    // Add a new feature (you can push an empty string or a default value)
    updatedOfflineFeatures.push(""); // Empty string for now

    // Update the course with the modified offlineFeatures array
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      offlineFeatures: updatedOfflineFeatures,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleAddOnlineFeatures = (index) => {
    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Create a copy of the onlineFeatures array for the course at the given index
    const updatedonlineFeatures = [
      ...updatedCourseDetails[index].onlineFeatures,
    ];

    // Add a new feature (you can push an empty string or a default value)
    updatedonlineFeatures.push(""); // Empty string for now

    // Update the course with the modified onlineFeatures array
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      onlineFeatures: updatedonlineFeatures,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleRemoveOnlineFeatures = (offlineIndex, index) => {
    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Create a copy of the onlineFeatures array for the course at the given index
    const updatedOnlineFeatures = [
      ...updatedCourseDetails[index].onlineFeatures,
    ];

    // Remove the specific feature at offlineIndex
    updatedOnlineFeatures.splice(offlineIndex, 1); // Remove one item at offlineIndex

    // Update the course with the modified onlineFeatures array
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      onlineFeatures: updatedOnlineFeatures,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleRemoveOfflineFeatures = (offlineIndex, index) => {
    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Create a copy of the offlineFeatures array for the course at the given index
    const updatedofflineFeatures = [
      ...updatedCourseDetails[index].offlineFeatures,
    ];

    // Remove the specific feature at offlineIndex
    updatedofflineFeatures.splice(offlineIndex, 1); // Remove one item at offlineIndex

    // Update the course with the modified offlineFeatures array
    updatedCourseDetails[index] = {
      ...updatedCourseDetails[index],
      offlineFeatures: updatedofflineFeatures,
    };

    // Set the updated course details
    setCourseDetails(updatedCourseDetails);
  };

  const handleAddCourse = () => {
    // Add the new course object to the existing courseDetails array
    setCourseDetails([...courseDetails, courseAndFeeObject]);
  };

  const handleRemoveCourse = (index) => {
    // Create a copy of the courseDetails array
    const updatedCourseDetails = [...courseDetails];

    // Remove the course at the specified index
    updatedCourseDetails.splice(index, 1);

    // Update the state with the new array
    setCourseDetails(updatedCourseDetails);
  };

  function handleAddFaculty() {
    setFaculty((prevState) => [...prevState, facultyObject]);
  }

  function handleRemoveFaculty(index) {
    let initialState = [...faculty];
    initialState.splice(index, 1);
    setFaculty(initialState);
  }

  const handleUploadGalleryImages = async (e) => {
    e.preventDefault();
    let validate = true;

    if (!training.name) {
      validate = false;
      setTraining({
        name: "",
        error: true,
        helperText: "Please Enter Training Institute Name",
      });
    }
    if (!country) {
      validate = false;
      setCountryError(true);
      setCountryHelperText("Please Select the country");
    }

    if (!state) {
      validate = false;
      setStateError(true);
      setStateHelperText("Please Select the State");
    }
    if (!district) {
      validate = false;
      setDistrictError(true);
      setDistrictHelperText("Please Select the District or city");
    }
    if (!pincode) {
      validate = false;
      setPincodeError(true);
      setPincodeHelperText("please select the postal Code");
    }
    if (!address) {
      validate = false;
      setAddressError(true);
      setAddressHelperText("Please enter the institute Address");
    }

    // Ensure galleryImages is not empty
    if (galleryImages.length === 0) {
      validate = false;
      setGalleryImageError(true);
      setGalleryImageHelperText("Please upload at least one image.");
    }

    if (validate) {
      // Only upload images that haven't been uploaded yet
      if (!allImagesUploaded) {
        const uploadedImages = await Promise.all(
          galleryImages.map(async (image, i) => {
            let formDataGallery = new FormData();
            formDataGallery.append("file", image);
            formDataGallery.append("path", "training_institute_Gallery_Image");

            try {
              let responseGall = await invokeFormDataApi(
                config.getMyCollege + apiList.uploadFile,
                formDataGallery,
                cookies
              );

              if (
                responseGall?.status >= 200 &&
                responseGall?.status < 300 &&
                responseGall.data.responseCode === "200"
              ) {
                return responseGall.data.url; // Collect uploaded image URL
              } else {
                throw new Error("Failed to upload image");
              }
            } catch (error) {
              console.error("Error uploading image:", error);
              alert("Failed to upload some images. Please try again.");
              return null; // Return null to mark failed uploads
            }
          })
        );

        // Filter out null values (failed uploads)
        const validUploadedImages = uploadedImages.filter(
          (url) => url !== null
        );
        setGalleryImageURLs(validUploadedImages); // Update galleryImageURLs once all images are uploaded
        setAllImagesUploaded(true); // Mark all images as uploaded
      }
    }
  };

  const handleTrainingNameChange = (e) => {
    const newValue = e.target.value;
    setTraining({ name: newValue, error: false, helperText: "" });
    const formattedValue = newValue.replace(/ /g, "-").toLowerCase();
    setTrainingSlug(formattedValue);
  };

  const handleFileUpload = (ev) => {
    ev.preventDefault();
    const fileUploaded = ev.target.files[0];
    let acceptProfileFileTypes = fileUploaded.type.match(
      /^image\/(jpe?g|png|gif)/
    );

    if (fileUploaded && acceptProfileFileTypes) {
      if (fileUploaded.size < 512 * 512) {
        setTrainingLogo(fileUploaded);
        setPreviewTrainingLogo(URL.createObjectURL(fileUploaded));
        setTrainingLogoMaxFileSizeErr("");
        handleUploadFile(fileUploaded);
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_SIZE = 512 * 512;
            let width = image.width;
            let height = image.height;

            while (width * height > MAX_SIZE) {
              width *= 0.9;
              height *= 0.9;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], fileUploaded.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });
                setTrainingLogo(compressedFile);
                setPreviewTrainingLogo(URL.createObjectURL(compressedFile));
                setTrainingLogoMaxFileSizeErr("");
                handleUploadFile(compressedFile);
              },
              "image/jpeg",
              0.8
            );
          };
        };
        reader.readAsDataURL(fileUploaded);
      }
    } else {
      setTrainingLogoMaxFileSizeErr(
        "Please upload a valid image in jpeg/jpg/png/gif format"
      );
    }
  };

  const handleUploadFile = async (ev) => {
    let formData = new FormData();
    formData.append("file", ev);
    formData.append("path", "training_logo");

    let response = await invokeFormDataApi(
      config.getMyCollege + apiList.uploadFile,
      formData,
      cookies
    );
    if (response?.status >= 200 && response?.status < 300) {
      if (response.data.responseCode === "200") {
        setTrainingLogoPath(response.data.url);
      } else {
        alert(
          "Something went wrong while uploading Training picture. Please try again later!"
        );
      }
    } else if (response?.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while uploading Training picture. Please try again later!!"
      );
    }
  };

  // gallery image validation
  const handleImageGalleryUpload = async (ev) => {
    setGalleryImageError(false);
    setGalleryImageHelperText("");

    const maxImagesAllowed = 15;
    if (ev.target.files) {
      const images = ev.target.files;

      // Check if the number of uploaded images exceeds the limit
      if (galleryImages.length + images.length > maxImagesAllowed) {
        setGalleryImageError(true);
        setGalleryImageHelperText("You can upload a maximum of 15 images");
        return;
      }

      const compressedImages = [];
      const previews = [];
      for (let i = 0; i < images.length; i++) {
        const img = images[i];

        // Validate the image format
        if (!img.type.match(/^image\/(jpe?g|png|gif)$/)) {
          alert("Please upload a valid image in jpeg/jpg/png/gif format");
          continue;
        }

        // Compress the image if necessary
        const compressedImg =
          img.size > 512 * 512 ? await compressImage(img) : img;
        compressedImages.push(compressedImg);

        // Create a preview URL for the compressed image
        previews.push(URL.createObjectURL(compressedImg));
      }

      // Add compressed images and preview URLs to state
      setGalleryImages((prevImages) =>
        prevImages.concat(compressedImages).slice(0, maxImagesAllowed)
      );
      setPreviewImages((prevImages) =>
        prevImages.concat(previews).slice(0, maxImagesAllowed)
      );
    }
  };

  // Compress image function
  const compressImage = (image) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_SIZE = 1024 * 1024;
          let width = img.width;
          let height = img.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], image.name, {
                type: image.type,
              });
              resolve(compressedFile);
            },
            image.type,
            0.8
          );
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(image);
    });
  };

  // Remove image from the gallery
  const deleteImageGallery = (idx) => {
    let updatedGalleryImages = [...galleryImages];
    let updatedPreviewImages = [...previewImages];

    updatedGalleryImages.splice(idx, 1);
    updatedPreviewImages.splice(idx, 1);

    setGalleryImages(updatedGalleryImages);
    setPreviewImages(updatedPreviewImages);
  };

  const handleSave = async () => {
    // Convert arrays to comma-separated strings
    const benefitsString = benefits.join(", ");
    const featuresString = keyFeature.join(", ");

    const updatedCourseDetails = courseDetails.map((course) => ({
      ...course,
      offlineFeatures: course.offlineFeatures.join(", "), // Convert to comma-separated string
      onlineFeatures: course.onlineFeatures.join(", "), // Convert to comma-separated string
    }));

    let params = {
      name: training.name,
      slug: trainingSlug,
      email: emails,
      contact: contactNumbers,
      description: description,
      benefits: benefitsString,
      pincode,
      country,
      state,
      city: district,
      address,
      establishedYear: establishedYear.year,
      features: featuresString,
      logoUrl: trainingLogoPath,
      trainingInstituteGallery: galleryImageURLs, // Use uploaded gallery image URLs
      faculties: faculty,
      courseDetails: updatedCourseDetails,
    };

    try {
      let response = await invokeApi(
        config.getMyCollege + apiList.addIntitute,
        params,
        cookies
      );

      if (
        response?.status >= 200 &&
        response?.status < 300 &&
        response.data.responseCode === "200"
      ) {
        clearCache();
        toast.success("Successfully Saved!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        throw new Error(response.data.responseMessage || "Failed to save data");
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  // Trigger handleSave after all images are uploaded
  useEffect(() => {
    if (allImagesUploaded && galleryImageURLs.length > 0) {
      handleSave();
      setAllImagesUploaded(false); // Reset the flag after saving
    }
  }, [allImagesUploaded, galleryImageURLs]);

  useEffect(() => {
    const getAllTrainingInstituteProgram = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getTrainingInstitutePrograms,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setTrainingInstituteProgramList(
            response.data.trainingInstitutePrograms
          );
        } else {
          alert(
            "Something went wrong while getting the training institute program Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the training institute program Details. Please try again later!!"
        );
      }
    };
    if (isTrainingInstituteProgramFetching) {
      getAllTrainingInstituteProgram();
      setIsTrainingInstituteProgramFetching(false);
    }
  }, [cookies, isTrainingInstituteProgramFetching]);

  useEffect(() => {
    const getAllAddress = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAddress,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setAddressList(response.data.countries);
        } else {
          alert(
            "Something went wrong while getting the Address Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Address Details. Please try again later!!"
        );
      }
    };
    getAllAddress();
  }, [cookies]);

  return (
    <>
      <TrainingInstituteTabs tabActive={0}>
        <form onSubmit={handleUploadGalleryImages}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2} mb={2}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Enter Training Institute Name"
                  autoComplete="off"
                  margin="normal"
                  value={training.name}
                  error={training.error}
                  helperText={training.helperText}
                  onChange={handleTrainingNameChange}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  disabled
                  margin="normal"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  value={trainingSlug}
                  placeholder="Training Institute slug automatic based on Institute name"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  type="text"
                  value={emails}
                  size="small"
                  autoComplete="off"
                  onChange={(e) => setEmails(e.target.value)}
                  label="Enter Email"
                  placeholder="Enter Email"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  type="text"
                  value={contactNumbers}
                  size="small"
                  label="Contact Number"
                  autoComplete="off"
                  onChange={(e) => setContactNumbers(e.target.value)}
                  placeholder="Enter Contact Number"
                  fullWidth
                />
              </Grid>
              {/* below the container have address fields  */}
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography variant="h4">Address</Typography>
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  fullWidth
                  freeSolo={true}
                  value={country}
                  options={addressList.map((item) => item.countryName)}
                  onChange={(e, newValue) => {
                    setCountry(newValue);
                    setCountryError(false);
                    setCountryHelperText("");
                  }}
                  error={countryError}
                  helperText={countryHelperText}
                  renderInput={(params) => (
                    <TextField
                      error={countryError}
                      helperText={countryHelperText}
                      {...params}
                      label="Country"
                      margin="dense"
                      variant="outlined"
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          setCountry(event.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  fullWidth
                  onChange={(e, newValue) => {
                    setState(newValue);
                    setStateError(false);
                    setStateHelperText("");
                  }}
                  error={stateError}
                  helperText={stateHelperText}
                  value={state}
                  options={(
                    addressList.find((item) => item.countryName === country)
                      ?.states || []
                  ).map((state) => state.stateName)}
                  freeSolo={true}
                  disabled={!country}
                  renderInput={(params) => (
                    <TextField
                      error={stateError}
                      margin="dense"
                      helperText={stateHelperText}
                      {...params}
                      label="State"
                      variant="outlined"
                      // value={state}
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          setState(event.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  fullWidth
                  options={districtList}
                  value={districtList.includes(district) ? district : ""}
                  disabled={!state}
                  freeSolo
                  onChange={(e, newValue) => {
                    setDistrict(newValue);
                    setDistrictError(false);
                    setDistrictHelperText("");
                  }}
                  error={districtError}
                  helperText={districtHelperText}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      error={districtError}
                      helperText={districtHelperText}
                      label={
                        districtList.includes(district)
                          ? "District"
                          : "Please Select District(City)"
                      }
                      variant="outlined"
                      onChange={(event, newValue) => {
                        if (!districtList.includes(newValue)) {
                          setDistrict(newValue);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  fullWidth
                  value={pincodeList.includes(pincode) ? pincode : ""}
                  options={pincodeList}
                  disabled={!district}
                  freeSolo
                  onChange={(e, newValue) => {
                    setPincode(newValue);
                    setPincodeError(false);
                    setPincodeHelperText("");
                  }}
                  error={pincodeError}
                  helperText={pincodeHelperText}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label={
                        pincodeList.includes(pincode)
                          ? "Pincode"
                          : "Please Select Postal Code"
                      }
                      onChange={(event, newValue) => {
                        if (!pincodeList.includes(newValue)) {
                          setPincode(newValue);
                        }
                      }}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  type="text"
                  label="Enter Address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setAddressError(false);
                    setAddressHelperText("");
                  }}
                  value={address}
                  margin="dense"
                  error={addressError}
                  helperText={addressHelperText}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Established Year"
                  value={establishedYear.year}
                  onChange={(e) => {
                    setEstablishedYear({
                      year: e.target.value,
                      error: false,
                      helperText: "",
                    });
                  }}
                  autoComplete="off"
                  error={establishedYear.error}
                  helperText={establishedYear.helperText}
                  inputProps={{
                    maxLength: 4,
                  }}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextEditor
                  initialValue={description}
                  onEditorChange={(e) => setDescription(e)}
                />
              </Grid>
              <Grid item md={4} sx={{ textAlign: "center" }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setBenefitsKeyDrawer(true)}
                  sx={{ textTransform: "capitalize" }}
                >
                  Add Benefits And Key Features
                </Button>
              </Grid>
              <Grid item md={4} sx={{ textAlign: "center" }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setCourseFeeDrawer(true)}
                  sx={{ textTransform: "capitalize" }}
                >
                  Add Course Fees
                </Button>
              </Grid>
              <Grid item md={4} sx={{ textAlign: "center" }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setFacultyDrawer(true)}
                  sx={{ textTransform: "capitalize" }}
                >
                  Add Facutly
                </Button>
              </Grid>
            </Grid>
            {/* below the grid container for gallery image upload and preview  */}
            <Grid container spacing={2}>
              {/* college logo  */}
              <Grid item md={6}>
                <Box
                  sx={{
                    border: trainingLogoError
                      ? "2px dashed red"
                      : "2px dashed #009688",
                    borderRadius: "15px",
                    padding: "20px",
                    backgroundColor: "#f5f5f5",
                    textAlign: "center",
                    cursor: "pointer",
                    transition: "border 0.3s",
                    ":hover": {
                      border: "2px dashed #005058",
                    },
                  }}
                >
                  <label htmlFor="logo-pic-college">
                    <input
                      id="logo-pic-college"
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileUpload(e)}
                    />
                    {trainingLogo ? (
                      <img
                        src={previewTrainingLogo}
                        alt="College Logo"
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "cover",
                          borderRadius: "10px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    ) : (
                      <>
                        <CloudUploadIcon
                          sx={{
                            fontSize: 80,
                            color: trainingLogoError ? "#d32f2f" : "#009688",
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            color: trainingLogoError ? "#d32f2f" : "#009688",
                            marginTop: "10px",
                          }}
                        >
                          {trainingLogoError
                            ? trainingLogoHelperText
                            : "Upload Training Institute Logo *"}
                        </Typography>
                        <Typography
                          variant="bodyparagraph"
                          sx={{ color: "#757575" }}
                        >
                          {trainingLogomaxFileSizeErr || "Max size: 1MB"}
                        </Typography>
                      </>
                    )}
                  </label>
                </Box>
              </Grid>
              {/* Gallery image input */}
              <Grid item md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#0077B6",
                      }}
                    >
                      Image Gallery
                    </Typography>
                    <Typography
                      variant="bodyregular"
                      sx={{
                        fontSize: "16px",
                        textAlign: "center",
                        color: "#333",
                      }}
                    >
                      You can upload multiple images (max 15)
                    </Typography>
                  </Box>
                  {previewImages.length < 15 && (
                    <label htmlFor="image-gallery">
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="image-gallery"
                        multiple
                        type="file"
                        onClick={(ev) => (ev.target.value = "")}
                        onChange={handleImageGalleryUpload}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          background: galleryImageError ? "#d32f2f" : "#405189",
                          borderRadius: 5,
                          color: "white",
                          height: 48,
                          padding: "0 30px",
                          "&:hover": {
                            backgroundColor: galleryImageError
                              ? "#d32f2f"
                              : "#405189",
                          },
                        }}
                      >
                        {galleryImageError
                          ? galleryImageHelperText
                          : "Upload Images *"}
                      </Button>
                    </label>
                  )}
                </Box>
              </Grid>

              {/* Gallery image preview */}
              <Grid item md={12}>
                <Box sx={{ overflowX: "auto", display: "flex" }}>
                  <ImageList cols={3} sx={{ flexWrap: "nowrap" }}>
                    {previewImages.map((image, idx) => (
                      <ImageListItem
                        key={idx}
                        sx={{
                          margin: "8px",
                          cursor: "pointer",
                          transition: "transform 0.3s",
                          "&:hover": {
                            transform: "scale(1.05)",
                          },
                        }}
                      >
                        <img
                          src={image}
                          loading="lazy"
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                          alt="gallery-img"
                        />
                        <ImageListItemBar
                          sx={{
                            background:
                              "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                          }}
                          position="top"
                          actionIcon={
                            <IconButton onClick={() => deleteImageGallery(idx)}>
                              <CloseIcon sx={{ color: "white" }} />
                            </IconButton>
                          }
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </TrainingInstituteTabs>
      {/* below the modal is for add Benefits and Key Features  */}
      <Dialog
        fullScreen
        open={benefitsKeyDrawer}
        onClose={() => setBenefitsKeyDrawer(false)}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => setBenefitsKeyDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Benefits and Key Features
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setBenefitsKeyDrawer(false)}
            >
              Submit
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 5 }}>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Paper elevation={2}>
                <Typography variant="h6" sx={{ ml: 2 }}>
                  {" "}
                  Benefits{" "}
                </Typography>
                <Divider />
                {benefits?.map((item, ben) => (
                  <Box key={ben} sx={{ p: 2 }}>
                    <TextField
                      size="small"
                      placeholder="Enter Benefits"
                      value={item}
                      onChange={(e) => {
                        const value = e.target.value;
                        let initialValueInput = [...benefits];
                        initialValueInput[ben] = value;
                        setBenefits(initialValueInput);
                      }}
                      fullWidth
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <IconButton
                        sx={{ backgroundColor: "green" }}
                        onClick={() => handleAddBenefits()}
                      >
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        sx={{ backgroundColor: "red" }}
                        onClick={() => handleRemoveBenefits(ben)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Paper>
            </Grid>
            <Grid item md={6}>
              <Paper elevation={2}>
                <Typography variant="h6" sx={{ ml: 2 }}>
                  Key Features
                </Typography>
                <Divider />
                {keyFeature?.map((item, index) => (
                  <Box key={index} sx={{ p: 2 }}>
                    <TextField
                      size="small"
                      placeholder="Enter Key Feature"
                      value={item}
                      onChange={(e) => {
                        const value = e.target.value;
                        let initialValueInput = [...keyFeature];
                        initialValueInput[index] = value;
                        setKeyFeature(initialValueInput);
                      }}
                      fullWidth
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <IconButton
                        sx={{ backgroundColor: "green" }}
                        onClick={() => handleAddKeyFeature1()}
                      >
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        sx={{ backgroundColor: "red" }}
                        onClick={() => handleRemoveKeyFeature1(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      {/* below the modal is for add Course Fees  */}
      <Dialog
        fullScreen
        open={courseFeeDrawer}
        onClose={() => setCourseFeeDrawer(false)}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => setCourseFeeDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Course Fees
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setCourseFeeDrawer(false)}
            >
              Submit
            </Button>
          </Toolbar>
        </AppBar>
        <Typography variant="h4">Coureses Fees</Typography>
        {courseDetails.map((item, index) => (
          <Grid
            container
            spacing={2}
            component={Paper}
            elevation={4}
            sx={{ p: 4, mt: 4 }}
            key={index}
          >
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Autocomplete
                disablePortal
                options={trainingInstituteProgramList}
                getOptionLabel={(options) => options.trainingInstituteProgram}
                value={
                  trainingInstituteProgramList.find(
                    (program) => program.id === item.trainingInstituteProgramId
                  ) || null
                }
                onChange={(event, newValue) => {
                  const updatedCourseDetails = [...courseDetails];
                  updatedCourseDetails[index].trainingInstituteProgramId =
                    newValue ? newValue.id : null;
                  setCourseDetails(updatedCourseDetails); // Assuming you have a setCourseDetails function
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Course Type" />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <TextField
                label="Course Name"
                fullWidth
                autoComplete="off"
                value={item.courseName}
                onChange={(e) => handleChangeCourseName(e, index)}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  p: 2,
                  backgroundColor: "#002147",
                  color: "white",
                  borderRadius: "10px 10px 0 0",
                }}
              >
                Offline Fees
              </Typography>
              <Divider />
              <Grid sx={{ p: 2 }}>
                <TextField
                  type="text"
                  value={item.offlineFee}
                  size="small"
                  autoComplete="off"
                  onChange={(e) => handleChangeOfflineFee(e, index)}
                  label="Enter Fees"
                  placeholder="Enter Fees"
                  fullWidth
                />
              </Grid>
              <Grid sx={{ p: 2 }}>
                <TextField
                  select
                  value={item.offlineDuration}
                  onChange={(e) => handleChangeOfflineDuration(e, index)}
                  variant="outlined"
                  margin="normal"
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => {
                      if (!value || value === "") {
                        return "Select Duration";
                      }
                      return value;
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "2.4em" },
                  }}
                >
                  {durationOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {item.offlineFeatures?.map((item, offline) => (
                <Box key={offline} sx={{ p: 2 }}>
                  <TextField
                    size="small"
                    placeholder="Enter Features"
                    value={item}
                    onChange={(e) =>
                      handleChangeOfflineFeatures(e, offline, index)
                    }
                    fullWidth
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <IconButton
                      sx={{ backgroundColor: "green" }}
                      onClick={() => handleAddOfflineFeatures(index)}
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      sx={{ backgroundColor: "red" }}
                      onClick={() =>
                        handleRemoveOfflineFeatures(offline, index)
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  p: 2,
                  backgroundColor: "#002147",
                  color: "white",
                  borderRadius: "10px 10px 0 0",
                }}
              >
                Online Fees
              </Typography>
              <Grid sx={{ p: 2 }}>
                <TextField
                  type="text"
                  value={item.onlineFee}
                  size="small"
                  autoComplete="off"
                  onChange={(e) => handleChangeOnlineFee(e, index)}
                  label="Enter Fees"
                  placeholder="Enter Fees"
                  fullWidth
                />
              </Grid>
              <Grid sx={{ p: 2 }}>
                <TextField
                  select
                  value={item.onlineDuration}
                  onChange={(e) => handleChangeOnlineDuration(e, index)}
                  variant="outlined"
                  margin="normal"
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => {
                      if (!value || value === "") {
                        return "Select Duration";
                      }
                      return value;
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "2.4em" },
                  }}
                >
                  {durationOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {item.onlineFeatures?.map((item, online) => (
                <Box key={online} sx={{ p: 2 }}>
                  <TextField
                    size="small"
                    placeholder="Enter Key Feature"
                    value={item}
                    onChange={(e) =>
                      handleChangeOnlineFeatures(e, online, index)
                    }
                    fullWidth
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <IconButton
                      sx={{ backgroundColor: "green" }}
                      onClick={() => handleAddOnlineFeatures(index)}
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      sx={{ backgroundColor: "red" }}
                      onClick={() => handleRemoveOnlineFeatures(online, index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Grid>
            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleAddCourse(index)}
                >
                  Add
                </Button>
              </Grid>
              {courseDetails.length > 1 && (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to remove this course?"
                        )
                      ) {
                        handleRemoveCourse(index);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Dialog>
      {/* below the modal is for add faculty  */}
      <Dialog
        fullScreen
        open={facultyDrawer}
        onClose={() => setFacultyDrawer(false)}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => setFacultyDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Faculty
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setFacultyDrawer(false)}
            >
              Submit
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 5 }}>
          {faculty?.map((item, index) => (
            <Paper elevation={3} sx={{ p: 2, mb: 2 }} key={index}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <TextField
                    label="Name"
                    fullWidth
                    value={item.name}
                    size="small"
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => {
                      const value = e.target.value;
                      let updatedFaculty = [...faculty];
                      updatedFaculty[index].name = value;
                      setFaculty(updatedFaculty);
                    }}
                  />
                  <TextField
                    label="Profession"
                    fullWidth
                    value={item.profession}
                    size="small"
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => {
                      const value = e.target.value;
                      let updatedFaculty = [...faculty];
                      updatedFaculty[index].profession = value;
                      setFaculty(updatedFaculty);
                    }}
                  />
                  <TextField
                    label="Experience"
                    fullWidth
                    id="outlined-end-adornment"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          Year {/* This could be any text or icon */}
                        </InputAdornment>
                      ),
                    }}
                    value={item.experience}
                    size="small"
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => {
                      const value = e.target.value;
                      let updatedFaculty = [...faculty];
                      updatedFaculty[index].experience = value;
                      setFaculty(updatedFaculty);
                    }}
                  />
                  <TextEditor
                    initialValue={item.description}
                    onEditorChange={(e) => {
                      let updatedFaculty = [...faculty];
                      updatedFaculty[index].description = e;
                      setFaculty(updatedFaculty);
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 3, display: "flex" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: "green",
                    },
                  }}
                  onClick={handleAddFaculty}
                >
                  Add
                </Button>
                {faculty.length > 1 && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: "red",
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                    onClick={() => handleRemoveFaculty(index)}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Paper>
          ))}
        </Box>
      </Dialog>
    </>
  );
}

export default TrainingInstitute;
