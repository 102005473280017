import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import DataTable from "react-data-table-component";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import TeamLeaderMappingTabs from "./TeamLeaderMappingTabs";
import { getUser } from "../../../global/redux/action";
import { useNavigate } from "react-router-dom";

const TeamLeaderMappingAdd = () => {
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [teamLeader, setTeamLeader] = useState("");
  const [userError1, setUserError] = useState(false);
  const [userHelperText, setUserHelperText] = useState("");
  const [search, setSearch] = useState("");
  const [fetching, setFetching] = useState(true);
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [teamLeaderList, setTeamLeaderList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const enableButton = teamLeader && selectedRows > 0;

  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies();

  // const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Name",
      selector: (row) => row.collegeName,
      sortable: true,
    },
  ];

  const saveTeamLeaderMapping = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    var validationError = false;
    if (!teamLeader) {
      setUserError(true);
      setUserHelperText("Please Select Team Leader");
      validationError = true;
    }
    if (!validationError) {
      let params = {
        collegeIds: selectedRows,
        teamLeaderUserId: teamLeader,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.addCollegeTeamLeader,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setFetching(true);
          setSaveLoading(false);
          setSelectedRows([]);
          setTeamLeader("");
          alert("Colleges Successfull Assigned to Team Leader");
        } else {
          alert(
            "Something went wrong while Add College Team Leader Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else {
        alert(
          "Something went wrong while College Team Leader Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows.map((row) => row.id));
  };

  useEffect(() => {
    const getAllUnAssignedColleges = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getUnAssignedCollege,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setData(response.data.colleges);
          setFilter(response.data.colleges);
          setLoading(false);
        } else {
          alert(
            "Something went wrong while getting the Colleges Details. Please try again later!"
          );
          setLoading(false);
        }
      } else {
        alert(
          "Something went wrong while getting the Colleges Details. Please try again later!!"
        );
        setLoading(false);
      }
    };
    const getAllTeamLeader = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getUsers,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setTeamLeaderList(
            response.data.users.filter(
              (user) => user.roles && user.roles.includes("Team Leader")
            )
          );
        } else {
          alert(
            "Something went wrong while getting the Team Leader Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Team Leader Details. Please try again later!!"
        );
      }
    };
    if (fetching) {
      getAllTeamLeader();
      getAllUnAssignedColleges();
    }
  }, [cookies, fetching]);

  useEffect(() => {
    const result = data.filter((item) => {
      return item.collegeName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <TeamLeaderMappingTabs tabActive={1}>
        <Box>
          <form onSubmit={saveTeamLeaderMapping}>
            <Paper sx={{ display: "flex", alignItems: "center", px: 2, mb: 2 }}>
              <Typography>Team Leader</Typography>
              <FormControl sx={{ my: 1, width: "300px", ml: 2 }}>
                <InputLabel id="select-team-leader">
                  Select Team Leader
                </InputLabel>
                <Select
                  labelId="select-team-leader"
                  id="select-team-leader"
                  value={teamLeader}
                  error={userError1}
                  helperText={userHelperText}
                  label="Team Leader"
                  onChange={(e) => setTeamLeader(e.target.value)}
                >
                  {teamLeaderList
                    ?.filter((item) => item.status === "Active")
                    ?.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Paper>
            <DataTable
              columns={columns}
              data={filter}
              pagination
              selectableRows
              fixedHeader
              highlightOnHover
              subHeaderAlign="left"
              onSelectedRowsChange={handleChange}
              progressPending={loading}
              subHeader
              subHeaderComponent={
                <TextField
                  type="text"
                  placeholder="Search....."
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              }
            />
            <Paper sx={{ textAlign: "center", py: 2 }}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={saveLoading || !enableButton}
              >
                Assign
                {saveLoading && <CircularProgress size={20} />}
              </Button>
            </Paper>
          </form>
        </Box>
      </TeamLeaderMappingTabs>
    </>
  );
};

export default TeamLeaderMappingAdd;
