import React, { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { apiList, invokeApi } from "../../../services/apiServices";

const TabBox = {
  // border: { xs: "none", md: " ridge " },
  borderBottom: "1px solid #F5F5F5",
  shadows: "gray",
  backgroundColor: { xs: "#F5F5F5", md: "white" },
  justifyContent: "Start",
  width: { xs: "100%" },
  minHeight: { xs: "30px", md: "100%" },
  height: { xs: "40px", md: "55px" },
  textTransform: "capitalize",
};

export const topCities = [
  {
    name: "Ahmedabad",
    svg: "/media/svg/ahmedabad.svg",
  },
  {
    name: "Bengaluru",
    svg: "/media/svg/bangalore.svg",
  },
  {
    name: "Bhubaneswar",
    svg: "/media/svg/Bhubaneswar.svg",
  },
  {
    name: "Chandigarh",
    svg: "/media/svg/Chandigarh.svg",
  },
  {
    name: "Chennai",
    svg: "/media/svg/Chennai.svg",
  },
  {
    name: "Dehradun",
    svg: "/media/svg/Dehradun.svg",
  },
  {
    name: "Delhi",
    svg: "/media/svg/Delhi.svg",
  },
  {
    name: "Faridabad",
    svg: "/media/svg/Faridabad.svg",
  },
  {
    name: "Ghaziabad",
    svg: "/media/svg/Ghaziabad.svg",
  },
  {
    name: "Greater Noida",
    svg: "/media/svg/Greater-Noida.svg",
  },
  {
    name: "Gurgaon",
    svg: "/media/svg/Gurgaon.svg",
  },
  {
    name: "Hyderabad",
    svg: "/media/svg/Hyderabad.svg",
  },
  {
    name: "Indore",
    svg: "/media/svg/Indore.svg",
  },
  {
    name: "Jaipur",
    svg: "/media/svg/Jaipur.svg",
  },
  {
    name: "Kolkata",
    svg: "/media/svg/Kolkata.svg",
  },
  {
    name: "Lucknow",
    svg: "/media/svg/Lucknow.svg",
  },
  {
    name: "Meerut",
    svg: "/media/svg/Meerut.svg",
  },
  {
    name: "Mohali",
    svg: "/media/svg/Mohali.svg",
  },
  {
    name: "mumbai",
    svg: "/media/svg/mumbai.svg",
  },
  {
    name: "noida",
    svg: "/media/svg/noida.svg",
  },
  {
    name: "vizag",
    svg: "/media/svg/vizag.svg",
  },
];

export const otherCities = [
  "Nagpur",
  "Navi Mumbai",
  "Panchkula",
  "Panipat",
  "Pune",
  "Sonipat",
  "Thane",
  "Vijayawada",
  "Zirakpur",
  "Agra",
  "Allahabad",
  "Bareilly",
  "Bhiwadi",
  "Bhopal",
  "Goa",
  "Guwahati",
  "Patna",
  "Rajkot",
  "Varanasi",
  "Agartala",
  "Ahmednagar",
  "Ajmer",
  "Akola",
  "Alappuzha",
  "Aligarh",
  "Almora",
  "Alwar",
  "Ambala",
  "Ambala Sadar",
  "Amravati",
  "Amritsar",
  "Anakapalle",
  "Anand",
  "Anantapur",
  "Ankleshwar",
  "Asansol",
  "Aurangabad",
  "Ayodhya",
  "Azamgarh",
  "Badlapur",
  "Bagalkot",
  "Bahadurgarh",
  "Balasore",
  "Ballia",
  "Bapatla",
  "Barabanki",
  "Baramati",
  "Baran",
  "Bardhaman",
  "Bargarh",
  "Belagavi",
  "Belgaum",
  "Bellary",
  "Berhampore",
  "Bhagalpur",
  "Bharuch",
  "Bhavnagar",
  "Bhilai",
  "Bidar",
  "Bijapur",
  "Bikaner",
  "Bilaspur",
  "Bokaro Steel City",
  "BolpurSantiniketan",
  "Bongaigaon",
  "Brahmapur",
  "Bundi",
  "Chandrapur",
  "Chapra",
  "Chhindwara",
  "Chikkaballapur",
  "Chikmagalur",
  "Chitradurga",
  "Chittor",
  "Chopda",
  "Coimbatore",
  "Coonoor",
  "Coorg",
  "Cuddalore",
  "Cuttack",
  "Dakshina Kannada",
  "Daman",
  "Damoh",
  "Darbhanga",
  "Darjeeling",
  "Dausa",
  "Davangere",
  "Deoghar",
  "Deoria",
  "Dewas",
  "Dhanbad",
  "Dharamshala",
  "Dharuhera",
  "Dharwad",
  "Dhule",
  "Dibrugarh",
  "Dindigul",
  "Durg",
  "Durgapur",
  "Dwarka",
  "East Singhbhum",
  "Eluru",
  "Erode",
  "Faizabad",
  "Fatehabad",
  "Firozabad",
  "Gandhidham",
  "Gandhinagar",
  "Gaya",
  "Ghazipur",
  "Gondia",
  "Gorakhpur",
  "Gudur",
  "Gulbarga",
  "Guntur",
  "Gwalior",
  "Hajipur",
  "Haldwani",
  "Hapur",
  "Hardoi",
  "Haridwar",
  "Hathras",
  "Hazaribag",
  "Hisar",
  "Hoshiarpur",
  "Hosur",
  "Hosur",
  "Hubli",
  "Jabalpur",
  "Jagtial",
  "Jalandhar",
  "Jalgaon",
  "Jammu",
  "Jamnagar",
  "Jamshedpur",
  "Jhajjar",
  "Jhansi",
  "Jind",
  "Jodhpur",
  "Jorhat",
  "Junagadh",
  "Kadi",
  "Kaithal",
  "Kakinada",
  "Kalol",
  "Kalyan",
  "Kanchipuram",
  "Kangra",
  "Kannauj",
  "Kannur",
  "Kanpur",
  "Kanpur Nagar",
  "Kanyakumari",
  "Karimnagar",
  "Karnal",
  "Karur",
  "Kasaragod",
  "Kashipur",
  "Katni",
  "Khammam",
  "Kharar",
  "Khatauli",
  "Khordha",
  "Kochi",
  "Kolar",
  "Kolhapur",
  "Kollam",
  "Kota",
  "Kotdwar",
  "Kottayam",
  "Kozhikode",
  "Krishnagiri",
  "Kullu",
  "Kurnool",
  "Kurukshetra",
  "Kushinagar",
  "Latur",
  "Lonavla",
  "Ludhiana",
  "Madurai",
  "Mahbubnagar",
  "Manali",
  "Mandsaur",
  "Mandya",
  "Mangalagiri",
  "Mangalore",
  "Mansa",
  "Mathura",
  "Mehsana",
  "Mettupalayam",
  "Midnapore",
  "Mirzapur",
  "Modinagar",
  "Moga",
  "Moradabad",
  "Morbi",
  "Motihari",
  "Motihari",
  "Munger",
  "Muradnagar",
  "Muzaffarnagar",
  "Muzaffarpur",
  "Mysore",
  "Nadiad",
  "Nagaur",
  "Nagercoil",
  "Nainital",
  "Nalgonda",
  "Namakkal",
  "Nashik",
  "Neemrana",
  "Nellore",
  "Nizamabad",
  "North Goa",
  "Ongole",
  "Ooty",
  "Palakkad",
  "Palani",
  "Palghar",
  "Palghar",
  "Palwal",
  "Patan",
  "Pathanamthitta",
  "Pathankot",
  "Patiala",
  "Pimpri Chinchwad",
  "Pithoragarh",
  "Pollachi",
  "Pondicherry",
  "Proddatur",
  "Pudukkottai",
  "Puri",
  "Raigad",
  "Raigarh",
  "Raipur",
  "Raisen",
  "Raj Nandgaon",
  "Rajahmundry",
  "Rajgarh",
  "Ramanagaram",
  "Ramgarh",
  "Ranchi",
  "Ratnagiri",
  "Rewa",
  "Rewari",
  "Rishikesh",
  "Rohtak",
  "Roorkee",
  "Rourkela",
  "SAS Nagar",
  "Sagr",
  "Saharanpur",
  "Salem",
  "Samastipur",
  "Sambalpur",
  "Sambhal",
  "Sangareddy",
  "Sangli",
  "Sangrur",
  "Satara",
  "Satna",
  "Sehore",
  "Shahjahanpur",
  "Shamli",
  "Shimla",
  "Shimoga",
  "Siddipet",
  "Sikar",
  "Silchar",
  "Siliguri",
  "Silvassa",
  "Sindhudurg",
  "Sirohi",
  "Sirsa",
  "Sivakasi",
  "Solan",
  "Solapur",
  "Srikakulam",
  "Srinagar",
  "Sultanpur",
  "Surat",
  "Surat",
  "Surendranagar",
  "Suryapet",
  "Tanuku",
  "Tarn Taran Sahib",
  "Tezpur",
  "Thalassery",
  "Thanjavur",
  "Thiruvananthapuram",
  "Thrissur",
  "Tindivanam",
  "Tiruchirappalli",
  "Tirunelveli",
  "Tirupati",
  "Tiruvalla",
  "Tiruvannamalai",
  "Tonk",
  "Trichy",
  "Tumkur",
  "Udaipur",
  "Udham Singh Nagar",
  "Udupi",
  "Ujjain",
  "Unnao",
  "Vadodara",
  "Valsad",
  "Vapi",
  "Vellore",
  "Vizianagaram",
  "Vrindavan",
  "Warangal",
  "Wardha",
  "Wayanad",
  "Yamuna Nagar",
  "Yavatmal",
];

export default function MenuItems({ name }) {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  const [programsList, setProgramsList] = useState([]);
  const [filteredTopCities, setFilteredTopCities] = useState([]);
  const [filteredOtherCities, setFilteredOtherCities] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeProgram, setActiveProgram] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [programFetching, setProgramFetching] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveProgram(event.target.innerText.toLowerCase());
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    // Handle search logic here
  };

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  useEffect(() => {
    // Filter top cities
    const filteredTop = topCities.filter((city) =>
      city.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredTopCities(filteredTop);

    // Filter other cities
    const filteredOther = otherCities.filter((cityName) =>
      cityName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOtherCities(filteredOther);
  }, [searchText, topCities, otherCities]);

  useEffect(() => {
    const getAllPrograms = async () => {
      let params = {
        status: "Active",
        instituteType: "college",
      };

      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );

      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          const firstProgram = response.data?.programs[0]?.programName;
          setProgramsList(response.data.programs);
          setActiveProgram(firstProgram);
          console.log("fetching program");
        } else {
          alert(
            "Something went wrong while getting the Program Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Program Details. Please try again later!!"
        );
      }
    };

    // Check if programs are still fetching
    if (programFetching) {
      getAllPrograms();
      setProgramFetching(false);
    }
  }, [cookies, programFetching]);

  useEffect(() => {
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
        programName: activeProgram,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseList(response.data.courses);
        } else {
          alert(
            "Something went wrong while getting the course list Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the course list Details. Please try again later!!"
        );
      }
    };

    if (activeProgram) {
      getAllCourseName();
    }
  }, [cookies, activeProgram]);

  const getAllSpecializationName = async (id) => {
    let params = {
      status: "Active",
      courseId: id,
    };
    console.log(params);
    let response = await invokeApi(
      config.getMyCollege + apiList.getSpecialization,
      params,
      cookies
    );
    if (response?.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        setSpecializationList(response.data.specialization);
      } else {
        alert(
          "Something went wrong while getting the specialization list Details. Please try again later!"
        );
      }
    } else {
      alert(
        "Something went wrong while getting the specialization list Details. Please try again later!!"
      );
    }
  };

  function handleSetProgramCookie(id) {
    const updatedCookies = {
      ...cookies[config.filterCookie],
      program: id,
    };
    setCookie(config.filterCookie, JSON.stringify(updatedCookies), {
      path: "/",
      maxAge: 3000000,
      sameSite: "strict",
    });
  }

  function handleSetCourseCookie(id) {
    const updatedCookies = {
      ...cookies[config.filterCookie],
      course: id,
    };
    setCookie(config.filterCookie, JSON.stringify(updatedCookies), {
      path: "/",
      maxAge: 3000000,
      sameSite: "strict",
    });
    getAllSpecializationName(id);
  }

  return (
    <>
      <Button
        variant="text"
        sx={{ color: "whitesmoke", textTransform: "capitalize" }}
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        {name}
        {anchorEl === null ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center", // Center the menu horizontally
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        {name === "Colleges" ? (
          <Box
            sx={{
              minWidth: "800px",
              minHeight: "400px",
              backgroundColor: "#fff",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={4}
                sx={{
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderColor: "divider",
                    boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25);",
                    color: "#5B5B5B",
                    paddingBottom: "1px",
                    overflowY: "auto",
                  }}
                >
                  {programsList.map((item, index) => (
                    <Tab
                      icon={
                        <img
                          src="/media/svg/bachelor.svg"
                          alt="degree icon"
                          width="25px"
                        />
                      }
                      onClick={handleSetProgramCookie.bind(this, item.id)}
                      key={index}
                      iconPosition="start"
                      label={item.programName}
                      sx={TabBox}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid item xs={8}>
                <TabPanel value={value} index={value}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      padding: "30px",
                      gridGap: "10px",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Colleges By Course
                      </Typography>
                      <List sx={{ fontSize: "14px" }}>
                        {courseList?.slice(0, 10).map((item, index) => (
                          <ListItem key={index}>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                                color:
                                  cookies[config.filterCookie]?.course ===
                                  item.id
                                    ? "#007bff"
                                    : "#7f8c8d",
                                cursor: "pointer",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                              onClick={handleSetCourseCookie.bind(
                                this,
                                item.id
                              )}
                            >
                              {item.courseName}
                              <ArrowForwardIcon />
                            </Typography>
                          </ListItem>
                        ))}
                        <ListItem>
                          <Link
                            to="/colleges/college"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                              color: "#95a5a6",
                            }}
                          >
                            <ListItemText primary="View All" />
                            <ListItemIcon>
                              <ChevronRightIcon />
                            </ListItemIcon>
                          </Link>
                        </ListItem>
                      </List>
                    </Box>
                    {specializationList?.length > 0 && (
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Colleges By Specialization
                        </Typography>
                        <List sx={{ fontSize: "14px" }}>
                          {specializationList.map((item, index) => (
                            <ListItem key={index}>
                              <Link
                                to="/colleges/college"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  textDecoration: "none",
                                  color: "#007bff", // Blue color for list items
                                }}
                              >
                                {item.specialization}
                              </Link>
                            </ListItem>
                          ))}
                          <ListItem>
                            <Link
                              to="/colleges/college"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                                color: "#95a5a6",
                              }}
                            >
                              <ListItemText primary="View All" />
                              <ListItemIcon>
                                <ChevronRightIcon />
                              </ListItemIcon>
                            </Link>
                          </ListItem>
                        </List>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <Button
                      variant="outlined"
                      sx={{ position: "fixed", width: "34%" }}
                    >
                      Result
                    </Button>
                  </Box>
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
        ) : name === "Schools" ? (
          <Box sx={{ minWidth: "200px" }}>
            <List>
              <ListItem button onClick={() => navigate("/schools/school")}>
                CBSE
              </ListItem>
              <ListItem button onClick={() => navigate("/schools/school")}>
                IGCSE
              </ListItem>
              <ListItem button onClick={() => navigate("/schools/school")}>
                State Board
              </ListItem>
              <ListItem button onClick={() => navigate("/schools/school")}>
                IB
              </ListItem>
              <ListItem button onClick={() => navigate("/schools/school")}>
                NIOS
              </ListItem>
              <ListItem button onClick={() => navigate("/schools/school")}>
                AISSCE
              </ListItem>
            </List>
          </Box>
        ) : name === "Training Institutes" ? (
          <Box
            sx={{
              backgroundColor: "#ced6e0",
              padding: "30px 40px",
            }}
            className="border"
          >
            <MenuItem
              onClick={() =>
                navigate("/training-institutes/training-institute")
              }
            >
              <ListItemIcon style={{ minWidth: "20px" }}>
                <FiberManualRecordIcon style={{ fontSize: 12 }} />{" "}
                {/* Dot icon */}
              </ListItemIcon>
              <Typography variant="inherit" sx={{ fontSize: "14px" }}>
                Website Development
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate("/training-institutes/training-institute")
              }
            >
              <ListItemIcon style={{ minWidth: "20px" }}>
                <FiberManualRecordIcon style={{ fontSize: 12 }} />{" "}
                {/* Dot icon */}
              </ListItemIcon>
              <Typography variant="inherit" sx={{ fontSize: "14px" }}>
                Mobile App Development
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate("/training-institutes/training-institute")
              }
            >
              <ListItemIcon style={{ minWidth: "20px" }}>
                <FiberManualRecordIcon style={{ fontSize: 12 }} />{" "}
                {/* Dot icon */}
              </ListItemIcon>
              <Typography variant="inherit" sx={{ fontSize: "14px" }}>
                Digital Marketing
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate("/training-institutes/training-institute")
              }
            >
              <ListItemIcon style={{ minWidth: "20px" }}>
                <FiberManualRecordIcon style={{ fontSize: 12 }} />{" "}
                {/* Dot icon */}
              </ListItemIcon>
              <Typography variant="inherit" sx={{ fontSize: "14px" }}>
                Graphic Design
              </Typography>
            </MenuItem>
          </Box>
        ) : (
          <List>
            <ListItem>
              <Input
                placeholder="Search"
                value={searchText}
                onChange={handleSearchChange}
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </ListItem>
            <ListItem sx={{ cursor: "default", fontWeight: "bold" }}>
              <ListItemText
                primary="Top Cities"
                sx={{
                  fontSize: "13px",
                  textDecoration: "underline",
                }}
              />
            </ListItem>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                px: "15px",
                columnGap: "10px",
              }}
            >
              {filteredTopCities?.map((cities, index) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    my: "10px",
                    p: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCookie(
                      config.preferencesCookie,
                      JSON.stringify({
                        ...cookies[config.preferencesCookie],
                        city: cities.name,
                      }),
                      {
                        path: "/",
                        maxAge: 3000000,
                        sameSite: "strict",
                      }
                    );
                    handleClose();
                  }}
                  key={index}
                >
                  <img src={cities.svg} height="30px" alt="cities" />
                  <Typography
                    variant="caption"
                    sx={{ "&:hover": { color: "blue" } }}
                  >
                    {cities.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            <ListItem sx={{ cursor: "default", fontWeight: "bold" }}>
              <ListItemText
                primary="Other Cities"
                sx={{
                  fontSize: "13px",
                  textDecoration: "underline",
                }}
              />
            </ListItem>
            {filteredOtherCities?.map((subitem, subIndex) => (
              <ListItem
                key={subIndex}
                button
                // onClick={handleMenuClose}
              >
                <ListItemText primary={subitem} />
              </ListItem>
            ))}
          </List>
        )}
      </Menu>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
