import React, { useEffect, useState } from "react";
import SchoolTabs from "./SchoolTabs";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import DataTable from "react-data-table-component";
import SearchIcon from "@mui/icons-material/Search";
import { Bounce, toast } from "react-toastify";

const SchoolList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [schools, setSchools] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
    },
    {
      name: "Logo",
      selector: (row) => (
        <img
          src={row.schoolLogo}
          height={70}
          alt={row.schoolName}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/loginlogo.png";
          }}
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.schoolName,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.city + "," + row.state + "," + row.pincode,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <IconButton
            size="small"
            color="success"
            onClick={(e) => navigate(`/edit-school/${row.id}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            onClick={() => deleteSchool(row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const deleteSchool = async (schoolId) => {
    let params = {
      status: "Deleted",
      id: schoolId,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.changeSchoolStatus,
      params,
      cookies
    );
    if (response?.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("SuccessFully Updated!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setFetching(true);
      } else {
        alert(
          "Something went wrong while updating the schools. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while updating the schools. Please try again later!!"
      );
    }
  };

  // fetch schools
  useEffect(() => {
    const getAllSchools = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getSchools,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setSchools(response.data.schools);
          setFilter(response.data.schools);
        } else {
          alert(
            "Something went wrong while getting the schools List. Please try again later!"
          );
          setFetching(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the schools List. Please try again later!!"
        );
        setFetching(false);
      }
    };
    if (fetching) {
      getAllSchools();
      setFetching(false);
    }
  }, [cookies, fetching]);

  useEffect(() => {
    const result = schools.filter((item) => {
      return item.schoolName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);

  return (
    <>
      <SchoolTabs tabActive={1}>
        <DataTable
          columns={columns}
          data={filter}
          pagination
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={fetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </SchoolTabs>
    </>
  );
};

export default SchoolList;
