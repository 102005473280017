import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../general-components/ui-components/Header";
import Footer from "../../general-components/ui-components/Footer";
import { Link, useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  const imgStyle = {
    img1: {
      width: "100%",
      height: "20vh",
      backgroundColor: "black", // Set a background color for the outer Box
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundImage: `url("/media/banner-image.jpg")`,
    },
    img2: {
      width: "100%",
      height: "20vh",
      backgroundColor: "rgba(200, 170, 65, 0.5)", // Use rgba for a transparent background color
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />

      <Box sx={imgStyle.img1}>
        <Box sx={imgStyle.img2}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { md: "30px" },
              color: "#fff",
            }}
          >
            About Get My Studies
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { md: "20px" },
              color: "#fff",
            }}
          >
            India's fastest growing college search platform aiming to help
            students choose the right college for their ambitious career
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        sx={{ justifyContent: "center", marginTop: { md: "80px", sx: "10px" } }}
      >
        <Grid>
          <Box sx={{ margin: { md: "100px", xs: "50px" } }}>
            <Typography
              sx={{ fontSize: { md: "20px", xs: "25px" }, color: "#273c75" }}
            >
              Our Mission
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              Get My Studies ambitiously aims to remove hurdles and aspires to
              be{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              a catalyst in providing an online boutique for students who want{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              to pursue their dream career by helping them make the right{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              academic choices.
            </Typography>
          </Box>
        </Grid>
        <Box>
          <img src="./media/students.png" alt="student" />
        </Box>
      </Grid>
      <Grid
        container
        sx={{ justifyContent: "center", marginTop: { md: "80px", sx: "10px" } }}
      >
        <Grid>
          {" "}
          <Box sx={{ margin: { md: "40px", xs: "50px" } }}>
            <Typography
              sx={{ fontSize: { md: "20px", xs: "25px" }, color: "#273c75" }}
            >
              Our Team
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              Get My Studies ambitiously aims to remove hurdles and aspires to
              be{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              a catalyst in providing an online boutique for students who want{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              to pursue their dream career by helping them make the right{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              academic choices.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: "center", marginTop: "80px" }}>
        <Grid>
          <Box>
            <img src="/media/admissions.png" alt="admission" />
          </Box>
        </Grid>
        <Grid>
          {" "}
          <Box sx={{ margin: { md: "100px", xs: "50px" } }}>
            <Typography
              sx={{ fontSize: { md: "20px", xs: "25px" }, color: "#273c75" }}
            >
              Our Team
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              Get My Studies ambitiously aims to remove hurdles and aspires to
              be{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              a catalyst in providing an online boutique for students who want{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              to pursue their dream career by helping them make the right{" "}
            </Typography>
            <Typography sx={{ fontSize: { md: "15px", xs: "20px" } }}>
              academic choices.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: "center",
          backgroundColor: "#c8d6e5",
          height: { md: "500px", xs: "1300px" },
          width: "100%",
          marginTop: "70px",
        }}
      >
        <Grid item xs={12}>
          <Container>
            <Typography
              sx={{
                fontSize: "20px",
                textAlign: "center",
                color: "linear-gradient(90deg, #4374b9, #ee424f)",
              }}
            >
              Our Journey
            </Typography>
            <Typography
              sx={{ fontSize: { md: "15px", xs: "14px" }, textAlign: "center" }}
            >
              Get My Studies is a TimesInternet backed ed-tech startup
              disrupting the college admissions space in India. We are a social
              college search platform which empowers students with making the
              most important decision of their life - which college to join.
              Since its inception, Get My Studies has come a long way in
              understanding the various dimensions of education. To help
              students decide, choose and shape their career, Get My Studies is
              hustling every day to provide the students with apt information
              regarding.
            </Typography>
          </Container>
        </Grid>

        <Grid container sx={{ justifyContent: "space-around", margin: "30px" }}>
          <Grid item md={3} sm={6} sx={{ textAlign: "center" }}>
            <img src="/media/colleges.png" alt="colleges" />
            <Typography>12000+ Colleges</Typography>
          </Grid>
          <Grid item md={3} sm={6} sx={{ textAlign: "center" }}>
            <img src="/media/exams.png" alt="exams" />
            <Typography>12000+ Colleges</Typography>
          </Grid>
          <Grid item md={3} sm={6} sx={{ textAlign: "center" }}>
            <img src="/media/reviews.png" alt="reviews" />
            <Typography>12000+ Colleges</Typography>
          </Grid>
          <Grid item md={3} sm={6} sx={{ textAlign: "center" }}>
            <img src="/media/users.png" alt="users" />
            <Typography>12000+ Colleges</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          backgroundColor: "#576574",
          width: "100%",
        }}
      >
        <Grid
          item
          sm={8}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: { md: "20px" }, color: "white" }}>
            Interested in Get My Studies Business Partnerships?
          </Typography>
          <Typography sx={{ fontSize: { md: "15px" }, color: "white" }}>
            For colleges, we are a one-stop student recruitment platform.
          </Typography>
          <Typography sx={{ fontSize: { md: "15px" }, color: "white" }}>
            We offer High-Quality Student Leads and Highly Cost Optimal
            Marketing and Communication solutions.
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "10px",
              gap: "10px",
            }}
          >
            <Link to={"mailto: info@getmystudies.com"}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "40px",
                  padding: "10px 20px",
                  backgroundColor: "#fff",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#000",
                  },
                }}
              >
                Mail Us
              </Button>
            </Link>
            <Button
              variant="contained"
              sx={{
                borderRadius: "40px",
                padding: "10px 20px",
                cursor: "pointer",
                backgroundColor: "#fff",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#000",
                },
              }}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Grid>

        <Grid item sm={4}>
          <img src="/media/loudspeaker.png" height="100%" alt="loudspeaker" />
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default AboutUs;
