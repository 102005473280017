import React from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import SideBar from "./SideBar";

const Cutoff = () => {
  const [college, setCollege] = useState([]);

  return (
    <>
      <CollegeDetailHeader activeTab={3} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3, minHeight: "1208px" }}>
              {college.cutoffSummary ? (
                <>
                  <Typography
                    sx={{
                      backgroundColor: "#F2F2F2",
                      p: 1,
                      fontWeight: "bold",
                      fontSize: { xs: "14px", md: "18px" },
                    }}
                    variant="subtitle1"
                  >
                    {college.collegeName} Cut-offs:
                  </Typography>
                  <br />
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: college.cutoffSummary,
                    }}
                    sx={{ my: 2 }}
                  />
                </>
              ) : null}
              {college.collegeCourses && college.collegeCourses.length > 0
                ? college.collegeCourses.map((item) => (
                    <Box padding={{ xs: "10px", md: "15px" }}>
                      <Typography variant="h6">{item.courseName}</Typography>
                      <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                      >
                        <colgroup>
                          <col style={{ width: "50%" }} />
                          <col style={{ width: "50%" }} />
                        </colgroup>
                        <thead>
                          <tr fontSize={{ xs: "14px", md: "18px" }}>
                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Exam
                            </th>
                            <th
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Cut off
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.collegeCourseEntryCutoffList.map((row) => (
                            <tr key={row}>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                  fontWeight: "bold",
                                }}
                              >
                                {row.examName} {row.cutoffType}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                up to {row.cutoff}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  ))
                : null}
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default Cutoff;
