export const config = {
  getMyCollege: "https://api.getmystudies.com",
  // getMyCollege: "http://192.168.117.9:8080",
  cookieName: "clg_user",
  sessionCookie: "clg_sess",
  preferencesCookie: "clg_pref",
  filterCookie: "clg_res",
  defaultLocation: {
    lng: 77.58045,
    lat: 12.9061,
  },
  googleMapKey: "AIzaSyCp3v3ox9JyxXT_IDBnjfNFMgmPfxyQAZo",
};
