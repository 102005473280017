import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Grid,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import { apiList, invokeApi } from "../../../services/apiServices";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { backdropStyle } from "../../../common/common";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../../global/redux/action";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import TextEditor from "./TextEditor";

const CoursesList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;

  const [courses, setCourses] = useState([]);
  const [courseFilter, setCourseFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [isCousesFetching, setIsCoursesFetching] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [courseName, setCourseName] = useState("");

  const [courseLevel, setCourseLevel] = useState("UG");
  const [description, setDescription] = useState("");

  const [courseType, setCourseType] = useState("");
  const [courseTypeError, setCourseTypeError] = useState(false);
  const [courseTypeHelperText, setCourseTypeHelperText] = useState("");
  const [programId, setProgramId] = useState("");
  const [programError, setProgramError] = useState(false);
  const [programHelperText, setStreamHelperText] = useState("");
  const [programList, setProgramList] = useState([]);
  const [isAddCourseDialogOpen, setIsAddCourseDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [id, setId] = useState("");
  const [courseHelperText, setCourseHelperText] = useState("");
  const [courseError, setCourseError] = useState(false);
  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const handleCloseDeleteConfirmation = () => {
    setdeleteConfirmation(false);
    setId("");
  };
  const handleOpenDeleteConfirmation = () => {
    setdeleteConfirmation(true);
  };

  const clearAllError = () => {
    setCourseTypeHelperText("");
    setCourseTypeError(false);
    setUpdateLoading(false);
    setCourseType("");
    setProgramId("");
    setCourseName("");
    setProgramError("");
    setDescription("");
    setStreamHelperText("");
    setCourseError(false);
    setCourseHelperText("");
    setCourseLevel("UG");
    setDescription("");
  };

  const validateCourses = () => {
    let validationErrors = false;
    if (!courseType) {
      setCourseTypeHelperText("Please Select The Course Type");
      setCourseTypeError(true);
      setSaveLoading(false);
      setUpdateLoading(false);
      validationErrors = true;
    }
    if (!programId) {
      setProgramError(true);
      setStreamHelperText("Please Select The Program");
      setSaveLoading(false);
      setUpdateLoading(false);
      validationErrors = true;
    }
    if (!courseName) {
      setCourseHelperText("Please enter the course");
      setCourseError(true);
      setUpdateLoading(false);
      setSaveLoading(false);
      validationErrors = true;
    }

    if (!validationErrors) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getCourses = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourses(response.data.courses);
          setCourseFilter(response.data.courses);
          setIsCoursesFetching(false);
        } else {
          alert(
            "Something went wrong while getting Course Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting Course Details. Please try again later!!"
        );
      }
    };
    if (isCousesFetching) {
      getCourses();
    }
  }, [cookies, isCousesFetching]);

  useEffect(() => {
    const getProgram = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setProgramList(response.data.programs);
        } else {
          alert(
            "Something went wrong while getting Program Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting Program Details. Please try again later!!"
        );
      }
    };
    getProgram();
  }, [cookies]);

  const saveCourse = async () => {
    let validate = validateCourses();
    if (validate) {
      let params = {
        courseName: courseName,
        courseType: courseType,
        programId: programId,
        courseLevel: courseLevel,
        description: description,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveCourse,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Course Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsAddCourseDialogOpen(false);
          setSaveLoading(false);
          setIsCoursesFetching(true);
          setCourseName("");
          setCourseType("");
          setCourseHelperText("");
          setCourseError(false);
          setCourseLevel("");
        } else if (response.data.responseCode === "GM003") {
          setIsAddCourseDialogOpen(false);
          toast.info("Course Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setCourseName("");
          setCourseType("");
          setCourseHelperText("");
          setCourseError(false);
          setCourseLevel("");
          setSaveLoading(false);
        } else {
          alert(
            "Something went wrong while saving the course Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while saving the course Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  const updateCourse = async () => {
    let validate = validateCourses();
    if (validate) {
      let params = {
        id: id,
        courseName: courseName,
        courseType: courseType,
        programId: programId,
        courseLevel: courseLevel,
        description: description,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateCourse,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Course Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsCoursesFetching(true);
          setIsEditDialogOpen(false);
          setUpdateLoading(false);
          setCourseType("");
          setProgramId("");
          setId("");
          setCourseName("");
        } else if (response.data.responseCode === "GM003") {
          setIsEditDialogOpen(false);
          toast.info("Course Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateLoading(false);
          setCourseType("");
          setProgramId("");
          setId("");
          setCourseName("");
        } else {
          alert(
            "Something went wrong while Edit course Details. Please try again later!"
          );
          setUpdateLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Edit course Details. Please try again later!!"
        );
        setUpdateLoading(false);
      }
    }
  };

  const deleteCourse = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateCourse,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Course Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleCloseDeleteConfirmation();
        setIsCoursesFetching(true);
        setProgramId("");
        setCourseName("");
      } else if (response.data.responseCode === "GM002") {
        toast.info(
          "This Course Mapped to Some College You Can't Delete This Course!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setIsCoursesFetching(true);
      } else {
        alert(
          "Something went wrong while delete course Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete course Details. Please try again later!!"
      );
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "10%",
    },
    {
      name: "Course Name",
      selector: (row) => row.courseName + " " + "(" + row.courseType + ")",
      sortable: true,
    },
    {
      name: "Program Name",
      selector: (row) => row.programName,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <Box
          dangerouslySetInnerHTML={{
            __html: row.description,
          }}
          sx={{ my: 2 }}
        />
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setCourseName(row.courseName);
                  setDescription(row.description);
                  setCourseType(row.courseType);
                  setCourseLevel(row.courseLevel);
                  setId(row.id);
                  setProgramId(row.programId);
                  setIsEditDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setCourseName(row.courseName);
                  setDescription(row.description);
                  setCourseType(row.courseType);
                  setId(row.id);
                  setProgramId(row.programId);
                  setIsEditDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setId(row.id);
                  handleOpenDeleteConfirmation();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = courses.filter((item) => {
      return item.courseName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setCourseFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CollegeMetadataTabs tabActive={2}>
        <Button
          variant="contained"
          sx={{ margin: 1, borderRadius: "20px" }}
          onClick={() => setIsAddCourseDialogOpen(true)}
        >
          <AddIcon />
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={courseFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isCousesFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      {/* delete course  */}
      <DeleteModal
        handleIsDeleteModal={handleCloseDeleteConfirmation}
        DeleteFunction={deleteCourse}
        Open={deleteConfirmation}
      />
      {/* add course  */}
      <Dialog
        open={isAddCourseDialogOpen}
        onClose={() => {
          setIsAddCourseDialogOpen(false);
          clearAllError();
        }}
        BackdropProps={{ style: backdropStyle }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            setIsAddCourseDialogOpen(false);
            clearAllError();
          }}
        />
        <DialogTitle>Add New Course</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Program *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={programId}
                  error={programError}
                  helperText={programHelperText}
                  label="Program *"
                  onChange={(e) => {
                    setProgramId(e.target.value);
                    setProgramError(false);
                    setStreamHelperText("");
                  }}
                >
                  {programList?.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.programName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Course Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={courseType}
                  error={courseTypeError}
                  helperText={courseTypeHelperText}
                  label="Course Type *"
                  onChange={(e) => {
                    setCourseType(e.target.value);
                    setCourseTypeError(false);
                    setCourseTypeHelperText("");
                  }}
                >
                  <MenuItem value="FullTime">FullTime</MenuItem>
                  <MenuItem value="PartTime">PartTime</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Name *"
                name="name"
                value={courseName}
                onChange={(ev) => {
                  setCourseName(ev.target.value);
                  setCourseError(false);
                  setCourseHelperText("");
                }}
                helperText={courseHelperText}
                error={courseError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Course Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={courseLevel}
                  label="Course Level"
                  onChange={(e) => setCourseLevel(e.target.value)}
                >
                  <MenuItem value="UG">UG</MenuItem>
                  <MenuItem value="PG">PG</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <TextEditor
                onEditorChange={setDescription}
                initialValue={description}
                placeholder="Enter Course Description"
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={() => {
              setSaveLoading(true);
              saveCourse();
            }}
            disabled={saveLoading}
            sx={{ mt: 1 }}
          >
            Add Course
            {saveLoading && <CircularProgress size={20} />}
          </Button>
        </DialogContent>
      </Dialog>
      {/* edit course  */}
      <Dialog
        open={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
          clearAllError("");
        }}
        BackdropProps={{ style: backdropStyle }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            setIsEditDialogOpen(false);
            clearAllError();
          }}
        />
        <DialogTitle>Edit Course</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Program *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={programError}
                  helperText={programHelperText}
                  value={programId}
                  label={
                    programList?.find(
                      (e) => e.id === programId && programList.programName
                    ) + "*"
                  }
                  onChange={(e) => setProgramId(e.target.value)}
                >
                  {programList?.map((e) => (
                    <MenuItem value={e.id} key={e.id}>
                      {e.programName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Course Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={courseType}
                  error={courseTypeError}
                  helperText={courseTypeHelperText}
                  label="Course Type *"
                  onChange={(e) => setCourseType(e.target.value)}
                >
                  <MenuItem
                    value="FullTime"
                    selected={courseType === "FullTime"}
                  >
                    FullTime
                  </MenuItem>
                  <MenuItem
                    value="PartTime"
                    selected={courseType === "PartTime"}
                  >
                    PartTime
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Name *"
                error={courseError}
                helperText={courseHelperText}
                value={courseName}
                onChange={(ev) => {
                  setCourseName(ev.target.value);
                  setCourseError(false);
                  setCourseHelperText("");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Course Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={courseLevel}
                  label="Course Level"
                  onChange={(e) => setCourseLevel(e.target.value)}
                >
                  <MenuItem value="UG" selected={courseLevel === "UG"}>
                    UG
                  </MenuItem>
                  <MenuItem value="PG" selected={courseLevel === "PG"}>
                    PG
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <TextEditor
                setValue={setDescription}
                value={description}
                placeholder="Enter Course Description"
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={() => {
              setUpdateLoading(true);
              updateCourse();
            }}
            disabled={updateLoading}
            sx={{ mt: 1 }}
          >
            Save Changes
            {updateLoading && <CircularProgress size={20} />}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoursesList;
