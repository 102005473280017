import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useCookies } from "react-cookie";
import DataTable from "react-data-table-component";
import { apiList, invokeApi } from "../../../services/apiServices";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { config } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import CollegeTabs from "./CollegeTabs";

const CollegesList = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;
  const [data, setData] = useState([]);
  const [university, setUniversity] = useState([]);
  const navigate = useNavigate("");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  // const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [id, setId] = useState("");

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const handleDeleteClose = () => {
    setDeleteConfirmation(false);
  };

  const updateCollege = async (e, collegeId) => {
    let params = {
      paidCampaign: e.target.checked ? "On" : "Off",
      id: collegeId,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateCollege,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        setLoading(true);
      } else {
        alert(
          "Something went wrong while Udpate College. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while Udpate College. Please try again later!!"
      );
    }
  };

  // fetch colleges
  useEffect(() => {
    const getAllColleges = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setData(
            response.data.colleges.filter((item) => item.status === "Active")
          );
          setFilter(
            response.data.colleges.filter((item) => item.status === "Active")
          );
          setLoading(false);
        } else {
          alert(
            "Something went wrong while getting the Colleges Details. Please try again later!"
          );
          setLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Colleges Details. Please try again later!!"
        );
        setLoading(false);
      }
    };
    const getUniversity = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getUniversities,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setUniversity(response.data.universities);
        } else {
          alert(
            "Something went wrong while getting the University Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the University Details. Please try again later!!"
        );
      }
    };
    if (loading) {
      getAllColleges();
      getUniversity();
    }
  }, [cookies, loading]);

  const deleteCollege = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateCollege,
      params,
      cookies
    );
    if (response?.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        setLoading(true);
        setId("");
        alert("College Deleted");
      } else {
        alert(
          "Something went wrong while delete the Colleges Details. Please try again later!"
        );
        setLoading(false);
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete the Colleges Details. Please try again later!!"
      );
      setLoading(false);
    }
    setLoading(false);
  };

  const [value, setValue] = useState(0);

  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/college-list") {
      setValue(0);
    } else if (path === "/add-college") {
      setValue(1);
    } else {
      setValue(0);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/college-list"); // Specify the route you want to navigate to
        break;
      case 1:
        navigate("/add-college");
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
    },
    {
      name: "Logo",
      selector: (row) => (
        <img src={row.collegeLogoPath} height={70} alt={row.collegeName} />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.collegeName,
      sortable: true,
    },
    {
      name: "University",
      selector: (row) => {
        // Find the university with a matching id in the university state variable
        const matchedUniversity = university.find(
          (item) => item.id === row.universityId
        );

        // If a matching university is found, display its name; otherwise, display the universityId
        return matchedUniversity
          ? matchedUniversity.universityName
          : row.universityId;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.district + "," + row.state,
      sortable: true,
    },
    {
      name: "PC",
      cell: (row, index) => (
        <Switch
          checked={row.paidCampaign === "On" ? true : false}
          onChange={(e) => updateCollege(e, row.id)}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={(e) => navigate(`/EditCollege/${row.id}`)}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={(e) => navigate(`/EditCollege/${row.id}`)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={(e) => {
                  setId(row.id);
                  setDeleteConfirmation(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = data.filter((item) => {
      return item.collegeName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CollegeTabs tabActive={0}>
        <DataTable
          columns={columns}
          data={filter}
          pagination
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={loading}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
        {/* delete confirmation modal  */}
        <Modal
          open={deleteConfirmation}
          onClose={handleDeleteClose}
          sx={{
            backdropFilter: deleteConfirmation ? "blur(1px)" : "none",
          }}
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "#fff",
              p: 3,
              textAlign: "center",
            }}
          >
            <Typography sx={{ textAlign: "center" }} variant="h5">
              Are you sure?
            </Typography>
            <Typography variant="p">
              Are you sure you want to remove this Record?
            </Typography>
            <Box
              sx={{
                my: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleDeleteClose}
                variant="outlined"
                sx={{ color: "black", mr: 1 }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                sx={{ color: "#fff", ml: 1 }}
                onClick={() => {
                  handleDeleteClose();
                  deleteCollege();
                  // setIsInstituteTypeLoading(true);
                }}
              >
                yes delete it!
              </Button>
            </Box>
          </Box>
        </Modal>
      </CollegeTabs>
    </>
  );
};

export default CollegesList;
