import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import DashboardHeader from "../home/DashboardHeader";
import DashboardNav from "../home/DashboardNav";
import { useNavigate } from "react-router-dom";

const TeamLeaderMappingTabs = (props) => {
  const { children, tabActive } = props;

  const navigate = useNavigate();

  const [value, setValue] = useState(tabActive || 0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/team-leader-mapping-list"); // Specify the route you want to navigate to
        break;
      case 1:
        navigate("/team-leader-mapping-add");
        break;
      case 2:
        navigate("/team-leader-mapping-view");
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{ flexGrow: 1, p: 2, backgroundColor: "#F6F8FB" }}
        >
          <DashboardHeader />
          <Tabs
            value={value}
            onChange={handleChange1}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{ my: 2 }}
          >
            <Tab label="Mapping List" sx={{ backgroundColor: "white" }} />
            <Tab label="New Mapping" sx={{ backgroundColor: "white" }} />
            <Tab label="View Mapping" sx={{ backgroundColor: "white" }} />
          </Tabs>
          <TabPanel value={value} index={tabActive}>
            {children}
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default TeamLeaderMappingTabs;
