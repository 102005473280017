import React, { useEffect, useState } from "react";
import DashboardNav from "../home/DashboardNav";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardHeader from "../home/DashboardHeader";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import { apiList, invokeApi } from "../../../services/apiServices";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const ManageBlogCategories = () => {
  const [cookies] = useCookies();
  const [blogCategoriesData, setBlogCategoriesData] = useState(null);
  const [isBlogCategoriesFetching, setIsBlogCategoriesFetching] =
    useState(true);

  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [categoryError, setCategoryError] = useState(false);
  const [categoryHelperText, setCategoryHelperText] = useState("");
  const [editCategoryName, setEditCategoryName] = useState(false);
  const [categoryFetching, setCategoryFetching] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);

  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [subCategoryModal, setSubCategoryModal] = useState(false);
  const [subCategoryError, setSubCategoryError] = useState(false);
  const [subCategoryHelperText, setsubCategoryHelperText] = useState("");
  const [categoryDisable, setCategoryDisable] = useState(false);

  //getBlogCategoriesHierarchy
  useEffect(() => {
    const getBlogCategoriesHierarchy = async (id) => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getBlogCategoriesHierarchy,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setIsBlogCategoriesFetching(false);
          setBlogCategoriesData(response.data.blogCategories);
        } else {
          alert(
            "Something went wrong while getting the blogs. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the blogs. Please try again later!!"
        );
      }
    };
    if (isBlogCategoriesFetching) {
      getBlogCategoriesHierarchy();
    }
  }, [cookies, isBlogCategoriesFetching]);

  //Add Category
  const addBlogCategory = async () => {
    if (categoryName.trim() !== "") {
      setCategoryFetching(true);
      let params = {
        category: categoryName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.addBlogCategory,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Category added successfully");
          setCategoryFetching(false);
          setIsBlogCategoriesFetching(true);
          setCategoryModal(false);
          setCategoryName("");
          setCategoryError(false);
          setCategoryHelperText("");
        } else {
          alert(
            "Something went wrong while adding the category. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while adding the category. Please try again later!!"
        );
      }
    } else {
      setCategoryError(true);
      setCategoryHelperText("Please enter the category");
    }
  };

  //update Category
  const updateBlogCategory = async () => {
    if (categoryName.trim() !== "") {
      setCategoryFetching(true);
      let params = {
        id: categoryId,
        category: categoryName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateBlogCategory,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Category updated successfully");
          setCategoryFetching(false);
          setIsBlogCategoriesFetching(true);
          setCategoryModal(false);
          setCategoryName("");
        } else {
          alert(
            "Something went wrong while adding the category. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while adding the category. Please try again later!!"
        );
      }
    } else {
      setCategoryError(true);
      setCategoryHelperText("Please enter the category");
    }
  };

  //delete the blog category
  const deleteBlogCategory = async (id) => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateBlogCategory,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Category deleted successfully");
        setCategoryFetching(false);
        setIsBlogCategoriesFetching(true);
        setCategoryModal(false);
        setCategoryName("");
      } else {
        alert(
          "Something went wrong while deleting the category. Please try again later!"
        );
      }
    } else {
      alert(
        "Something went wrong while deleting the category. Please try again later!!"
      );
    }
  };

  //Add Subcategory
  const addBlogSubCategory = async () => {
    if (subCategoryName.trim() !== "" && categoryName !== "") {
      setCategoryFetching(true);
      let params = {
        categoryId: categoryId,
        subCategory: subCategoryName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.addBlogSubCategory,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Subcategory added successfully");
          setCategoryFetching(false);
          setIsBlogCategoriesFetching(true);
          setSubCategoryModal(false);
          setSubCategoryError(false);
          setCategoryError(false);
          setCategoryHelperText("");
          setsubCategoryHelperText("");
          setCategoryName("");
          setSubCategoryName("");
        } else {
          alert(
            "Something went wrong while adding the category. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while adding the category. Please try again later!!"
        );
      }
    } else if (subCategoryName.trim() === "" || categoryName === "") {
      if (subCategoryName.trim() === "") {
        setSubCategoryError(true);
        setsubCategoryHelperText("Please enter the Subcategory");
      } else {
        setCategoryError(true);
        setCategoryHelperText("Please select the category");
      }
    }
    // else if (categoryName !== "") {
    //   setCategoryError(true);
    //   setCategoryHelperText("Please select the category");
    // }
  };

  //Update Blog Subcategory
  const updateBlogSubCategory = async () => {
    if (subCategoryName.trim() !== "") {
      let params = {
        id: subCategoryId,
        categoryId: categoryId,
        subCategory: subCategoryName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateBlogSubCategory,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Subcategory updated successfully");
          setCategoryFetching(false);
          setIsBlogCategoriesFetching(true);
          setSubCategoryModal(false);
          setSubCategoryError(false);
          setCategoryError(false);
          setCategoryHelperText("");
          setsubCategoryHelperText("");
          setCategoryName("");
          setSubCategoryName("");
        } else {
          alert(
            "Something went wrong while updating the category. Please try again later!"
          );
          setCategoryFetching(false);
          setSubCategoryModal(false);
        }
      } else {
        alert(
          "Something went wrong while updating the category. Please try again later!!"
        );
        setCategoryFetching(false);
        setSubCategoryModal(false);
      }
    } else {
      setSubCategoryError(true);
      setsubCategoryHelperText("Please enter the Subcategory");
    }
  };

  //delete Blog Subcategory
  const deleteBlogSubCategory = async (subId, id) => {
    let params = {
      id: subId,
      categoryId: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateBlogSubCategory,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Subcategory deleted successfully");
        setCategoryFetching(false);
        setIsBlogCategoriesFetching(true);
        setSubCategoryModal(false);
        setSubCategoryError(false);
        setCategoryError(false);
        setCategoryHelperText("");
        setsubCategoryHelperText("");
        setCategoryName("");
        setSubCategoryName("");
      } else {
        alert(
          "Something went wrong while deleting the category. Please try again later!"
        );
        setCategoryFetching(false);
        setSubCategoryModal(false);
      }
    } else {
      alert(
        "Something went wrong while deleting the category. Please try again later!!"
      );
      setCategoryFetching(false);
      setSubCategoryModal(false);
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{ flexGrow: 1, p: 2, backgroundColor: "#F6F8FB" }}
        >
          <DashboardHeader />

          <Paper sx={{ p: 3 }}>
            <Breadcrumbs separator="›">
              <Link underline="hover" component={RouterLink} to="/manage-blogs">
                ManageBlogs
              </Link>
              <Typography color="inherit"> ManageBlog Categories</Typography>
            </Breadcrumbs>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              ManageBlog Categories
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                // margin: "10px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setEditCategoryName(false);
                  setCategoryModal(true);
                  setCategoryName("");
                }}
              >
                Add Category
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setCategoryDisable(false);
                  setSubCategoryModal(true);
                  setCategoryName("");
                  setSubCategoryName("");
                }}
              >
                Add Subcategory
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "90%",
              }}
            >
              {!isBlogCategoriesFetching ? (
                <>
                  {blogCategoriesData?.length > 0 ? (
                    <>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                <Typography variant="bodybold">
                                  Category
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="bodybold">
                                  Action
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <TableRow>
                                  <Typography
                                    variant="bodybold"
                                    sx={{ marginLeft: "15px" }}
                                  >
                                    Sub Category
                                  </Typography>

                                  <Typography
                                    variant="bodybold"
                                    sx={{ marginLeft: "100px" }}
                                  >
                                    Action
                                  </Typography>
                                </TableRow>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {blogCategoriesData.map((el, id) => (
                              <>
                                <TableRow key={id}>
                                  <TableCell align="center">
                                    {el.category}
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton
                                      size="small"
                                      color="success"
                                      onClick={() => {
                                        setEditCategoryName(true);
                                        setCategoryName(el.category);
                                        setCategoryId(el.id);
                                        setCategoryModal(true);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>

                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => {
                                        alert(
                                          "Are you sure you want to delete the category?"
                                        );
                                        deleteBlogCategory(el.id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>

                                  <TableCell align="center">
                                    {el.subCategories?.map((e, subId) => (
                                      <TableRow>
                                        <TableCell
                                          key={subId}
                                          align="center"
                                          //sx={{ marginLeft: "110px" }}
                                        >
                                          {e.subCategory}
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ marginLeft: "100px" }}
                                        >
                                          <IconButton
                                            size="small"
                                            color="success"
                                            onClick={() => {
                                              setCategoryDisable(true);
                                              setSubCategoryId(e.id);
                                              setCategoryName(el.category);
                                              setCategoryId(el.id);
                                              setSubCategoryName(e.subCategory);
                                              setSubCategoryModal(true);
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>

                                          <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => {
                                              alert(
                                                "Are you sure you want to delete the subcategory?"
                                              );
                                              deleteBlogSubCategory(
                                                e.id,
                                                el.id
                                              );
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <>
                      <Typography variant="bodyparagraph">
                        No records found
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <>
                  <CircularProgress sx={{ margin: "auto" }} />
                </>
              )}
            </Box>
          </Paper>
          {/* Category Modal */}
          <Modal
            open={categoryModal}
            onClose={() => {
              setCategoryModal(false);
              setCategoryError(false);
              setCategoryHelperText("");
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "11px 10px",
                gap: "10px",
                background: "#FCFCFC",
                border: "1px solid #DFE2E6",
                borderRadius: "20px",
                maxHeight: "80%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "20px",
                  gap: "16px",
                  background: "#FCFCFC",
                  boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.15)",
                  width: `calc(100% - 40px)`,
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="header3"> Category</Typography>
              </Box>
              <Box sx={{ padding: "20px" }}>
                <TextField
                  id="categoryName"
                  label="Category Name *"
                  value={categoryName}
                  variant="standard"
                  sx={{ mb: 2 }}
                  fullWidth
                  onChange={(ev) => {
                    setCategoryName(ev.target.value);
                    setCategoryError(false);
                    setCategoryHelperText("");
                  }}
                  inputProps={{ maxLength: 60 }}
                  error={categoryError}
                  helperText={categoryHelperText}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                    padding: "20px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setCategoryModal(false);
                      setCategoryError(false);
                      setCategoryHelperText("");
                    }}
                    sx={{ margin: "5px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={categoryFetching}
                    onClick={() => {
                      if (editCategoryName) {
                        updateBlogCategory();
                      } else {
                        addBlogCategory();
                      }
                    }}
                    sx={{ margin: "5px" }}
                  >
                    Submit
                    {categoryFetching ? (
                      <CircularProgress size={24} sx={{ ml: 2 }} />
                    ) : (
                      <></>
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>

          {/* Subcategory Modal */}
          <Modal
            open={subCategoryModal}
            onClose={() => {
              setSubCategoryModal(false);
              setSubCategoryError(false);
              setsubCategoryHelperText("");
              setCategoryError(false);
              setCategoryHelperText("");
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "11px 10px",
                gap: "10px",
                background: "#FCFCFC",
                border: "1px solid #DFE2E6",
                borderRadius: "20px",
                maxHeight: "80%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "20px",
                  gap: "16px",
                  background: "#FCFCFC",
                  boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.15)",
                  width: `calc(100% - 40px)`,
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="header3"> Subcategory</Typography>
                {/* <Box
                  component="img"
                  onClick={() => {
                    setSubCategoryModal(false);
                  }}
                  sx={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                  alt="close icon"
                  src="/media/svg/cross-circled.svg"
                /> */}
              </Box>
              <Box sx={{ padding: "20px" }}>
                <FormControl variant="standard" sx={{ mt: 2 }}>
                  <InputLabel>Category Name *</InputLabel>
                  <Select
                    label="Categorie"
                    value={categoryName}
                    onChange={(ev) => {
                      setCategoryName(ev.target.value);
                      setCategoryError(false);
                      setCategoryHelperText("");
                    }}
                    sx={{ width: "200px" }}
                    error={categoryError}
                    disabled={categoryDisable}
                  >
                    {}
                    {blogCategoriesData?.map((el) => (
                      <MenuItem
                        key={el.id}
                        value={el.category}
                        onClick={() => {
                          setCategoryId(el.id);
                        }}
                      >
                        {" "}
                        {el.category}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{categoryHelperText}</FormHelperText>
                </FormControl>
                <TextField
                  id="subCategoryName"
                  label="Subcategory Name *"
                  value={subCategoryName}
                  variant="standard"
                  sx={{ mb: 2 }}
                  fullWidth
                  onChange={(ev) => {
                    setSubCategoryName(ev.target.value);
                    setSubCategoryError(false);
                    setsubCategoryHelperText("");
                  }}
                  inputProps={{ maxLength: 60 }}
                  error={subCategoryError}
                  helperText={subCategoryHelperText}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                    padding: "20px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSubCategoryModal(false);
                      setSubCategoryError(false);
                      setsubCategoryHelperText("");
                      setCategoryError(false);
                      setCategoryHelperText("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={categoryFetching}
                    onClick={() => {
                      if (categoryDisable) {
                        updateBlogSubCategory();
                      } else {
                        addBlogSubCategory();
                      }
                    }}
                  >
                    Submit
                    {categoryFetching ? (
                      <CircularProgress size={24} sx={{ ml: 2 }} />
                    ) : (
                      <></>
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default ManageBlogCategories;
