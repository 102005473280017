import React from "react";
import UsersTabs from "./UsersTabs";
import { useState } from "react";
import { useEffect } from "react";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import SearchIcon from "@mui/icons-material/Search";
import { useCookies } from "react-cookie";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "react-data-table-component";
import DeleteModal from "../colleges/DeleteModal";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { mobileNoValidation } from "../../../common/common";
import { getUser } from "../../../global/redux/action";
import { useNavigate } from "react-router-dom";

const UsersList = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [usersFilter, setUsersFilter] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [collegeId, setCollegeId] = useState("");
  const [collegeIdError, setCollegeIdError] = useState(false);
  const [collegeIdHelperText, setCollegeIdHelperText] = useState("");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const mobileNumberValidate = mobileNoValidation(phoneNumber);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");

  const [roles, setRoles] = useState([]);
  const [rolesError, setRolesError] = useState(false);
  const [rolesHelperText, setRolesHelperText] = useState("");

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [id, setId] = useState("");
  const [cookies] = useCookies();

  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;
  const dispatch = useDispatch();

  const showCollege =
    (roles.includes("College Admin") || roles.includes("College Staff")) &&
    (userData?.users.roles.includes("Marketing Executive") ||
      userData?.users.roles.includes("Admin"));

  const handleRoleChange = (event, newValue) => {
    setRoles(newValue);
  };

  const handleRemoveRole = (role) => {
    setRoles(roles.filter((r) => r !== role));
  };

  const handleIsEditModal = () => {
    setEditModal(!editModal);
  };
  const handleIsDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  useEffect(() => {
    var userRole = userData?.users?.roles;

    if (userRole) {
      if (userRole.includes("Admin")) {
        setRoleList([
          "Admin",
          "College Admin",
          "Student",
          "Team Leader",
          "Telecaller",
          "Marketing Executive",
          "College Staff",
        ]);
      } else if (userRole.includes("Marketing Executive")) {
        setRoleList(["College Admin", "College Staff"]);
      } else if (userRole.includes("College Admin")) {
        setRoleList(["College Staff"]);
      } else {
        setRoleList([]);
      }
    }
  }, [userData]);

  useEffect(() => {
    const getUsers = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getUsers,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setUsers(response.data.users);
          setUsersFilter(response.data.users);
          setLoading(false);
        } else {
          alert(
            "Something went wrong while getting User Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting User Details. Please try again later!!"
        );
      }
    };
    const getColleges = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setColleges(response.data.colleges);
        } else {
          alert(
            "Something went wrong while getting Colleges Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting Colleges Details. Please try again later!!"
        );
      }
    };
    if (loading) {
      getUsers();
      getColleges();
    }
  }, [cookies, loading]);

  const getUser1 = async (id) => {
    let params = {
      id: id,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.getUser,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        setName(response.data.users.name);
        setPhoneNumber(response.data.users.phoneNumber);
        setRoles(response.data.users.roles);
        handleIsEditModal();
      } else {
        alert(
          "Something went wrong while getting User Detail. Please try again later!"
        );
      }
    } else {
      alert(
        "Something went wrong while getting User Detail. Please try again later!!"
      );
    }
  };

  const deleteUser = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let responseData = await invokeApi(
      config.getMyCollege + apiList.updateUser,
      params,
      cookies
    );
    if (responseData.status >= 200 && responseData.status < 300) {
      if (responseData.data.responseCode === "200") {
        toast.success("User Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleIsDeleteModal();
        setLoading(true);
      } else {
        alert(
          "Something went wrong while delete User Details. Please try again later!"
        );
      }
    } else {
      alert(
        "Something went wrong while delete User Details. Please try again later!!"
      );
    }
  };

  const updateRoles = async () => {
    var validation = true;
    if (!roles.length > 0) {
      setRolesError(true);
      setRolesHelperText("Please Select Atleast One Role");
      setUpdateLoading(false);
      validation = false;
    }
    if (validation) {
      let params = {
        id: id,
        roles: roles,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateUserRoles,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("User Detail Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleIsEditModal();
          setLoading(true);
          setName("");
          setPhoneNumber("");
          setRoles([]);
          setNameError(false);
          setNameHelperText("");
          setPhoneNumberError(false);
          setPhoneNumberHelperText("");
          setUpdateLoading(false);
        } else if (response.data.responseCode === "GE001-1") {
          alert("Email already exists!");
        } else {
          alert(
            "Something went wrong while Update the Roles. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while Update the Roles. Please try again later!!"
        );
      }
    }
  };

  const updateUser = async () => {
    let validate = true;
    if (roles.includes("College Admin")) {
      if (!collegeId) {
        setCollegeIdError(true);
        setCollegeIdHelperText("Please Select College");
        validate = false;
        setUpdateLoading(false);
      }
    }
    if (!name) {
      setNameHelperText("Please Enter User Name");
      setNameError(true);
      validate = false;
    }
    if (!mobileNumberValidate) {
      setPhoneNumberHelperText("Please Enter Valid Mobile Number");
      setPhoneNumberError(true);
      validate = false;
    }
    if (validate) {
      let params = {
        id: id,
        name: name,
        collegeId: collegeId,
        phoneNumber: phoneNumber,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateUser,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          updateRoles();
        } else if (response.data.responseCode === "GE001-1") {
          alert("Email already exists!");
        } else {
          alert(
            "Something went wrong while saving the user Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while saving the user Details. Please try again later!!"
        );
      }
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "User Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "User Phone",
      selector: (row) => row.phoneNumber,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "User Roles",
      selector: (row) => (
        <ul>
          {row.roles?.map((role) => (
            <li>{role}</li>
          ))}
        </ul>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {!userData?.users.roles.includes("Admin") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setId(row.id);
                  getUser1(row.id);
                  handleIsEditModal();
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setId(row.id);
                  getUser1(row.id);
                  handleIsEditModal();
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setId(row.id);
                  handleIsDeleteModal();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = users.filter((item) => {
      return item.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setUsersFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);
  return (
    <>
      <UsersTabs tabActive={0}>
        <DataTable
          columns={columns}
          data={usersFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={loading}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </UsersTabs>
      <DeleteModal
        handleIsDeleteModal={handleIsDeleteModal}
        Open={deleteModal}
        DeleteFunction={deleteUser}
      />
      <Dialog
        sx={{ width: "40" }}
        open={editModal}
        onClose={() => {
          handleIsEditModal();
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            handleIsEditModal();
          }}
        />

        <DialogTitle>Edit Users</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={name}
            onChange={(ev) => {
              setName(ev.target.value);
              setNameError(false);
              setNameHelperText("");
            }}
            helperText={nameHelperText}
            error={nameError}
          />
          <TextField
            fullWidth
            sx={{ marginTop: "18px" }}
            label="Phone Number"
            value={phoneNumber}
            inputProps={{
              maxLength: 10,
            }}
            onChange={(ev) => {
              setPhoneNumber(ev.target.value);
              setPhoneNumberHelperText("");
              setPhoneNumberError(false);
            }}
            helperText={phoneNumberHelperText}
            error={phoneNumberError}
          />
          <Autocomplete
            multiple
            id="role-select"
            options={roleList}
            value={roles}
            sx={{ marginTop: "18px" }}
            onChange={handleRoleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Roles"
                error={rolesError}
                helperText={rolesHelperText}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={() => handleRemoveRole(option)}
                  deleteIcon={<CloseIcon />}
                />
              ))
            }
          />
          {showCollege ? (
            <Autocomplete
              fullWidth
              sx={{ marginTop: "18px" }}
              options={colleges
                .filter((item) => item.status === "Active")
                .map((item) => item.collegeName)}
              onChange={(event, newValue) => {
                const selectedCollege = colleges.find(
                  (item) => item.collegeName === newValue
                );

                if (selectedCollege) {
                  const selectedCollegeId = selectedCollege.id;
                  setCollegeId(selectedCollegeId);
                }
              }}
              renderInput={(params) => (
                <TextField
                  error={collegeIdError}
                  helperText={collegeIdHelperText}
                  {...params}
                  label="Select College"
                  variant="outlined"
                />
              )}
            />
          ) : null}
          <Button
            variant="contained"
            sx={{ marginTop: "15px" }}
            onClick={() => {
              updateUser();
            }}
            disabled={updateLoading}
          >
            Update
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UsersList;
