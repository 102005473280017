import React, { useState, useEffect } from "react";
import {
  Button,
  Paper,
  TextField,
  Chip,
  Autocomplete,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useCookies } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { emailValidation, mobileNoValidation } from "../../../common/common";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import UsersTabs from "./UsersTabs";
import { useNavigate } from "react-router-dom";

const UserAdd = () => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;

  const [isUserFetching, setIsUserFetching] = useState(true);
  const [cookies] = useCookies();
  const [roleList, setRoleList] = useState([]);
  const [collegesList, setCollegesList] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");
  const mobileNumberValidate = mobileNoValidation(phoneNumber);

  const [email, setEmail] = useState("");
  var emailValidate = emailValidation(email);
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  const [collegeId, setCollegeId] = useState("");
  const [collegeIdError, setCollegeIdError] = useState(false);
  const [collegeIdHelperText, setCollegeIdHelperText] = useState("");

  const [roles, setRoles] = useState([]);
  const [rolesError, setRolesError] = useState(false);
  const [rolesHelperText, setRolesHelperText] = useState("");

  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(false);

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    var userRole = userData?.users?.roles;

    if (userRole) {
      if (userRole.includes("Admin")) {
        setRoleList([
          "Admin",
          "College Admin",
          "Student",
          "Team Leader",
          "Telecaller",
          "Marketing Executive",
          "College Staff",
          "Author",
        ]);
      } else if (userRole.includes("Marketing Executive")) {
        setRoleList(["College Admin", "College Staff"]);
      } else if (userRole.includes("College Admin")) {
        setRoleList(["College Staff"]);
      } else {
        setRoleList([]);
      }
    }
  }, [userData]);

  const handleRoleChange = (event, newValue) => {
    setRoles(newValue);
  };

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;

    // Validate password
    const hasUppercase = /[A-Z]/.test(newPasswordValue);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(newPasswordValue);
    const hasNumber = /\d/.test(newPasswordValue);
    const isLengthValid = newPasswordValue.length >= 8;

    setPassword(newPasswordValue);

    if (hasUppercase && hasSpecialCharacter && hasNumber && isLengthValid) {
      setPasswordError(false);
      setPasswordHelperText("Password is valid!");
      setPasswordValidation(true);
    } else {
      setPasswordError(true);
      setPasswordHelperText(
        "Password must include an uppercase letter, a special character, at least one number, and be at least 8 characters long."
      );
      setPasswordValidation(false);
    }
  };

  const handleRemoveRole = (role) => {
    setRoles(roles.filter((r) => r !== role));
  };

  const showCollege =
    (roles.includes("College Admin") || roles.includes("College Staff")) &&
    (userData?.users.roles.includes("Marketing Executive") ||
      userData?.users.roles.includes("Admin"));

  const validateUser = () => {
    setSaveLoading(true);
    let validationErrors = false;
    if (roles.includes("College Admin")) {
      if (!collegeId) {
        setCollegeIdError(true);
        setCollegeIdHelperText("Please Select College");
        validationErrors = true;
        setSaveLoading(false);
      }
    }
    if (!name) {
      setNameHelperText("Please enter your Full Name");
      setNameError(true);
      validationErrors = true;
      setSaveLoading(false);
    }
    if (!mobileNumberValidate) {
      setPhoneNumberHelperText("Please enter valid Mobile Number");
      setPhoneNumberError(true);
      setSaveLoading(false);
      validationErrors = true;
    }

    if (!emailValidate) {
      setEmailHelperText("Please Enter Valid Email");
      setEmailError(true);
      setSaveLoading(false);
      validationErrors = true;
    }
    if (!roles.length > 0) {
      setRolesError(true);
      setRolesHelperText("Please Select Atleast One Role");
      setSaveLoading(false);
      validationErrors = true;
    }
    if (!passwordValidation) {
      setPasswordError(true);
      setPasswordHelperText("Please Enter Password");
      setSaveLoading(false);
      validationErrors = true;
    }
    if (!validationErrors) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getColleges = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCollegesList(response.data.colleges);
        } else {
          alert(
            "Something went wrong while getting Colleges Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting Colleges Details. Please try again later!!"
        );
      }
    };
    getColleges();
    if (isUserFetching) {
      setIsUserFetching(false);
    }
  }, [cookies, isUserFetching]);

  // add user
  const saveUser = async (e) => {
    e.preventDefault();
    let validate = validateUser();
    if (validate) {
      let params = {
        name: name,
        phoneNumber: phoneNumber,
        email: email,
        password: password,
        collegeId: collegeId ? collegeId : userData?.users.collegeId,
        roles: roles,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveUser,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("User Added SuccessFully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsUserFetching(true);
          setName("");
          setCollegeId("");
          setSaveLoading(false);
          setPhoneNumber("");
          setEmail("");
          setRoles([]);
          setNameError(false);
          setNameHelperText("");
          setPhoneNumberError(false);
          setPhoneNumberHelperText("");
          setEmailError(false);
          setEmailHelperText("");
          navigate("/user-list");
        } else if (response.data.responseCode === "GM001-1") {
          toast.info("Email Already Exist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSaveLoading(false);
          navigate("/user-list");
        } else {
          alert(
            "Something went wrong while saving the course Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else {
        alert(
          "Something went wrong while saving the course Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  return (
    <>
      <UsersTabs tabActive={1}>
        <Paper sx={{ p: 2, mt: 2 }}>
          <form onSubmit={saveUser}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Name"
                  value={name}
                  onChange={(ev) => {
                    setName(ev.target.value);
                    setNameError(false);
                    setNameHelperText("");
                  }}
                  helperText={nameHelperText}
                  error={nameError}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  sx={{ marginTop: "18px" }}
                  label="Phone Number"
                  value={phoneNumber}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={(ev) => {
                    setPhoneNumber(ev.target.value);
                    setPhoneNumberHelperText("");
                    setPhoneNumberError(false);
                  }}
                  helperText={phoneNumberHelperText}
                  error={phoneNumberError}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  sx={{ marginTop: "18px" }}
                  label="Email"
                  value={email}
                  onChange={(ev) => {
                    setEmail(ev.target.value);
                    setEmailHelperText("");
                    setEmailError(false);
                  }}
                  helperText={emailHelperText}
                  error={emailError}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="password"
                  sx={{ marginTop: "18px" }}
                  label="Password"
                  value={password}
                  onChange={handleNewPasswordChange}
                  helperText={passwordHelperText}
                  error={passwordError}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  multiple
                  id="role-select"
                  options={roleList}
                  value={roles}
                  sx={{ marginTop: "18px" }}
                  onChange={handleRoleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Roles"
                      error={rolesError}
                      helperText={rolesHelperText}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() => handleRemoveRole(option)}
                        deleteIcon={<CloseIcon />}
                      />
                    ))
                  }
                />
              </Grid>
              {showCollege ? (
                <Grid item md={6}>
                  <Autocomplete
                    fullWidth
                    sx={{ marginTop: "18px" }}
                    options={collegesList
                      .filter((item) => item.status === "Active")
                      .map((item) => item.collegeName)}
                    onChange={(event, newValue) => {
                      const selectedCollege = collegesList.find(
                        (item) => item.collegeName === newValue
                      );

                      if (selectedCollege) {
                        const selectedCollegeId = selectedCollege.id;
                        setCollegeId(selectedCollegeId);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={collegeIdError}
                        helperText={collegeIdHelperText}
                        {...params}
                        label="Select College"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : null}
              <Grid md={12} sx={{ textAlign: "center", mt: 2 }}>
                <Button
                  type="submit"
                  disabled={saveLoading}
                  variant="contained"
                  sx={{ backgroundColor: "#405189", padding: "10px" }}
                >
                  Submit{saveLoading && <CircularProgress size={20} />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </UsersTabs>
    </>
  );
};

export default UserAdd;
