import {
  Button,
  IconButton,
  Box,
  Divider,
  Modal,
  Typography,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import AddIcon from "@mui/icons-material/Add";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";

const InstituteTypeList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;
  // add college type modal state
  const [addModal, setAddModal] = useState(false);
  // edit college type modal state
  const [editModal, setEditModal] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [search, setSearch] = useState("");
  // college type name state
  const [instituteTypeName, setInstituteTypeName] = useState("");
  // textfiled error condition state
  const [instituteTypeError, setInstituteTypeError] = useState(false);
  // error message for textfiled state
  const [instituteTypeHelperText, setInstituteTypeHelperText] = useState("");
  // add college type save button loading state
  const [saveLoading, setSaveLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  // data fetching depends on this state if true call the fetch function
  const [isInstituteTypeFetching, setIsInstituteTypeFetching] = useState(true);
  // edit save id state
  const [id, setId] = useState("");
  // fetch data set to this state
  const [instituteType, setInstituteType] = useState([]);
  const [instituteTypeFilter, setInstituteTypeFilter] = useState([]);

  const handleIsAddModal = () => {
    setAddModal(!addModal);
  };
  const handleIsEditModal = () => {
    setEditModal(!editModal);
  };
  const handleIsDeleteModal = () => {
    setDeleteConfirmation(!deleteConfirmation);
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  useEffect(() => {
    const getAllCollegeType = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getInsituteType,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setInstituteType(response.data.instituteTypes);
          setInstituteTypeFilter(response.data.instituteTypes);
          setInstituteTypeError(false);
          setIsInstituteTypeFetching(false);
        } else {
          alert(
            "Something went wrong while getting the institute Types Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the institute Types Details. Please try again later!!"
        );
      }
    };
    if (isInstituteTypeFetching) {
      getAllCollegeType();
    }
  }, [cookies, isInstituteTypeFetching]);

  const saveCollegeType = async () => {
    var validation = true;
    if (!instituteTypeName) {
      setSaveLoading(false);
      setInstituteTypeError(true);
      setInstituteTypeHelperText("Enter College Type");
      validation = false;
    }
    if (validation) {
      let params = {
        instituteType: instituteTypeName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveInstituteType,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Institute Type Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSaveLoading(false);
          handleIsAddModal();
          setIsInstituteTypeFetching(true);
          setInstituteTypeError(false);
          setInstituteTypeHelperText("");
        } else if (response.data.responseCode === "GM003") {
          handleIsAddModal(false);
          toast.info("Institute Type Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSaveLoading(false);
          setInstituteTypeError(false);
          setInstituteTypeHelperText("");
          setInstituteTypeName("");
        } else {
          alert(
            "Something went wrong while saving the College Type Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while saving the College Type Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  const deleteCollegeType = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateInstituteType,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Institute Type Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsInstituteTypeFetching(true);
        handleIsDeleteModal();
      } else if (response.data.responseCode === "GM002") {
        handleIsDeleteModal();
        toast.info(
          "This Institute Type Mapped to Some College You Can't Delete This Institute Type!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        alert(
          "Something went wrong while delete course Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete course Details. Please try again later!!"
      );
    }
  };

  //edit institute type
  const updateCollegeType = async () => {
    var validation = true;
    if (!instituteTypeName) {
      setUpdateLoading(false);
      setInstituteTypeError(true);
      setInstituteTypeHelperText("Enter College Type");
      validation = false;
    }
    if (validation) {
      let params = {
        id: id,
        instituteType: instituteTypeName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateInstituteType,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Institute Type Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          handleIsEditModal();
          setUpdateLoading(false);
          setInstituteTypeError(false);
          setInstituteTypeHelperText("");
          setInstituteTypeName("");
          setIsInstituteTypeFetching(true);
        } else if (response.data.responseCode === "GM003") {
          handleIsEditModal(false);
          toast.info("Institute Type Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateLoading(false);
          setInstituteTypeError(false);
          setInstituteTypeHelperText("");
          setInstituteTypeName("");
        } else {
          alert(
            "Something went wrong while Edit course Details. Please try again later!"
          );
          setUpdateLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Edit course Details. Please try again later!!"
        );
        setUpdateLoading(false);
      }
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Institute Name",
      selector: (row) => row.instituteType,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  handleIsEditModal();
                  setInstituteTypeName(row.instituteType);
                  setId(row.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  handleIsEditModal();
                  setInstituteTypeName(row.instituteType);
                  setId(row.id);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  handleIsDeleteModal();
                  setId(row.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = instituteType.filter((item) => {
      return item.instituteType.toLowerCase().match(search.toLocaleLowerCase());
    });
    setInstituteTypeFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CollegeMetadataTabs tabActive={0}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={() => {
            handleIsAddModal();
            setSaveLoading(false);
          }}
        >
          <AddIcon></AddIcon>
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={instituteTypeFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isInstituteTypeFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      {/* delete confirmation modal  */}
      <DeleteModal
        handleIsDeleteModal={handleIsDeleteModal}
        Open={deleteConfirmation}
        DeleteFunction={deleteCollegeType}
      />
      {/* edit modal  */}
      <Modal
        open={editModal}
        onClose={() => {
          handleIsEditModal();
          setInstituteTypeName("");
          setInstituteTypeError(false);
          setUpdateLoading(false);
          setInstituteTypeHelperText("");
        }}
        sx={{
          backdropFilter: editModal ? "blur(1px)" : "none",
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsEditModal();
                setInstituteTypeName("");
                setUpdateLoading(false);
                setInstituteTypeError(false);
                setInstituteTypeHelperText("");
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="medium" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              gap: "10px",
            }}
          >
            <TextField
              id="Institute-Type-basic"
              label="Institute Type *"
              variant="outlined"
              type="text"
              autoComplete="off"
              sx={{ my: 2 }}
              value={instituteTypeName}
              onChange={(e) => {
                setInstituteTypeName(e.target.value);
                setInstituteTypeError(false);
                setInstituteTypeHelperText("");
              }}
              error={instituteTypeError}
              helperText={instituteTypeHelperText}
              fullWidth
            />
            <Button
              variant="contained"
              type="submit"
              onClick={() => {
                setUpdateLoading(true);
                updateCollegeType();
              }}
              disabled={updateLoading}
            >
              Update
              {updateLoading && <CircularProgress size={20} />}
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* college Type list modal  */}
      <Modal
        open={addModal}
        onClose={() => {
          handleIsAddModal();
          setInstituteTypeName("");
          setInstituteTypeError(false);
          setInstituteTypeHelperText("");
        }}
        sx={{
          backdropFilter: addModal ? "blur(1px)" : "none",
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsAddModal();
                setInstituteTypeError(false);
                setInstituteTypeHelperText("");
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="medium" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              gap: "10px",
            }}
          >
            <TextField
              id="Institute-Type-basic"
              label="Institute Type *"
              variant="outlined"
              autoComplete="off"
              sx={{ my: 2 }}
              onChange={(e) => {
                setInstituteTypeName(e.target.value);
                setInstituteTypeError(false);
                setInstituteTypeHelperText("");
              }}
              error={instituteTypeError}
              helperText={instituteTypeHelperText}
              fullWidth
            />
            <Button
              variant="contained"
              type="submit"
              onClick={() => {
                setSaveLoading(true);
                saveCollegeType();
              }}
              disabled={saveLoading}
            >
              Save
              {saveLoading && <CircularProgress size={20} />}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default InstituteTypeList;
