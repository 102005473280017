import {
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  const [cookies] = useCookies();
  const theme = useTheme();
  const navigate = useNavigate();
  const [collegeList, setCollegeList] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const getAllColleges = async () => {
      let params = {
        district: "Bangalore",
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCollegeList(response.data.colleges);
          setFetching(false);
        } else {
          alert(
            "Something went wrong while getting the College Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the College Details. Please try again later!!"
        );
      }
    };
    if (fetching) {
      getAllColleges();
    }
  }, [fetching]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Grid item md={4} xs={12}>
        <Paper sx={{ p: 3, color: "#405189", maxheight: "552px" }}>
          <Typography
            style={{
              textDecoration: "underline",
              textAlign: "center",
              fontSize: "18px",
              paddingBottom: "25px",
            }}
          >
            Explore Near by Colleges
          </Typography>
          {collegeList?.slice(0, 6).map((item) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt={item.collegeName}
                src={item.collegeLogoPath}
                height="40px"
              />
              <Typography
                padding={"10px"}
                fontSize="18px"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/institute/${item.seoOgUrl}/blog/${item.id}`);
                }}
              >
                {item.collegeName} in {item.district}
              </Typography>
            </Box>
          ))}
          <Typography
            textAlign={"center"}
            color={"black"}
            fontSize="18px"
            padding={"20px"}
          >
            View more
          </Typography>
        </Paper>
        <br />
        {!isSm ? (
          <>
            <Paper sx={{ p: 3, maxheight: "552px" }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Recent Articles" />
                <Tab label="Featured Articles" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                {[1, 2, 3].map((item) => (
                  <Typography padding={"10px"} fontSize="18px" key={item}>
                    IIT university Colleges are going to placements are gets n
                    most iit students
                  </Typography>
                ))}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {[1, 2, 3, 4, 5].map((item) => (
                  <Typography padding={"10px"} fontSize="18px">
                    IIT university Colleges are going to placements are gets n
                    most iit students
                  </Typography>
                ))}
              </TabPanel>

              <Typography
                textAlign={"center"}
                color={"#282828"}
                fontSize="18px"
                padding={"20px"}
              >
                View more
              </Typography>
            </Paper>
            <br />
            <Paper sx={{ p: 3, fontSize: "20px" }}>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#405189",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Latest Blog News
              </Typography>
              <hr />
              <Box sx={{ display: "flex", pt: "10px" }}>
                <Typography>
                  <img src="/blog.png" alt="logo" />
                </Typography>
                <Typography sx={{ pl: "10px" }}>
                  IIT university Colleges are going to placements are gets n
                  most iit students
                </Typography>
              </Box>
              <Box sx={{ display: "flex", pt: "10px" }}>
                <Typography>
                  <img src="/blog.png" alt="logo" />
                </Typography>
                <Typography sx={{ pl: "10px" }}>
                  IIT university Colleges are going to placements are gets n
                  most iit students
                </Typography>
              </Box>
              <Box sx={{ display: "flex", pt: "10px" }}>
                <Typography>
                  <img src="/blog.png" alt="logo" />
                </Typography>
                <Typography sx={{ pl: "10px" }}>
                  IIT university Colleges are going to placements are gets n
                  most iit students
                </Typography>
              </Box>
              <Box sx={{ display: "flex", pt: "10px" }}>
                <Typography>
                  <img src="/blog.png" alt="logo" />
                </Typography>
                <Typography sx={{ pl: "10px" }}>
                  IIT university Colleges are going to placements are gets n
                  most iit students
                </Typography>
              </Box>
              <Typography textAlign={"center"} color={"#282828"}>
                View more
              </Typography>
            </Paper>
          </>
        ) : null}
      </Grid>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default SideBar;
