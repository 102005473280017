import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import MarketingExecutiveMappingTabs from "./MarketingExecutiveMappingTabs";

const MarketingExecutiveMappingList = () => {
  const [mappingData, setMappingData] = useState([]);
  const [cookies] = useCookies();
  useEffect(() => {
    const getMktngExecMappingList = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getMarketingExecutiveMapping,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setMappingData(response.data.collegeMktngExecs);
        } else {
          alert(
            "Something went wrong while getting the Marketing Executive Mapping Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Marketing Executive Mapping Details. Please try again later!!"
        );
      }
    };
    getMktngExecMappingList();
  }, [cookies]);
  return (
    <>
      <MarketingExecutiveMappingTabs tabActive={0}>
        <TableContainer component={Paper}>
          <Table size="mediam">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ width: "20%" }}>
                  ID
                </TableCell>
                <TableCell align="left" style={{ width: "40%" }}>
                  Marketing Executive
                </TableCell>
                <TableCell align="left" style={{ width: "40%" }}>
                  Colleges
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappingData &&
                Array.isArray(mappingData) &&
                mappingData.length > 0 &&
                mappingData.map((item, index) => (
                  <TableRow>
                    <TableCell align="center" style={{ width: "20%" }}>
                      {index + 1}{" "}
                    </TableCell>
                    <TableCell align="left" style={{ width: "40%" }}>
                      {item.mktngExecName}
                    </TableCell>
                    <TableCell align="left" style={{ width: "40%" }}>
                      <ul>
                        {item.colleges.map((colleges) => (
                          <li>{colleges.collegeName}</li>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MarketingExecutiveMappingTabs>
    </>
  );
};

export default MarketingExecutiveMappingList;
