import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Box,
  Autocomplete,
  Button,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  invokeApi,
  apiList,
  invokeFormDataApi,
} from "../../../services/apiServices";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete as MapAutoComplete,
} from "@react-google-maps/api";
import { useRef } from "react";
import DashboardHeader from "../home/DashboardHeader";
import DashboardNav from "../home/DashboardNav";
import TextEditor from "./TextEditor";
import { toast } from "react-toastify";

const libraries = ["places"];

const CollegeEdit = () => {
  // cookies for fetching
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const { id } = useParams();
  const [fetching, setFetching] = useState(true);
  // navigate to another page
  const [examList, setExamList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [scholorshipList, setScholorshipList] = useState([]);
  const [ogurlError, setOgurlError] = useState(false);
  const [ogurlHelperText, setOgurlHelperText] = useState("");

  // this is for loading and fetching status state
  // const [isFileUploadFetching, setIsFileUploadFetching] = useState(false);
  // college name and validate
  const [collegeName, setCollegeName] = useState("");
  const [collegeNameError, setCollegeNameError] = useState(false);
  const [collegeNameHelperText, setCollegeNameHelperText] = useState("");

  // university list fetching and universityid and validate

  const [universityId, setUniversityId] = useState("");
  const defaultUniversity = universityList?.find(
    (item) => item.id === universityId
  );

  const [universityIdError, setUniversityIdError] = useState(false);
  const [universityIdHelperText, setUniversityIdHelperText] = useState("");
  const [examCutoffError, setExamCutoffError] = useState(false);
  const [scholarshipAmtError, setScholarshipAmtError] = useState(false);

  // ogurl
  const [orUrl, setOgUrl] = useState("");

  // established year and validate
  const [collegeEstablishedYear, setCollegeEstablishedYear] = useState("");
  const [establishedYearError, setEstablishedYearError] = useState(false);
  const [establishedYearHelperText, setEstablishedYearHelperText] =
    useState("");

  const [yearError, setYearError] = useState(false);
  const [yearHelperText, setYearHelperText] = useState("");

  // meta description and validate
  const [metaDescription, setMetaDescription] = useState(null);
  console.log(metaDescription);
  const [metaDescriptionError, setMetaDescriptionError] = useState(false);
  const [metaDescriptionHelperText, setMetaDescriptionHelperText] =
    useState("");

  // meta keyword and validation
  const [keyWords, setKeyWords] = useState(null);

  // college desction and validation
  const [collegeDescription, setCollegeDescription] = useState(null);
  // schema script and validtion
  const [schemaScript, setSchemaScript] = useState(null);

  // scholorship id and validation

  const [scholarShipId, setScholorShipId] = useState([]);

  const handleRemoveScholarship = (prev) => {
    setScholorShipId((prevIds) => prevIds.filter((id) => id !== prev.id));
  };
  const handleScholarshipChange = (event, newValue) => {
    const stringIds = newValue.map((company) => company.id.toString());

    setScholorShipId(stringIds);
  };

  // program id and validation
  const [programList, setProgramsList] = useState([]);
  const [programId, setProgramId] = useState("");
  const defaulProgram = programList?.find((item) => item.id === programId);
  const [programError, setProgramError] = useState(false);
  const [programHelperText, setProgramHelperText] = useState("");

  // scholorship cutoff and validation
  const [scholorShipCutOff, setScholorShipCutOff] = useState("");

  const [placementCompaniesList, setPlacementCompaniesList] = useState([]);
  const [placementCompanyId, setPlacementCompanyId] = useState([]);
  const [placementCompanyError, setPlacementCompanyError] = useState(false);
  const [placementCompanyHelperText, setPlacementCompanyHelperText] =
    useState("");

  const [instituteBrand, setInstituteBrand] = useState([]);
  const [instituteBrandId, setInstituteBrandId] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const defaultInstituteBrand = instituteBrand?.find(
    (item) => item.id === instituteBrandId
  );
  const [instituteBrandError, setInstituteBrandError] = useState(false);
  const [instituteBrandHelperText, setInstituteBrandHelperText] = useState("");

  // insitute type list fetch and insitutte type selected and validation
  const [insituteTypeId, setInsituteTypeId] = useState("1");
  // course list fetch
  const [courseNameList, setCourseNameList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [specializationIdError, setSpecializationIdError] = useState(false);
  const [specializationIdHelperText, setSpecializationIdHelperText] =
    useState("");

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // address list fetching and address selected and validation
  const [addressList, setAddressList] = useState([]);

  // country selected and validation
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [countryHelperText, setCountryHelperText] = useState("");

  // state selected and validation
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);
  const [stateHelperText, setStateHelperText] = useState("");

  // district selected and validation
  const [district, setDistrict] = useState("");
  const [districtError, setDistrictError] = useState(false);
  const [districtHelperText, setDistrictHelperText] = useState("");

  // area and validation
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState(false);
  const [areaHelperText, setAreaHelperText] = useState("");

  // pincode selected and validation
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState(false);
  const [pincodeHelperText, setPincodeHelperText] = useState("");

  const [emails, setEmails] = useState("");
  const [trustName, setTrustName] = useState("");
  const [collegeCategory, setCollegeCategory] = useState("co-ed");
  const [contactNumbers, setContactNumbers] = useState("");
  const [website, setWebsite] = useState("");
  const [admissionSummary, setAdmissionSummary] = useState("");
  const [placementCompanySummary, setPlacementCompanySummary] = useState("");
  const [cutOffSummary, setCutOffSummary] = useState("");
  const [scholarshipSummar, setScholarshipSummary] = useState("");
  const [courseFeeSummary, setCourseFeeSummary] = useState("");
  const [resultSummary, setResultSummary] = useState("");
  const [questionAnswerSummary, setQuestionAnswerSummary] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [videoLinks, setVideoLinks] = useState([]);
  const [deletedVideoLinks, setDeletedVideoLinks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);

  const [monthError, setMonthError] = useState(false);
  const [monthHelperText, setMonthHelperText] = useState("");

  const [youtubeVideoError, setYoutubeVideoError] = useState(false);
  const [youtubeVideoHelperText, setYoutubeVideoHelperText] = useState("");

  const handleAddVideo = () => {
    const youtubeUrlRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(.*\/)?|youtu\.be\/)([^\?&"'>]+)/;

    if (!inputValue) {
      setYoutubeVideoError(true);
      setYoutubeVideoHelperText("Please Paste Youtube Url");
    } else if (!youtubeUrlRegex.test(inputValue)) {
      setYoutubeVideoError(true);
      setYoutubeVideoHelperText("Please enter a valid YouTube URL");
    } else {
      const newVideoObject = {
        mediaType: "youtube",
        mediaFilePath: inputValue,
      };

      setVideoLinks((prevLinks) => [...prevLinks, newVideoObject]);
      setInputValue("");
    }
  };

  const handleRemoveVideo = (index) => {
    const newVideoLinks = [...videoLinks];
    newVideoLinks.splice(index, 1);
    setVideoLinks(newVideoLinks);
  };

  const handleCloseDialog = () => {
    let updatedData = [...videoLinks];
    setOpenDialog(false);
    if (selectedVideoIndex !== null) {
      const removedItem = {
        id: updatedData[selectedVideoIndex].id,
        status: "Deleted",
      };
      if (removedItem.id === undefined) {
        updatedData.splice(selectedVideoIndex, 1); // Remove the item from the original array
        setVideoLinks(updatedData); // Update the course state without the removed item
        setSelectedVideoIndex(null);
      } else {
        updatedData.splice(selectedVideoIndex, 1);
        setDeletedVideoLinks((prevDeletedYoutubeLink) => [
          removedItem,
          ...prevDeletedYoutubeLink,
        ]); // Add the removed item to the deletedCourse state
        setVideoLinks(updatedData); // Update the course state without the removed item
        setSelectedVideoIndex(null);
      }
    }
  };

  // add course multiple row and validation

  // below the code is add rows in course add
  const courseTemplate = {
    courseId: "",
    courseFee: "",
    batchCapacity: "",
    years: "",
    months: "",
    courseSpecializationId: "",
    collegeCourseEntryCutoffList: [
      {
        examId: "",
        cutoff: "",
        cutoffType: "",
      },
    ],
    collegeCourseScholarshipCutoffList: [
      {
        examId: "",
        cutoff: "",
        cutoffType: "",
        scholarship: "",
        scholarshipId: "",
        scholarshipType: "",
      },
    ],
  };
  const [course, setCourse] = useState([courseTemplate]);
  const [deletedCourse, setDeletedCourse] = useState([]);
  const [deletedScholarship, setDeletedScholarship] = useState([]);
  const [deletedExam, setDeletedExam] = useState([]);
  const [courseNameError, setCourseNameError] = useState(false);
  const [courseFeeError, setCourseFeeError] = useState(false);
  const [courseBatchCapacityError, setCourseBatchCapacityError] =
    useState(false);
  const [courseNameHelperText, setCourseNameHelperText] = useState("");
  const [courseFreeHelperText, setCourseFreeHelperText] = useState("");
  const [courseBatchCapacityHelperText, setCourseBatchCapacityHelperText] =
    useState("");

  // google map
  // search address and auto fill location
  const [autocomplete, setAutocomplete] = useState(null);
  // default location and update the college location
  const [markerPosition, setMarkerPosition] = useState({
    lat: config.defaultLocation.lat,
    lng: config.defaultLocation.lng,
  });

  // college logo, preview, college logo path and validation
  const [collegeLogo, setCollegeLogo] = useState(null);
  const [collegeLogoError, setCollegeLogoError] = useState(false);
  const [collegeLogoHelperText, setCollegeLogoHelperText] = useState("");
  const [previewCollegeLogo, setPreviewCollegeLogo] = useState(null);
  const [collegeLogomaxFileSizeErr, setCollegeLogoMaxFileSizeErr] =
    useState("");
  const [collegeLogoPath, setCollegeLogoPath] = useState(null);

  // Banner logo, preview, Banner logo path and validation
  const [bannerLogo, setBannerLogo] = useState(null);
  const [bannerLogoError, setBannerLogoError] = useState(false);
  const [bannerLogoHelperText, setBannerLogoHelperText] = useState("");
  const [previewBannerLogo, setPreviewBannerLogo] = useState(null);
  const [bannerPath, setBannerPath] = useState(null);

  // Broucher logo, preview, Broucher logo path and validation
  const [broucherLogo, setBroucherLogo] = useState(null);
  const [broucherError, setBroucherError] = useState(false);
  const [broucherHelperText, setBroucherHelperText] = useState("");
  const [previewBroucherLogo, setPreviewBroucherLogo] = useState(null);
  const [brouchermaxFileSizeErr, setBroucherMaxFileSizeErr] = useState("");
  const [broucherPath, setBroucherPath] = useState(null);

  // gallery image, preview, gallery images path and validation
  const [previewImages, setPreviewImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImageURLs, setGalleryImageURLs] = useState([]);
  const [galleryImageUploadStatus, setGalleryImageUploadStatus] =
    useState(false);

  const [collegeType, setCollegeType] = useState("");

  const [previewPreviousImages, setPreviewPreviousImages] = useState([]);
  const [galleryPreviousImageURLs, setGalleryPreviousImageURLs] = useState([]);
  const [galleryImagesError, setGalleryImagesError] = useState(false);
  const [galleryImagesHelperText, setGalleryImagesHelperText] = useState("");

  const [summaryImage, setSummaryImage] = useState(null);
  const [summaryImageUrl, setSummaryImageUrl] = useState(null);

  const [previousDeletedImages, setPreviousDeletedImages] = useState([]);

  var mergedGalleryImages = galleryImageURLs.concat(galleryPreviousImageURLs);
  var mergedGalleryImagesDeleted =
    previousDeletedImages.concat(mergedGalleryImages);
  var mergedCourses = course.concat(deletedCourse);

  const [videoFiles, setVideoFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [videoUrl, setVideoUrl] = useState([]);
  const [deletedVideoUrl, setDeletedVideoUrl] = useState([]);

  // amenitites selected and validation and loading
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [amenities, setAmenities] = useState([
    { amenityId: "", amenityDescription: null },
  ]);

  const [deletedAmenity, setDeletedAmenity] = useState([]);
  const filteredAmenities = amenities.filter(
    (item) => !(item.amenityId === "" && item.amenityDescription === null)
  );
  var mergedAmenities = [...filteredAmenities, ...deletedAmenity];

  const handleAddRow = () => {
    setAmenities([...amenities, { amenityId: "", amenityDescription: null }]);
  };

  const [amenityError, setAmenityError] = useState(false);
  const [amenityHelperText, setAmenityHelperText] = useState("");

  const handleRemoveRow = (index) => {
    const updatedData = [...amenities];
    const removedItem = {
      id: updatedData[index].id,
      status: "Deleted",
    };
    if (removedItem.id === undefined) {
      updatedData.splice(index, 1); // Remove the item from the original array
      setAmenities(updatedData); // Update the course state without the removed item
    } else {
      updatedData.splice(index, 1); // Remove the item from the original array

      setDeletedAmenity((prevDeletedCourse) => [
        removedItem,
        ...prevDeletedCourse,
      ]); // Add the removed item to the deletedCourse state
      setAmenities(updatedData); // Update the course state without the removed item
    }
  };

  const handleChange = (index, field, value) => {
    const updatedAmenities = [...amenities];
    updatedAmenities[index][field] =
      field === "amenityId" ? String(value) : value;
    setAmenities(updatedAmenities);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...videoFiles];
    const removedFile = updatedFiles.splice(index, 1)[0];
    setTotalSize(totalSize - removedFile.size);
    setVideoFiles(updatedFiles);
  };

  const handleRemovePreviousFile = (index) => {
    // Create a copy of the videoUrl array
    const updatedData = [...videoUrl];

    // Create an object representing the removed item with id and status properties
    const removedItem = {
      id: updatedData[index].id,
      status: "Deleted",
    };

    // Remove the item at the specified index from the updatedData array
    updatedData.splice(index, 1);
    setVideoUrl(updatedData);

    // Update the state variable deletedVideoUrl by adding the removed item to its beginning
    setDeletedVideoUrl((prevDeletedCourse) => [
      removedItem,
      ...prevDeletedCourse,
    ]);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Calculate total size of selected files
    const size = files.reduce((acc, file) => acc + file.size, 0);

    // Check if total size exceeds 100MB
    if (size > 100 * 1024 * 1024) {
      alert("Total size exceeds 100MB. Please select smaller files.");
      e.target.value = null; // Clear selected files
      return;
    }

    setVideoFiles([...videoFiles, ...files]);
    setTotalSize(totalSize + size);
  };

  const ClearTextField = () => {
    setAmenities({ amenityId: "", amenityDescription: null });
    setCollegeName("");
    setCollegeEstablishedYear("");
    setMetaDescription(null);
    setKeyWords(null);
    setCollegeDescription(null);
    setSchemaScript(null);
    setScholorShipId("");
    setScholorShipCutOff("");
    setUniversityId("");
    setPlacementCompanyId([]);
    setCountry("");
    setState("");
    setDistrict("");
    setArea("");
    setPincode("");
    setCollegeLogo(null);
    setPreviewCollegeLogo(null);
    setCollegeLogoMaxFileSizeErr("");
    setCollegeLogoPath(null);
    setBannerLogo(null);
    setPreviewBannerLogo(null);
    setBannerPath(null);
    setBroucherLogo(null);
    setPreviewBroucherLogo(null);
    setBroucherMaxFileSizeErr("");
    setBroucherPath(null);
    setPreviewImages([]);
    setGalleryImages([]);
    setGalleryImageURLs([]);
    setGalleryImageUploadStatus(false);
    setCourse([courseTemplate]);
  };

  // collegeLogo Validation
  const validateSummaryImageUpload = async (ev) => {
    const fileUploaded = ev.target.files[0];
    if (fileUploaded) {
      // Check if the file format is supported
      const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];
      if (supportedFormats.includes(fileUploaded.type)) {
        if (fileUploaded.size > 512 * 512) {
          try {
            const compressedFile = await compressSummaryImageUpload(
              fileUploaded
            );
            setSummaryImage(compressedFile);
            // setPreviewCollegeLogo(URL.createObjectURL(compressedFile));
            const uploadResult = await uploadCollegeLogo(compressedFile);
            if (uploadResult.success) {
              setSummaryImageUrl(uploadResult.url);
            } else {
              alert(
                "Something went wrong while uploading Summary Image. Please try again later!"
              );
            }
          } catch (error) {
            console.error(
              "Error while processing and uploading the image:",
              error
            );
            alert(
              "Something went wrong while processing the image. Please try again later!"
            );
          }
        } else {
          // setPreviewCollegeLogo(URL.createObjectURL(fileUploaded));
          setSummaryImage(fileUploaded);
          const uploadResult = await SummaryImageUpload(fileUploaded);
          if (uploadResult.success) {
            setSummaryImageUrl(uploadResult.url);
          } else {
            alert(
              "Something went wrong while uploading Summary Image. Please try again later!"
            );
          }
        }
      } else {
        alert("Please upload a valid image in jpeg/jpg/png/gif format.");
      }
    }
  };

  // college logo compress
  const compressSummaryImageUpload = async (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_SIZE = 512 * 512;
          let width = image.width;
          let height = image.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], imageFile.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            "image/jpeg",
            0.8
          );
        };
      };
      reader.readAsDataURL(imageFile);
    });
  };

  // college logo uploading getting path
  const SummaryImageUpload = async (file) => {
    try {
      let formDataCollegeLogo = new FormData();
      formDataCollegeLogo.append("file", file);
      formDataCollegeLogo.append("path", "Summary_ Images");

      let responseCollegeLogo = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formDataCollegeLogo,
        cookies
      );

      if (
        responseCollegeLogo?.status >= 200 &&
        responseCollegeLogo?.status < 300
      ) {
        if (responseCollegeLogo.data.responseCode === "200") {
          return { success: true, url: responseCollegeLogo.data.url };
        } else {
          return { success: false };
        }
      } else if (responseCollegeLogo?.status === 401) {
        navigate("/logout");
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Error while uploading college logo:", error);
      return { success: false };
    }
  };

  // collegeLogo Validation
  const validateCollegeLogo = async (ev) => {
    const fileUploaded = ev.target.files[0];

    if (fileUploaded) {
      // Check if the file format is supported
      const supportedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      if (supportedFormats.includes(fileUploaded.type)) {
        if (fileUploaded.size > 512 * 512) {
          try {
            const compressedFile = await compressClgLogo(fileUploaded);
            setCollegeLogo(compressedFile);
            setPreviewCollegeLogo(URL.createObjectURL(compressedFile));
            const uploadResult = await uploadCollegeLogo(compressedFile);
            if (uploadResult.success) {
              setCollegeLogoPath(uploadResult.url);
            } else {
              alert(
                "Something went wrong while uploading College Logo. Please try again later!"
              );
            }
          } catch (error) {
            console.error(
              "Error while processing and uploading the image:",
              error
            );
            alert(
              "Something went wrong while processing the image. Please try again later!"
            );
          }
        } else {
          setPreviewCollegeLogo(URL.createObjectURL(fileUploaded));
          setCollegeLogo(fileUploaded);
          setCollegeLogoHelperText("");
          const uploadResult = await uploadCollegeLogo(fileUploaded);
          if (uploadResult.success) {
            setCollegeLogoPath(uploadResult.url);
          } else {
            alert(
              "Something went wrong while uploading College Logo. Please try again later!"
            );
          }
        }
      } else {
        alert("Please upload a valid image in jpeg/jpg/png/gif format.");
      }
    }
  };

  // college logo compress
  const compressClgLogo = async (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_SIZE = 512 * 512;
          let width = image.width;
          let height = image.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], imageFile.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            "image/jpeg",
            0.8
          );
        };
      };
      reader.readAsDataURL(imageFile);
    });
  };

  // college logo uploading getting path
  const uploadCollegeLogo = async (file) => {
    try {
      let formDataCollegeLogo = new FormData();
      formDataCollegeLogo.append("file", file);
      formDataCollegeLogo.append("path", "College_Logo");

      let responseCollegeLogo = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formDataCollegeLogo,
        cookies
      );

      if (
        responseCollegeLogo.status >= 200 &&
        responseCollegeLogo.status < 300
      ) {
        if (responseCollegeLogo.data.responseCode === "200") {
          return { success: true, url: responseCollegeLogo.data.url };
        } else {
          return { success: false };
        }
      } else if (responseCollegeLogo.status === 401) {
        navigate("/logout");
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Error while uploading college logo:", error);
      return { success: false };
    }
  };

  // Banner Image Validation
  const validateBannerImage = async (ev) => {
    const fileUploaded = ev.target.files[0];

    if (fileUploaded) {
      // Check if the file format is supported
      const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];
      if (supportedFormats.includes(fileUploaded.type)) {
        if (fileUploaded.size > 512 * 512) {
          try {
            const compressedFile = await compressImageBannerImage(fileUploaded);
            setBannerLogo(compressedFile);
            setPreviewBannerLogo(URL.createObjectURL(compressedFile));
            const uploadResult = await uploadBannerImage(compressedFile);
            if (uploadResult.success) {
              setBannerPath(uploadResult.url);
            } else {
              alert(
                "Something went wrong while uploading College Logo. Please try again later!"
              );
            }
          } catch (error) {
            console.error(
              "Error while processing and uploading the image:",
              error
            );
            alert(
              "Something went wrong while processing the image. Please try again later!"
            );
          }
        } else {
          setPreviewBannerLogo(URL.createObjectURL(fileUploaded));
          setBannerLogo(fileUploaded);
          setBannerLogoHelperText("");
          const uploadResult = await uploadBannerImage(fileUploaded);
          if (uploadResult.success) {
            setBannerPath(uploadResult.url);
          } else {
            alert(
              "Something went wrong while uploading College Logo. Please try again later!"
            );
          }
        }
      } else {
        alert("Please upload a valid image in jpeg/jpg/png/gif format.");
      }
    }
  };
  // banner image compress
  const compressImageBannerImage = async (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_SIZE = 512 * 512;
          let width = image.width;
          let height = image.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], imageFile.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            "image/jpeg",
            0.8
          );
        };
      };
      reader.readAsDataURL(imageFile);
    });
  };

  // banner image uploading getting path
  const uploadBannerImage = async (file) => {
    try {
      let formDataBannerImage = new FormData();
      formDataBannerImage.append("file", file);
      formDataBannerImage.append("path", "Banner_Image");

      let responseBannerImage = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formDataBannerImage,
        cookies
      );

      if (
        responseBannerImage.status >= 200 &&
        responseBannerImage.status < 300
      ) {
        if (responseBannerImage.data.responseCode === "200") {
          return { success: true, url: responseBannerImage.data.url };
        } else {
          return { success: false };
        }
      } else if (responseBannerImage.status === 401) {
        navigate("/logout");
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Error while uploading college logo:", error);
      return { success: false };
    }
  };

  // Broucher Image Validation
  const validateBroucherImage = async (ev) => {
    const fileUploaded = ev.target.files[0];
    if (fileUploaded) {
      // Check if the file format is supported
      const supportedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf", // Add PDF format
      ];

      if (supportedFormats.includes(fileUploaded.type)) {
        if (fileUploaded.type === "application/pdf") {
          if (fileUploaded.size < 2048 * 2048) {
            // Handle PDF file
            setBroucherLogo("./media/png/pdf-icon.png");
            setPreviewBroucherLogo("./media/png/pdf-icon.png");
            const uploadResult = await uploadBroucherImage(fileUploaded);
            if (uploadResult.success) {
              setBroucherPath(uploadResult.url);
            } else {
              alert(
                "Something went wrong while uploading College Logo. Please try again later!"
              );
            }
          } else {
            alert("Pdf is Higher Than 2MB Please Upload Below 2MB File.");
          }
        } else {
          if (fileUploaded.size > 512 * 512) {
            try {
              const compressedFile = await compressImageBroucherImage(
                fileUploaded
              );
              setBroucherLogo(compressedFile);
              setPreviewBroucherLogo(URL.createObjectURL(compressedFile));
              const uploadResult = await uploadBroucherImage(compressedFile);
              if (uploadResult.success) {
                setBroucherPath(uploadResult.url);
                // alert(uploadResult.url);
              } else {
                alert(
                  "Something went wrong while uploading College Logo. Please try again later!"
                );
              }
            } catch (error) {
              console.error(
                "Error while processing and uploading the image:",
                error
              );
              alert(
                "Something went wrong while processing the image. Please try again later!"
              );
            }
          } else {
            setPreviewBroucherLogo(URL.createObjectURL(fileUploaded));
            setBroucherLogo(fileUploaded);
            setBroucherHelperText("");
            const uploadResult = await uploadBroucherImage(fileUploaded);
            if (uploadResult.success) {
              setBroucherPath(uploadResult.url);
            } else {
              alert(
                "Something went wrong while uploading Broucher Image. Please try again later!"
              );
            }
          }
        }
      } else {
        alert(
          "Please upload a valid image in jpeg/jpg/png/gif or a PDF format."
        );
      }
    }
  };

  // broucher image compress
  const compressImageBroucherImage = async (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_SIZE = 512 * 512;
          let width = image.width;
          let height = image.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], imageFile.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            "image/jpeg",
            0.8
          );
        };
      };
      reader.readAsDataURL(imageFile);
    });
  };

  // Broucher image uploading getting path
  const uploadBroucherImage = async (file) => {
    try {
      let formDataBroucher = new FormData();
      formDataBroucher.append("file", file);
      formDataBroucher.append("path", "Broucher_Image");

      let responseBroucherImage = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formDataBroucher,
        cookies
      );

      if (
        responseBroucherImage.status >= 200 &&
        responseBroucherImage.status < 300
      ) {
        if (responseBroucherImage.data.responseCode === "200") {
          return { success: true, url: responseBroucherImage.data.url };
        } else {
          return { success: false };
        }
      } else if (responseBroucherImage.status === 401) {
        navigate("/logout");
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Error while uploading college logo:", error);
      return { success: false };
    }
  };

  // gallery image validation
  const handleImageGalleryUpload = async (ev) => {
    if (ev.target.files) {
      const images = ev.target.files;
      const compressedImages = [];

      for (let i = 0; i < images.length; i++) {
        const img = images[i];
        if (!img.type.match(/^image\/(jpe?g|png|gif)/)) {
          alert("Please upload a valid image in jpeg/jpg/png/gif format");
        } else {
          const compressedImg =
            img.size > 512 * 512 ? await compressImage(img) : img;
          compressedImages.push(compressedImg);
        }
      }

      setPreviewImages((prevImages) =>
        prevImages
          .slice(0, 14)
          .concat(compressedImages?.map((img) => URL.createObjectURL(img)))
      );
      setGalleryImages((prevImages) =>
        prevImages.slice(0, 14).concat(compressedImages)
      );
    }
  };

  // gallery image comperss each
  const compressImage = (image) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_SIZE = 1024 * 1024;
          let width = img.width;
          let height = img.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], image.name, {
                type: image.type,
              });
              resolve(compressedFile);
            },
            image.type,
            0.8
          );
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(image);
    });
  };

  // Remove new gallery image
  const deleteImageGallery = (idx) => {
    let gllryImages = galleryImages;
    gllryImages.splice(idx, 1);
    setGalleryImages([...gllryImages]);

    let prvwImages = previewImages;
    prvwImages.splice(idx, 1);
    setPreviewImages([...prvwImages]);
  };
  // Remove previous gallery image
  const deletePreviousImageGallery = (index) => {
    let gllryprevImages = previewPreviousImages;
    gllryprevImages.splice(index, 1);
    setPreviewPreviousImages([...gllryprevImages]);

    // Copy the current state
    let prvwPreviousImagesCopy = [...galleryPreviousImageURLs];
    const removedItem = {
      id: prvwPreviousImagesCopy[index].id,
      collegeId: prvwPreviousImagesCopy[index].collegeId,
      status: "Deleted",
    };
    if (removedItem.id === undefined) {
      prvwPreviousImagesCopy.splice(index, 1); // Remove the item from the original array
      setPreviewPreviousImages(prvwPreviousImagesCopy); // Update the course state without the removed item
    } else {
      prvwPreviousImagesCopy.splice(index, 1); // Remove the item from the original array

      setPreviousDeletedImages((prevDeletedCourse) => [
        removedItem,
        ...prevDeletedCourse,
      ]); // Add the removed item to the deletedCourse state
      setGalleryPreviousImageURLs(prvwPreviousImagesCopy); // Update the course state without the removed item
    }
  };

  // below the function is adding course with existing course
  const addCourse = () => {
    setCourse([...course, courseTemplate]);
  };

  const addExam = (courseIndex) => {
    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseEntryCutoffList.push({
      examId: "",
      cutoff: "",
      cutoffType: "",
    });
    setCourse(updatedCourse);
  };
  const addScholarship = (courseIndex) => {
    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseScholarshipCutoffList.push({
      examId: "",
      cutoff: "",
      cutoffType: "",
      scholarship: "",
      scholarshipId: "",
      scholarshipType: "",
    });
    setCourse(updatedCourse);
  };

  const removeExam = (courseIndex, examIndex) => {
    const updatedCourse = [...course];
    const deletedExam =
      updatedCourse[courseIndex].collegeCourseEntryCutoffList[examIndex];

    if (deletedExam.id === undefined) {
      updatedCourse[courseIndex].collegeCourseEntryCutoffList.splice(
        examIndex,
        1
      );
    } else {
      const deletedItem = {
        id: deletedExam.id,
        status: "Deleted",
      };
      updatedCourse[courseIndex].collegeCourseEntryCutoffList.splice(
        examIndex,
        1
      );
      updatedCourse[courseIndex].collegeCourseEntryCutoffList.push(deletedItem);
      setDeletedExam((prevDeletedExams) => [...prevDeletedExams, deletedItem]);
    }

    setCourse(updatedCourse);
  };

  const removeScholarship = (courseIndex, scholarshipIndex) => {
    const updatedCourse = [...course];
    const deletedScholarship1 =
      updatedCourse[courseIndex].collegeCourseScholarshipCutoffList[
        scholarshipIndex
      ];

    if (deletedScholarship1.id === undefined) {
      updatedCourse[courseIndex].collegeCourseScholarshipCutoffList.splice(
        scholarshipIndex,
        1
      );
    } else {
      const deletedItem = {
        id: deletedScholarship1.id,
        status: "Deleted",
      };
      updatedCourse[courseIndex].collegeCourseScholarshipCutoffList.splice(
        scholarshipIndex,
        1
      );
      updatedCourse[courseIndex].collegeCourseScholarshipCutoffList.push(
        deletedItem
      );
      setDeletedScholarship((prevDeletedScholarships) => [
        ...prevDeletedScholarships,
        deletedItem,
      ]);
    }

    setCourse(updatedCourse);
  };

  const removeCourse = (courseIndex) => {
    const updatedCourse = [...course];
    const removedItem = {
      id: updatedCourse[courseIndex].id,
      courseId: updatedCourse[courseIndex].courseId,
      status: "Deleted",
    };

    if (removedItem.id === undefined) {
      updatedCourse.splice(courseIndex, 1);
    } else {
      updatedCourse.splice(courseIndex, 1);
      setDeletedCourse((prevDeletedCourses) => [
        ...prevDeletedCourses,
        removedItem,
      ]);
    }

    setCourse(updatedCourse);
  };

  // below the function is course all fields and courseIndex save to the course state
  const onChangeGetCourseDetails = (e, courseIndex) => {
    const { name, value } = e.target;
    const updatedCourse = course.map((courseObj, i) =>
      i === courseIndex ? { ...courseObj, [name]: value } : courseObj
    );
    setCourse(updatedCourse);
  };

  const onChangeGetCourseExamDetails = (e, examIndex, courseIndex) => {
    const { name, value } = e.target;

    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseEntryCutoffList[examIndex] = {
      ...updatedCourse[courseIndex].collegeCourseEntryCutoffList[examIndex],
      [name]: value,
    };

    setCourse(updatedCourse);
  };
  const onChangeGetCourseScholarshipDetails = (
    e,
    scholarshipIndex,
    courseIndex
  ) => {
    const { name, value } = e.target;

    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseScholarshipCutoffList[
      scholarshipIndex
    ] = {
      ...updatedCourse[courseIndex].collegeCourseScholarshipCutoffList[
        scholarshipIndex
      ],
      [name]: value,
    };

    setCourse(updatedCourse);
  };

  // below the code is for slug url based on college name
  const handleCollegeNameChange = (e) => {
    const newValue = e.target.value;
    setCollegeName(newValue);
    const formattedValue = newValue.replace(/ /g, "-");
    setOgUrl(formattedValue);
  };

  // pincode dropdown options function
  const pincodeList = (
    addressList
      ?.find((item) => item.countryName === country)
      ?.states.find((item) => item.stateName === state)
      ?.districts.find((item) => item.districtName === district)?.pincodes || []
  )?.map((pincode) => pincode.pincode);

  // district options function
  const districtList = (
    addressList
      ?.find((item) => item.countryName === country)
      ?.states.find((item) => item.stateName === state)?.districts || []
  )?.map((district) => district.districtName);

  // meta description character validation
  const maxCharacters = 155;
  const handleMetaDescriptionChange = (e) => {
    const newValue = e.target.value;
    const characterCount = newValue.length;

    if (characterCount >= 70 && characterCount <= maxCharacters) {
      setMetaDescription(newValue);
      setMetaDescriptionError(false);
      setMetaDescriptionHelperText("");
    } else {
      setMetaDescription(newValue);
      setMetaDescriptionError(true);
      setMetaDescriptionHelperText(
        "Meta description must be between 70 and 155 characters."
      );
    }
  };

  // below the code all is related to google map

  const onMarkerDragEnd = (coords) => {
    setMarkerPosition({
      lat: coords.latLng.lat(),
      lng: coords.latLng.lng(),
    });
  };

  const showPosition = (position) => {
    if (position.coords.latitude && position.coords.longitude) {
      setMarkerPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
  };

  const mapCenterMoved = () => {
    setMarkerPosition({
      lat: mapRef.current.getCenter().lat(),
      lng: mapRef.current.getCenter().lng(),
    });
  };

  const onAutoCompleteLoad = (ac) => {
    setAutocomplete(ac);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null && !!autocomplete.getPlace().geometry?.location) {
      setMarkerPosition({
        lat: autocomplete.getPlace().geometry.location.lat(),
        lng: autocomplete.getPlace().geometry.location.lng(),
      });
    }
  };

  // onchange the placement Company
  const handlePlacementChange = (event, newValue) => {
    const stringIds = newValue.map((company) => company.id.toString());

    setPlacementCompanyId(stringIds);
    setPlacementCompanyError(false);
    setPlacementCompanyHelperText("");
  };
  const handleRemovepc = (prev) => {
    setPlacementCompanyId(placementCompanyId.filter((r) => r !== prev.id));
  };

  // onsubmit active this function check the required fields and upload the gallery image and callback college save function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);
    let validationError = false;
    if (!collegeName) {
      setCollegeNameHelperText("Please Enter College Name");
      setCollegeNameError(true);
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!universityId) {
      setUniversityIdHelperText("Please Select University");
      setUniversityIdError(true);
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!collegeEstablishedYear) {
      setEstablishedYearError(true);
      setEstablishedYearHelperText("Please Enter College Established Year");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!previewPreviousImages.length > 0 || !previewImages.length > 0) {
      setGalleryImagesError(true);
      setGalleryImagesHelperText("please upload minimum images");
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].months) {
      setMonthError(true);
      setMonthHelperText("Please Enter year for Select Course");
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].years) {
      setYearError(true);
      setYearHelperText("Please Enter year for Select Course");
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!district) {
      setDistrictError(true);
      setDistrictHelperText("Please Enter City");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!pincode) {
      setPincodeError(true);
      setPincodeHelperText("Please Enter Postal Code");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!area) {
      setAreaError(true);
      setAreaHelperText("Please Enter Area and Road");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!programId) {
      setProgramError(true);
      setProgramHelperText("Please Select Program Type");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!collegeLogoPath) {
      setCollegeLogoError(true);
      setCollegeLogoHelperText("Please Upload College Logo");
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!broucherPath) {
      setBroucherError(true);
      setBroucherHelperText("Please Upload Broucher");
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!country) {
      setCountryError(true);
      setCountryHelperText("Please Select Country");
      validationError = true;
      setUpdateLoading(false);
      setGalleryImageUploadStatus(false);
    }
    if (!state) {
      setStateError(true);
      setStateHelperText("Please Select State");
      validationError = true;
      setUpdateLoading(false);
      setGalleryImageUploadStatus(false);
    }
    if (!instituteBrandId) {
      setInstituteBrandHelperText("Please Select College Brand Name");
      setInstituteBrandError(true);
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!course[0].courseId) {
      setCourseNameError(true);
      setCourseNameHelperText("Please Select Atleast One course");
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].courseSpecializationId) {
      setSpecializationIdError(true);
      setSpecializationIdHelperText(
        "Please Enter Specialization for Select Course"
      );
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].courseFee) {
      setCourseFeeError(true);
      setCourseFreeHelperText("Please Enter Select Course Fee");
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].batchCapacity) {
      setCourseBatchCapacityError(true);
      setCourseBatchCapacityHelperText(
        "Please Enter Select Course Batch Capacity"
      );
      setUpdateLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (placementCompanyId.length <= 0) {
      setPlacementCompanyError(true);
      setPlacementCompanyHelperText(
        "Please Select Atleast One Placement Company"
      );
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!orUrl) {
      setOgurlError(true);
      setOgurlHelperText("Please Enter College Name Correctly");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }
    if (!amenities[0].amenityId) {
      setAmenityError(true);
      setAmenityHelperText("please selcted amentiy");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setUpdateLoading(false);
    }

    if (!validationError) {
      for (let i = 0; i < galleryImages.length; i++) {
        let formDataGallery = new FormData();
        formDataGallery.append("file", galleryImages[i]);
        formDataGallery.append("path", "College_Gallery_Image");

        let responseGall = await invokeFormDataApi(
          config.getMyCollege + apiList.uploadFile,
          formDataGallery,
          cookies
        );

        if (responseGall.status >= 200 && responseGall.status < 300) {
          if (responseGall.data.responseCode === "200") {
            setGalleryImageURLs((prevState) => [
              ...prevState,
              {
                mediaType: "Image",
                mediaFilePath: responseGall.data.url,
              },
            ]);
          } else {
            alert(
              "Something went wrong while uploading Gallery images. Please try again later!"
            );
          }
        } else if (responseGall.status === 401) {
          setUpdateLoading(false);
          navigate("/logout");
        } else {
          alert(
            "Something went wrong while uploading Gallery images. Please try again later!!"
          );
          setGalleryImageUploadStatus(false);
          setUpdateLoading(false);
        }
      }
      for (let i = 0; i < videoFiles.length; i++) {
        let formDataVideos = new FormData();
        formDataVideos.append("file", videoFiles[i]);
        formDataVideos.append("path", "college_videos");

        let response = await invokeFormDataApi(
          config.getMyCollege + apiList.uploadVideo,
          formDataVideos,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          if (response.data.responseCode === "200") {
            setVideoUrl((prevState) => [
              ...prevState,
              {
                mediaType: "video",
                mediaFilePath: response.data.url,
              },
            ]);
          } else {
            alert(
              "Something went wrong while uploading Video. Please try again later!"
            );
          }
        } else if (response.status === 401) {
          navigate("/logout");
        } else {
          alert(
            "Something went wrong while uploading Gallery images. Please try again later!!"
          );
          setGalleryImageUploadStatus(false);
          setUpdateLoading(false);
        }
      }
      setGalleryImages([]);
      setGalleryImageUploadStatus(true);
    } else if (galleryImages.length === 0) {
      setGalleryImages([]);
      setGalleryImageUploadStatus(true);
    }
  };

  // use effect start from here
  useEffect(() => {
    const updateCollege = async () => {
      let params = {
        id: id,
        collegeName: collegeName,

        universityId: universityId,

        collegeLogoPath: collegeLogoPath,

        bannerImagePath: bannerPath,

        brochurePath: broucherPath,

        scholarshipList: scholarShipId,

        scholarshipCutOff: scholorShipCutOff,

        collegeTypeId: collegeType,

        instituteTypeId: insituteTypeId,

        placementCompanyIdsList: placementCompanyId,

        establishedYear: collegeEstablishedYear,

        country: country,
        state: state,
        district: district,
        pincode: pincode,
        area: area,

        seoOgUrl: orUrl,

        programId: programId,

        instituteBrandId: instituteBrandId,

        collegeCourses: mergedCourses,

        collegeMediaFiles: [
          ...deletedVideoUrl,
          ...videoUrl,
          ...mergedGalleryImagesDeleted,
          ...deletedVideoLinks,
          ...videoLinks,
        ],

        collegeAmenities: mergedAmenities,

        collegeFullDescription: collegeDescription,
        metaKeywords: keyWords || null,
        metaDescription: metaDescription || null,
        schemaScript: schemaScript || null,

        latitude: markerPosition.lat,
        longitude: markerPosition.lng,

        collegeCategory: collegeCategory,
        trustName: trustName,
        emails: emails,
        contactNos: contactNumbers,
        websiteUrl: website,
        admissionSummary: admissionSummary,
        placementCompanySummary: placementCompanySummary,
        cutoffSummary: cutOffSummary,
        scholarshipSummary: scholarshipSummar,
        courseFeeSummary: courseFeeSummary,
        resultsSummary: resultSummary,
        qnaSummary: questionAnswerSummary,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateCollege,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("College Update SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/dashboard");
          ClearTextField();
          setUpdateLoading(false);
          setGalleryImageUploadStatus(false);
        } else {
          alert(
            "Something went wrong while Updating college. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        setUpdateLoading(false);
        setGalleryImageUploadStatus(false);
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Updating college. Please try again later!!"
        );
        setUpdateLoading(false);
        setGalleryImageUploadStatus(false);
      }
    };
    if (galleryImageUploadStatus) {
      updateCollege();
      setGalleryImageUploadStatus(false);
    }
  }, [galleryImageUploadStatus, cookies]);

  // below the code is for fetching
  useEffect(() => {
    const getAllAmenities = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAmenitites,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setAmenitiesList(response.data.amenities);
        } else {
          alert(
            "Something went wrong while getting the Amenities Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Amenities Details. Please try again later!!"
        );
      }
    };
    const getAllPlacementCompany = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPlacementCompanies,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setPlacementCompaniesList(response.data.placementCompanies);
        } else {
          alert(
            "Something went wrong while getting the Placement Companies Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Placement Companies Details. Please try again later!!"
        );
      }
    };
    const getAllAddress = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAddress,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setAddressList(response.data.countries);
        } else {
          alert(
            "Something went wrong while getting the College Types Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the College Types Details. Please try again later!!"
        );
      }
    };
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseNameList(response.data.courses);
          console.log("fetching");
        } else {
          alert(
            "Something went wrong while getting the course list Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the course list Details. Please try again later!!"
        );
      }
    };
    const getAllUniversity = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getUniversities,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setUniversityList(response.data.universities);
        } else {
          alert(
            "Something went wrong while getting the University Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the University Details. Please try again later!!"
        );
      }
    };
    const getAllScholorship = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getScholorShips,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setScholorshipList(response.data.scholarships);
        } else {
          alert(
            "Something went wrong while getting the scholorship Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the scholorship Details. Please try again later!!"
        );
      }
    };
    const getAllProgram = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setProgramsList(response.data.programs);
        } else {
          alert(
            "Something went wrong while getting the Program Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Program Details. Please try again later!!"
        );
      }
    };
    const getInstituteBrands = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getInstituteBrands,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setInstituteBrand(response.data.instituteBrands);
        } else {
          alert(
            "Something went wrong while getting the insitute brand Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the insitute brand Details. Please try again later!!"
        );
      }
    };
    const getSpecialization = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAllSpecialization,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setSpecializationList(response.data.specializations);
        } else {
          alert(
            "Something went wrong while getting the Specializations Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Specializations Details. Please try again later!!"
        );
      }
    };
    const getExam = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getExams,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setExamList(response.data.exams);
        } else {
          alert(
            "Something went wrong while getting the Exams Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Exams Details. Please try again later!!"
        );
      }
    };

    getExam();
    getSpecialization();
    getAllUniversity();
    getAllCourseName();
    getAllAddress();
    getAllPlacementCompany();
    getAllAmenities();
    getAllScholorship();
    getAllProgram();
    getInstituteBrands();
  }, [cookies]);

  // below the code is for google map
  useEffect(() => {
    let url =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      markerPosition.lat +
      "," +
      markerPosition.lng +
      "&key=" +
      config.googleMapKey;

    function updateState(type, state, setter, longName) {
      if (type) {
        setter(longName);
      }
    }

    fetch(url, {
      method: "get",
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.results.length > 0) {
          const data = res.results[0].address_components;
          for (let i = 0; i < data.length; i++) {
            const object = data[i];
            const types = object.types;

            updateState(
              types.includes("country"),
              country,
              setCountry,
              object.long_name
            );
            updateState(
              types.includes("administrative_area_level_1"),
              state,
              setState,
              object.long_name
            );
            updateState(
              types.includes("administrative_area_level_3"),
              district,
              setDistrict,
              object.long_name
            );
            updateState(
              types.includes("postal_code"),
              pincode,
              setPincode,
              object.long_name
            );
            updateState(
              // types.includes("sublocality") ||
              types.includes("sublocality"),
              // types.includes("sublocality_level_1"),
              area,
              setArea,
              object.long_name
            );
          }
        }
      });
  }, [markerPosition]);

  useEffect(() => {
    const getCollege = async () => {
      let params = {
        id: id,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCollege,
        params,
        cookies
      );
      console.log("response", response);
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCollegeName(response.data.colleges.collegeName);

          setUniversityId(response.data.colleges.universityId);

          // college logo
          setCollegeLogoPath(response.data.colleges.collegeLogoPath);
          setCollegeLogo(response.data.colleges.collegeLogoPath);
          setPreviewCollegeLogo(response.data.colleges.collegeLogoPath);

          // brochure file
          setPreviewBroucherLogo(response.data.colleges.brochurePath);
          setBroucherLogo(response.data.colleges.brochurePath);
          setBroucherPath(response.data.colleges.brochurePath);

          // scholarship getting comma separated from database spliting and assign to the state variable
          var scholarshipData = response.data.colleges.scholarship;
          if (!Array.isArray(scholarshipData)) {
            var scholarshipArray = scholarshipData?.split(",");
            scholarshipArray = scholarshipArray?.map(String);
          }
          setScholorShipId(scholarshipArray);

          setCollegeType(response.data.colleges.collegeTypeId);

          setInsituteTypeId(response.data.colleges.instituteTypeId);

          let placement = response.data.colleges.placementCompanyId;

          if (!Array.isArray(placement)) {
            var companiesArray = placement?.split(",");
            companiesArray = companiesArray?.map(String);
          }
          setPlacementCompanyId(companiesArray);

          setCollegeEstablishedYear(response.data.colleges.establishedYear);

          setCountry(response.data.colleges.country);

          setDistrict(response.data.colleges.district);

          setState(response.data.colleges.state);

          setPincode(response.data.colleges.pincode);

          setArea(response.data.colleges.area);

          setCollegeDescription(
            response.data.colleges.collegeFullDescription !== ""
              ? response.data.colleges.collegeFullDescription
              : null
          );

          setKeyWords(response.data.colleges.metaKeywords);

          setMetaDescription(response.data.colleges.metaDescription);

          setSchemaScript(response.data.colleges.schemaScript);

          setOgUrl(response.data.colleges.seoOgUrl);

          setMarkerPosition({
            lat: response.data.colleges.latitude,
            lng: response.data.colleges.longitude,
          });

          setProgramId(response.data.colleges.programId);

          setInstituteBrandId(response.data.colleges.instituteBrandId);

          setCollegeCategory(response.data.colleges.collegeCategory);

          setTrustName(response.data.colleges.trustName);

          setEmails(response.data.colleges.emails);

          setContactNumbers(response.data.colleges.contactNos);

          setWebsite(response.data.colleges.websiteUrl);

          setAdmissionSummary(
            response.data.colleges.admissionSummary !== ""
              ? response.data.colleges.admissionSummary
              : null
          );

          setPlacementCompanySummary(
            response.data.colleges.placementCompanySummary !== ""
              ? response.data.colleges.placementCompanySummary
              : null
          );

          setCutOffSummary(
            response.data.colleges.cutoffSummary !== ""
              ? response.data.colleges.cutoffSummary
              : null
          );

          setScholarshipSummary(
            response.data.colleges.scholarshipSummary !== ""
              ? response.data.colleges.scholarshipSummary
              : null
          );

          setCourseFeeSummary(
            response.data.colleges.courseFeeSummary !== ""
              ? response.data.colleges.courseFeeSummary
              : null
          );

          setResultSummary(
            response.data.colleges.resultsSummary !== ""
              ? response.data.colleges.resultsSummary
              : null
          );

          setQuestionAnswerSummary(
            response.data.colleges.qnaSummary !== ""
              ? response.data.colleges.qnaSummary
              : null
          );

          // below the logic is if college courses objects don't have two protey getting error that why below the logic it will check and add empty property

          let arr = response.data.colleges.collegeCourses;
          let newArray = arr?.map((item) => {
            if (item.collegeCourseEntryCutoffList.length <= 1) {
              // If falsy or not an array, add the default value
              item.collegeCourseEntryCutoffList.push({
                examId: "",
                cutoff: "",
                cutoffType: "",
              });
            }
            if (item.collegeCourseScholarshipCutoffList.length <= 1) {
              item.collegeCourseScholarshipCutoffList.push({
                examId: "",
                cutoff: "",
                cutoffType: "",
                scholarship: "",
                scholarshipId: "",
                scholarshipType: "",
              });
            }

            const { createdDate, createdBy, ...rest } = item;

            return rest;
          });

          setCourse(newArray);

          const collegeMediaGallery =
            response.data.colleges.collegeMediaFiles.filter(
              (item) => item.mediaType === "Image"
            );
          const collegeMediaVideo =
            response.data.colleges.collegeMediaFiles.filter(
              (item) => item.mediaType === "video"
            );
          const collegeMediaYoutube =
            response.data.colleges.collegeMediaFiles.filter(
              (item) => item.mediaType === "youtube"
            );

          // Extract image paths for previewImages (if needed)
          const previewPreviousImages1 = collegeMediaGallery.map(
            (image) => image.mediaFilePath
          );

          // Extract image paths for galleryImageURLs
          const galleryPreviousImageURL = collegeMediaGallery?.map((image) => ({
            id: image.id,
            collegeId: id,
            mediaType: image.mediaType,
            mediaFilePath: image.mediaFilePath,
            status: image.status,
          }));
          // Set the states
          setPreviewPreviousImages(previewPreviousImages1);
          setGalleryPreviousImageURLs(galleryPreviousImageURL);

          // Extract youtube paths
          const youtubeURL = collegeMediaYoutube?.map((youtube) => ({
            id: youtube.id,
            collegeId: id,
            mediaType: youtube.mediaType,
            mediaFilePath: youtube.mediaFilePath,
            status: youtube.status,
          }));
          // Extract video paths
          const videoUrl = collegeMediaVideo?.map((video) => ({
            id: video.id,
            collegeId: id,
            mediaType: video.mediaType,
            mediaFilePath: video.mediaFilePath,
            status: video.status,
          }));

          setVideoLinks(youtubeURL);
          setVideoUrl(videoUrl);

          let arramenity = response.data.colleges.collegeAmenities;
          const newArrayAmenity = arramenity?.map((item) => {
            const {
              createdDate,
              createdBy,
              amenityName,
              amenityLogoPath,
              amenityDescription,
              ...rest
            } = item;

            // Convert empty string to null for amenityDescription
            const updatedAmenityDescription =
              amenityDescription === "" ? null : amenityDescription;

            return {
              createdDate,
              createdBy,
              amenityName,
              amenityLogoPath,
              amenityDescription: updatedAmenityDescription,
              ...rest,
            };
          });

          setAmenities(
            newArrayAmenity.length > 0
              ? [...newArrayAmenity]
              : [...newArrayAmenity, ...amenities]
          );

          setPreviewBannerLogo(response.data.colleges.bannerImagePath);
          setBannerLogo(response.data.colleges.bannerImagePath);
          setBannerPath(response.data.colleges.bannerImagePath);

          setScholorShipCutOff(response.data.colleges.scholarshipCutOff);
          setFetching(false);
        } else {
          alert(
            "Something went wrong while getting the College Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the College Details. Please try again later!!"
        );
      }
    };
    if (fetching) {
      getCollege();
    }
  }, [cookies, fetching]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{ flexGrow: 1, p: 2, backgroundColor: "#F6F8FB" }}
        >
          <DashboardHeader />
          {!fetching ? (
            <Container sx={{ mt: 3 }}>
              <form onSubmit={handleSubmit}>
                <Paper sx={{ p: 2 }}>
                  <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item md={6}>
                      <TextField
                        label="College Name"
                        fullWidth
                        onChange={handleCollegeNameChange}
                        value={collegeName}
                        error={collegeNameError}
                        helperText={collegeNameHelperText}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        fullWidth
                        value={
                          defaultUniversity
                            ? defaultUniversity.universityName
                            : null
                        }
                        options={universityList?.map(
                          (item) => item.universityName
                        )}
                        onChange={(event, newValue) => {
                          const selectedUniversity = universityList?.find(
                            (item) => item.universityName === newValue
                          );

                          if (selectedUniversity) {
                            const selectedUniversityId = selectedUniversity.id;
                            setUniversityId(selectedUniversityId);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={universityIdError}
                            helperText={universityIdHelperText}
                            {...params}
                            label="Select University"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        type="text"
                        label="OG URL"
                        error={ogurlError}
                        helperText={ogurlHelperText}
                        disabled
                        fullWidth
                        value={orUrl}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        fullWidth
                        value={defaulProgram ? defaulProgram.programName : null}
                        options={programList?.map((item) => item.programName)}
                        onChange={(event, newValue) => {
                          const selectedUniversity = programList?.find(
                            (item) => item.programName === newValue
                          );

                          if (selectedUniversity) {
                            const selectedUniversityId = selectedUniversity.id;
                            setProgramId(selectedUniversityId);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={programError}
                            helperText={programHelperText}
                            {...params}
                            label="Select Program"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/* add row courses start from here  */}
                  {course?.map((courseObj, courseIndex) => (
                    <TableContainer
                      key={courseIndex}
                      component={Paper}
                      elevation={3}
                      sx={{ mb: 2 }}
                    >
                      <Table>
                        <TableBody>
                          <TableRow sx={{ backgroundColor: "#dfe6e9" }}>
                            <TableCell>Course</TableCell>
                            <TableCell>Course Fee</TableCell>
                            <TableCell>Batch Capacity</TableCell>
                            <TableCell>Specialization</TableCell>
                            <TableCell>Year</TableCell>
                            <TableCell>Month</TableCell>
                            <TableCell>Add</TableCell>
                            <TableCell>Remove</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <FormControl fullWidth>
                                <InputLabel
                                  id={`select-college-course-${courseIndex}`}
                                >
                                  Course
                                </InputLabel>
                                <Select
                                  labelId={`select-college-course-${courseIndex}`}
                                  id={`select-college-course-${courseIndex}`}
                                  name="courseId"
                                  error={courseNameError}
                                  helperText={courseNameHelperText}
                                  value={courseObj.courseId}
                                  label="Course"
                                  onChange={(e) => {
                                    onChangeGetCourseDetails(e, courseIndex);
                                    setCourseNameError(false);
                                    setCourseNameHelperText("");
                                  }}
                                >
                                  {courseNameList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.courseName +
                                        " (" +
                                        item.courseType +
                                        ")"}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <TextField
                                type="text"
                                error={courseFeeError}
                                helperText={courseFreeHelperText}
                                value={courseObj.courseFee}
                                label="Course Fee"
                                onChange={(e) => {
                                  const inputValue = e.target.value;

                                  if (!isNaN(inputValue) || inputValue === "") {
                                    onChangeGetCourseDetails(e, courseIndex);
                                    setCourseFeeError(false);
                                    setCourseFreeHelperText("");
                                  } else {
                                    setCourseFeeError(true);
                                  }
                                }}
                                fullWidth
                                name="courseFee"
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                type="text"
                                error={courseBatchCapacityError}
                                helperText={courseBatchCapacityHelperText}
                                label="Batch Capacity"
                                value={courseObj.batchCapacity}
                                onChange={(e) => {
                                  const inputValue = e.target.value;

                                  if (!isNaN(inputValue) || inputValue === "") {
                                    onChangeGetCourseDetails(e, courseIndex);
                                    setCourseBatchCapacityError(false);
                                    setCourseBatchCapacityHelperText("");
                                  } else {
                                    setCourseBatchCapacityError(true);
                                  }
                                }}
                                fullWidth
                                name="batchCapacity"
                              />
                            </TableCell>
                            <TableCell>
                              <FormControl fullWidth>
                                <InputLabel
                                  id={`select-specialization-${courseIndex}`}
                                >
                                  Specialization
                                </InputLabel>
                                <Select
                                  labelId={`select-specialization-${courseIndex}`}
                                  id={`select-specialization-${courseIndex}`}
                                  error={specializationIdError}
                                  value={courseObj.courseSpecializationId}
                                  label="Specialization"
                                  name="courseSpecializationId"
                                  onChange={(e) => {
                                    onChangeGetCourseDetails(e, courseIndex);
                                    setSpecializationIdError(false);
                                    setSpecializationIdHelperText("");
                                  }}
                                  fullWidth
                                  sx={{
                                    color: specializationIdError
                                      ? "red"
                                      : undefined,
                                  }}
                                >
                                  {specializationList?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.specialization}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {specializationIdError && (
                                  <Typography
                                    sx={{ fontSize: "0.75rem", color: "red" }}
                                  >
                                    {specializationIdHelperText}
                                  </Typography>
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Year
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  error={yearError}
                                  helperText={yearHelperText}
                                  value={courseObj.years}
                                  label="year"
                                  name="years"
                                  onChange={(e) => {
                                    onChangeGetCourseDetails(e, courseIndex);
                                    setYearError(false);
                                    setYearHelperText("");
                                  }}
                                  fullWidth
                                >
                                  <MenuItem value="1">1</MenuItem>
                                  <MenuItem value="2">2</MenuItem>
                                  <MenuItem value="3">3</MenuItem>
                                  <MenuItem value="4">4</MenuItem>
                                  <MenuItem value="5">5</MenuItem>
                                  <MenuItem value="6">6</MenuItem>
                                  <MenuItem value="7">7</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Month
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  error={monthError}
                                  helperText={monthHelperText}
                                  value={courseObj.months}
                                  label="Month"
                                  name="months"
                                  onChange={(e) => {
                                    onChangeGetCourseDetails(e, courseIndex);
                                    setMonthError(false);
                                    setMonthHelperText("");
                                  }}
                                  fullWidth
                                >
                                  <MenuItem value="0">0</MenuItem>
                                  <MenuItem value="1">1</MenuItem>
                                  <MenuItem value="2">2</MenuItem>
                                  <MenuItem value="3">3</MenuItem>
                                  <MenuItem value="4">4</MenuItem>
                                  <MenuItem value="5">5</MenuItem>
                                  <MenuItem value="6">6</MenuItem>
                                  <MenuItem value="7">7</MenuItem>
                                  <MenuItem value="8">8</MenuItem>
                                  <MenuItem value="9">9</MenuItem>
                                  <MenuItem value="10">10</MenuItem>
                                  <MenuItem value="11">11</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                sx={{
                                  background: "#405189",
                                  borderRadius: 5,
                                  border: 0,
                                  color: "white",
                                  height: 48,
                                  padding: "0 30px",
                                  boxShadow: "0 3px 5px 2px #405189",
                                  transition: "background 0.3s",
                                  "&:hover": {
                                    background: "#405189",
                                  },
                                }}
                                onClick={addCourse}
                              >
                                <AddIcon />
                              </Button>
                            </TableCell>
                            <TableCell>
                              {course.length > 1 && (
                                <IconButton
                                  color="red"
                                  onClick={() => removeCourse(courseIndex)}
                                >
                                  <DeleteOutlineIcon fontSize="large" />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ backgroundColor: "#dfe6e9" }}>
                            <TableCell>Exam</TableCell>
                            <TableCell>Cut Off</TableCell>
                            <TableCell>Type of Cut off</TableCell>
                            <TableCell>Add</TableCell>
                            <TableCell>Remove</TableCell>
                          </TableRow>
                          {courseObj.collegeCourseEntryCutoffList?.map(
                            (item, examIndex) => (
                              <TableRow key={examIndex}>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel id="select-college-course">
                                      Exam
                                    </InputLabel>
                                    <Select
                                      labelId="select-college-course"
                                      id="select-college-course"
                                      name="examId"
                                      // error={courseNameError}
                                      // helperText={courseNameHelperText}
                                      value={item.examId} // Assuming `courseId` is the selected course ID
                                      label="Exam"
                                      onChange={(e) => {
                                        onChangeGetCourseExamDetails(
                                          e,
                                          examIndex,
                                          courseIndex
                                        );
                                        // setCourseNameError(false);
                                        // setCourseNameHelperText("");
                                      }}
                                    >
                                      {examList.map((exam) => (
                                        <MenuItem key={exam.id} value={exam.id}>
                                          {exam.examName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Cutoff"
                                    value={item.cutoff}
                                    name="cutoff"
                                    error={examCutoffError}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;

                                      if (
                                        !isNaN(inputValue) ||
                                        inputValue === ""
                                      ) {
                                        onChangeGetCourseExamDetails(
                                          e,
                                          examIndex,
                                          courseIndex
                                        );
                                        setExamCutoffError(false);
                                      } else {
                                        setExamCutoffError(true);
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel id="select-college-course">
                                      cutoff Type
                                    </InputLabel>
                                    <Select
                                      labelId="select-college-course"
                                      id="select-college-course"
                                      name="cutoffType"
                                      value={item.cutoffType}
                                      label="Cutoff Type"
                                      onChange={(e) => {
                                        onChangeGetCourseExamDetails(
                                          e,
                                          examIndex,
                                          courseIndex
                                        );
                                      }}
                                    >
                                      <MenuItem value="Percentage">
                                        Percentage
                                      </MenuItem>
                                      <MenuItem value="Rank">Rank</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      background: "#405189",
                                      borderRadius: 5,
                                      border: 0,
                                      color: "white",
                                      height: 48,
                                      padding: "0 30px",
                                      boxShadow: "0 3px 5px 2px #405189",
                                      transition: "background 0.3s",
                                      "&:hover": {
                                        background: "#405189",
                                      },
                                    }}
                                    onClick={() => addExam(courseIndex)}
                                  >
                                    <AddIcon />
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  {course[courseIndex]
                                    .collegeCourseEntryCutoffList.length > 1 ? (
                                    <IconButton
                                      color="red"
                                      onClick={() =>
                                        removeExam(courseIndex, examIndex)
                                      }
                                    >
                                      <DeleteOutlineIcon fontSize="large" />
                                    </IconButton>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                          <TableRow sx={{ backgroundColor: "#dfe6e9" }}>
                            <TableCell>Scholarship</TableCell>
                            <TableCell>Cut Off Amount</TableCell>
                            <TableCell>Type of Cut off</TableCell>
                            <TableCell>Exam</TableCell>
                            <TableCell>Cut Off</TableCell>
                            <TableCell>Type of Cut off</TableCell>
                            <TableCell>Add</TableCell>
                            <TableCell>Remove</TableCell>
                          </TableRow>
                          {courseObj.collegeCourseScholarshipCutoffList?.map(
                            (item, scholarshipIndex) => (
                              <TableRow key={scholarshipIndex}>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel id="select-college-course">
                                      Scholarship
                                    </InputLabel>
                                    <Select
                                      labelId="select-college-course"
                                      id="select-college-course"
                                      name="scholarshipId"
                                      // error={courseNameError}
                                      // helperText={courseNameHelperText}
                                      value={item.scholarshipId} // Assuming `courseId` is the selected course ID
                                      label="Exam"
                                      onChange={(e) => {
                                        onChangeGetCourseScholarshipDetails(
                                          e,
                                          scholarshipIndex,
                                          courseIndex
                                        );
                                        // setCourseNameError(false);
                                        // setCourseNameHelperText("");
                                      }}
                                    >
                                      {scholorshipList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                          {item.scholarshipName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="scholarship Amt"
                                    name="scholarship"
                                    value={item.scholarship}
                                    error={scholarshipAmtError}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;

                                      if (
                                        !isNaN(inputValue) ||
                                        inputValue === ""
                                      ) {
                                        onChangeGetCourseScholarshipDetails(
                                          e,
                                          scholarshipIndex,
                                          courseIndex
                                        );
                                        setScholarshipAmtError(false);
                                      } else {
                                        setScholarshipAmtError(true);
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel id="select-college-course">
                                      Scholarship Type
                                    </InputLabel>
                                    <Select
                                      labelId="select-college-course"
                                      id="select-college-course"
                                      name="scholarshipType"
                                      // error={courseNameError}
                                      // helperText={courseNameHelperText}
                                      value={item.scholarshipType} // Assuming `courseId` is the selected course ID
                                      label="Scholarship Type"
                                      onChange={(e) => {
                                        onChangeGetCourseScholarshipDetails(
                                          e,
                                          scholarshipIndex,
                                          courseIndex
                                        );
                                        // setCourseNameError(false);
                                        // setCourseNameHelperText("");
                                      }}
                                    >
                                      <MenuItem value="Percenteage">
                                        Percenteage
                                      </MenuItem>
                                      <MenuItem value="Rank">Rank</MenuItem>
                                      <MenuItem value="Rupees">Rupees</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel id="select-college-course">
                                      Exam
                                    </InputLabel>
                                    <Select
                                      labelId="select-college-course"
                                      id="select-college-course"
                                      name="examId"
                                      // error={courseNameError}
                                      // helperText={courseNameHelperText}
                                      value={item.examId} // Assuming `courseId` is the selected course ID
                                      label="Exam"
                                      onChange={(e) => {
                                        onChangeGetCourseScholarshipDetails(
                                          e,
                                          scholarshipIndex,
                                          courseIndex
                                        );
                                        // setCourseNameError(false);
                                        // setCourseNameHelperText("");
                                      }}
                                    >
                                      {examList.map((scholarship) => (
                                        <MenuItem
                                          key={scholarship.id}
                                          value={scholarship.id}
                                        >
                                          {scholarship.examName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Cut off"
                                    name="cutoff"
                                    value={item.cutoff}
                                    onChange={(e) => {
                                      onChangeGetCourseScholarshipDetails(
                                        e,
                                        scholarshipIndex,
                                        courseIndex
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel id="select-college-course">
                                      cutoff Type
                                    </InputLabel>
                                    <Select
                                      labelId="select-college-course"
                                      id="select-college-course"
                                      name="cutoffType"
                                      value={item.cutoffType} // Assuming `courseId` is the selected course ID
                                      label="cutoff Type"
                                      onChange={(e) => {
                                        onChangeGetCourseScholarshipDetails(
                                          e,
                                          scholarshipIndex,
                                          courseIndex
                                        );
                                      }}
                                    >
                                      <MenuItem value="Percentage">
                                        Percentage
                                      </MenuItem>
                                      <MenuItem value="Rank">Rank</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      background: "#405189",
                                      borderRadius: 5,
                                      border: 0,
                                      color: "white",
                                      height: 48,
                                      padding: "0 30px",
                                      boxShadow: "0 3px 5px 2px #405189",
                                      transition: "background 0.3s",
                                      "&:hover": {
                                        background: "#405189",
                                      },
                                    }}
                                    onClick={() => addScholarship(courseIndex)}
                                  >
                                    <AddIcon />
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  {course[courseIndex]
                                    .collegeCourseScholarshipCutoffList.length >
                                  1 ? (
                                    <IconButton
                                      color="red"
                                      onClick={() =>
                                        removeScholarship(
                                          courseIndex,
                                          scholarshipIndex
                                        )
                                      }
                                    >
                                      <DeleteOutlineIcon fontSize="large" />
                                    </IconButton>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))}
                  {/* add row courses end  here  */}
                  {amenities.map((data, index) => (
                    <Grid container spacing={1} key={index} sx={{ my: 2 }}>
                      <Grid item md={3}>
                        <Autocomplete
                          fullWidth
                          options={amenitiesList?.map(
                            (item) => item.amenityName
                          )}
                          value={
                            amenitiesList?.find(
                              (item) => item.id === data.amenityId
                            )
                              ? amenitiesList?.find(
                                  (item) => item.id === data.amenityId
                                ).amenityName
                              : null
                          }
                          onChange={(event, newValue) => {
                            const selectedAmenity = amenitiesList?.find(
                              (item) => item.amenityName === newValue
                            );

                            if (selectedAmenity) {
                              const selectedAmenityId = selectedAmenity.id;

                              handleChange(
                                index,
                                "amenityId",
                                selectedAmenityId
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={amenityError}
                              helperText={amenityHelperText}
                              label="Select Amenity"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={7}>
                        <TextEditor
                          initialValue={data.amenityDescription}
                          onEditorChange={(value) =>
                            handleChange(index, "amenityDescription", value)
                          }
                          // rowIndex={index}
                        />
                      </Grid>
                      <Grid item md={1}>
                        <Button
                          variant="contained"
                          sx={{
                            background: "#405189",
                            borderRadius: 5,
                            border: 0,
                            color: "white",
                            height: 48,
                            padding: "0 20px",
                            boxShadow: "0 3px 5px 2px #405189",
                            transition: "background 0.3s",
                            "&:hover": {
                              background: "#405189",
                            },
                          }}
                          onClick={handleAddRow}
                        >
                          <AddIcon size="small" />
                        </Button>
                      </Grid>
                      {amenities.length > 1 && (
                        <Grid item md={1}>
                          <IconButton
                            color="red"
                            onClick={() => handleRemoveRow(index)}
                          >
                            <DeleteOutlineIcon fontSize="large" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                  <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item md={3}>
                      <Box
                        sx={{
                          border: collegeLogoError
                            ? "2px dashed red"
                            : "2px dashed #009688",
                          borderRadius: "15px",
                          padding: "20px",
                          backgroundColor: "#f5f5f5",
                          textAlign: "center",
                          cursor: "pointer",
                          transition: "border 0.3s",
                          ":hover": {
                            border: "2px dashed #005058",
                          },
                        }}
                      >
                        <label htmlFor="logo-pic-college">
                          <input
                            id="logo-pic-college"
                            type="file"
                            accept=".png, .jpg, .jpeg, .webp"
                            style={{ display: "none" }}
                            onChange={validateCollegeLogo}
                          />
                          {collegeLogo ? (
                            <img
                              src={previewCollegeLogo}
                              alt="College Logo"
                              style={{
                                width: 200,
                                height: 200,
                                objectFit: "cover",
                                borderRadius: "10px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              }}
                            />
                          ) : (
                            <>
                              <CloudUploadIcon
                                sx={{
                                  fontSize: 80,
                                  color: collegeLogoError
                                    ? "#d32f2f"
                                    : "#009688",
                                }}
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  color: collegeLogoError
                                    ? "#d32f2f"
                                    : "#009688",
                                  marginTop: "10px",
                                }}
                              >
                                {collegeLogoError
                                  ? collegeLogoHelperText
                                  : "Upload College Logo"}
                              </Typography>
                              <Typography
                                variant="bodyparagraph"
                                sx={{ color: "#757575" }}
                              >
                                {collegeLogomaxFileSizeErr || "Max size: 1MB"}
                              </Typography>
                            </>
                          )}
                        </label>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <Box
                        sx={{
                          border: bannerLogoError
                            ? "2px dashed #d32f2f"
                            : "2px dashed #009688",
                          borderRadius: "15px",
                          padding: "20px",
                          backgroundColor: "#f5f5f5",
                          textAlign: "center",
                          cursor: "pointer",
                          transition: "border 0.3s",
                          ":hover": {
                            border: "2px dashed #005058",
                          },
                          position: "relative", // Added for positioning the close icon
                        }}
                      >
                        <label htmlFor="banner-image">
                          <input
                            id="banner-image"
                            type="file"
                            accept=".png, .jpg, .jpeg, .webp"
                            style={{ display: "none" }}
                            onChange={validateBannerImage}
                          />
                          {bannerLogo ? (
                            <>
                              <img
                                src={previewBannerLogo}
                                alt="College Logo"
                                style={{
                                  width: 200,
                                  height: 200,
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                              />
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  backgroundColor: "#fff",
                                }}
                                onClick={() => {
                                  // Handle removal logic here
                                  setBannerLogo(null);
                                  setPreviewBannerLogo(null);
                                  setBannerPath(null);
                                }}
                              >
                                <CancelIcon
                                  sx={{ fontSize: 18, color: "#757575" }}
                                />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <CloudUploadIcon
                                sx={{
                                  fontSize: 80,
                                  color: bannerLogoError
                                    ? "#d32f2f"
                                    : "#009688",
                                }}
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  color: bannerLogoError
                                    ? "#d32f2f"
                                    : "#009688",
                                  marginTop: "10px",
                                }}
                              >
                                {bannerLogoError
                                  ? bannerLogoHelperText
                                  : "Upload Banner Image"}
                              </Typography>
                              <Typography
                                variant="bodyparagraph"
                                sx={{ color: "#757575" }}
                              >
                                {collegeLogomaxFileSizeErr || "Max size: 1MB"}
                              </Typography>
                            </>
                          )}
                        </label>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <Box
                        sx={{
                          border: broucherError
                            ? "2px dashed #d32f2f"
                            : "2px dashed #009688",
                          borderRadius: "15px",
                          padding: "20px",
                          backgroundColor: "#f5f5f5",
                          textAlign: "center",
                          cursor: "pointer",
                          transition: "border 0.3s",
                          ":hover": {
                            border: "2px dashed #005058",
                          },
                        }}
                      >
                        <label htmlFor="Broucher-File-Upload">
                          <input
                            id="Broucher-File-Upload"
                            type="file"
                            accept=".png, .jpg, .jpeg, .webp, .pdf"
                            style={{ display: "none" }}
                            onChange={validateBroucherImage}
                          />
                          {broucherLogo ? (
                            <img
                              src={previewBroucherLogo}
                              alt="Broucher File"
                              style={{
                                width: 200,
                                height: 200,
                                objectFit: "cover",
                                borderRadius: "10px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              }}
                            />
                          ) : (
                            <>
                              <CloudUploadIcon
                                sx={{
                                  fontSize: 80,
                                  color: broucherError ? "#d32f2f" : "#009688",
                                }}
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  color: broucherError ? "#d32f2f" : "#009688",
                                  marginTop: "10px",
                                }}
                              >
                                {broucherError
                                  ? broucherHelperText
                                  : "Upload Broucher"}
                              </Typography>
                              <Typography
                                variant="bodyparagraph"
                                sx={{ color: "#757575" }}
                              >
                                {collegeLogomaxFileSizeErr || "Max size: 2MB"}
                              </Typography>
                            </>
                          )}
                        </label>
                      </Box>
                    </Grid>
                    {/* gallery image input  */}
                    <Grid item md={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "24px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "16px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              color: "#0077B6",
                            }}
                          >
                            Image Gallery
                          </Typography>
                          <Typography
                            variant="bodyregular"
                            sx={{
                              fontSize: "16px",
                              textAlign: "center",
                              color: "#333",
                            }}
                          >
                            You can upload multiple images (max 15)
                          </Typography>
                        </Box>
                        {previewImages.length !== 15 && (
                          <label htmlFor="image-gallery">
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="image-gallery"
                              multiple
                              type="file"
                              onClick={(ev) => (ev.target.value = "")}
                              onChange={handleImageGalleryUpload}
                            />
                            <Button
                              variant="contained"
                              component="span"
                              sx={{
                                background: "#405189",
                                borderRadius: "24px", // Rounded corners
                                color: "white",
                                padding: "10px 30px",
                                boxShadow: "0 3px 5px 2px #405189",
                                transition: "background 0.3s",
                                "&:hover": {
                                  background: "#405189",
                                },
                              }}
                            >
                              {galleryImagesError
                                ? galleryImagesHelperText
                                : "Upload Images"}

                              {/* <AddAPhotoIcon sx={{ mr: 2 }} /> */}
                            </Button>
                          </label>
                        )}
                      </Box>
                    </Grid>
                    {/* gallery image preview  */}
                    <Grid item md={6}>
                      <Typography variant="h6">New Images</Typography>
                      <Box
                        sx={{ overflowX: "auto", display: "flex", height: 350 }}
                      >
                        <ImageList
                          cols={3}
                          sx={{
                            flexWrap: "nowrap",
                          }}
                        >
                          {previewImages?.map((item, idx) => (
                            <>
                              <ImageListItem
                                key={idx}
                                sx={{
                                  margin: "8px",
                                  cursor: "pointer",
                                  transition: "transform 0.3s",
                                  "&:hover": {
                                    transform: "scale(1.05)",
                                  },
                                }}
                              >
                                <img
                                  src={item}
                                  loading="lazy"
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  alt="gallery-img"
                                />
                                <ImageListItemBar
                                  sx={{
                                    background:
                                      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                                  }}
                                  position="top"
                                  actionPosition="right"
                                  actionIcon={
                                    <IconButton
                                      onClick={() => deleteImageGallery(idx)}
                                    >
                                      <ClearIcon
                                        sx={{ color: "white" }}
                                      ></ClearIcon>
                                    </IconButton>
                                  }
                                />
                              </ImageListItem>
                            </>
                          ))}
                        </ImageList>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="h6">Previous Images</Typography>
                      <Box sx={{ overflowX: "auto", display: "flex" }}>
                        <ImageList
                          cols={3}
                          sx={{
                            flexWrap: "nowrap",
                          }}
                        >
                          {previewPreviousImages?.map((items, index) => (
                            <>
                              <ImageListItem
                                key={index}
                                sx={{
                                  margin: "8px",
                                  cursor: "pointer",
                                  transition: "transform 0.3s",
                                  "&:hover": {
                                    transform: "scale(1.05)",
                                  },
                                }}
                              >
                                <img
                                  src={items}
                                  loading="lazy"
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  alt="gallery-img"
                                />
                                <ImageListItemBar
                                  sx={{
                                    background:
                                      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                                  }}
                                  position="top"
                                  actionPosition="right"
                                  actionIcon={
                                    <IconButton
                                      onClick={() =>
                                        deletePreviousImageGallery(index)
                                      }
                                    >
                                      <ClearIcon
                                        sx={{ color: "white" }}
                                      ></ClearIcon>
                                    </IconButton>
                                  }
                                />
                              </ImageListItem>
                            </>
                          ))}
                        </ImageList>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Box sx={{ width: "98.5%" }}>
                        <LoadScript
                          googleMapsApiKey={config.googleMapKey}
                          libraries={libraries}
                        >
                          <GoogleMap
                            ref={mapRef}
                            onLoad={(map) => {
                              mapRef.current = map;
                              if (navigator.geolocation) {
                                navigator.geolocation.getCurrentPosition(
                                  showPosition
                                );
                              }
                            }}
                            mapContainerStyle={{ height: "400px" }}
                            center={markerPosition}
                            zoom={17}
                            onDragEnd={mapCenterMoved}
                          >
                            <Marker
                              position={markerPosition}
                              draggable={true}
                              onDragEnd={onMarkerDragEnd}
                            />
                            <MapAutoComplete
                              onLoad={onAutoCompleteLoad}
                              onPlaceChanged={onPlaceChanged}
                            >
                              <input
                                type="text"
                                id="gmap-autocomplete"
                                placeholder="Search for your location"
                                style={{
                                  boxSizing: `border-box`,
                                  border: `1px solid transparent`,
                                  width: `240px`,
                                  height: `32px`,
                                  padding: `0 12px`,
                                  borderRadius: `3px`,
                                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                  fontSize: `14px`,
                                  outline: `none`,
                                  textOverflow: `ellipses`,
                                  position: "absolute",
                                  left: "50%",
                                  marginLeft: "-120px",
                                }}
                                onKeyDown={(ev) => {
                                  ev.key === "Enter" && ev.preventDefault();
                                }}
                              />
                            </MapAutoComplete>
                          </GoogleMap>
                        </LoadScript>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        fullWidth
                        freeSolo={true}
                        value={country}
                        options={addressList?.map((item) => item.countryName)}
                        onChange={(e, newValue) => setCountry(newValue)}
                        renderInput={(params) => (
                          <TextField
                            error={countryError}
                            helperText={countryHelperText}
                            {...params}
                            label="Country"
                            margin="dense"
                            variant="outlined"
                            onChange={(event, newValue) => {
                              if (!newValue) {
                                setCountry(event.target.value);
                                setCountryError(false);
                                setCountryHelperText("");
                              }
                            }}
                          />
                        )}
                      />
                      <Autocomplete
                        fullWidth
                        onChange={(e, newValue) => setState(newValue)}
                        value={state}
                        options={(
                          addressList?.find(
                            (item) => item.countryName === country
                          )?.states || []
                        )?.map((state) => state.stateName)}
                        freeSolo={true}
                        disabled={!country}
                        renderInput={(params) => (
                          <TextField
                            error={stateError}
                            helperText={stateHelperText}
                            {...params}
                            label="State"
                            margin="dense"
                            variant="outlined"
                            // value={state}
                            onChange={(event, newValue) => {
                              if (!newValue) {
                                setState(event.target.value);
                                setStateError(false);
                                setStateHelperText("");
                              }
                            }}
                          />
                        )}
                      />
                      <Autocomplete
                        fullWidth
                        error={districtError}
                        helperText={districtHelperText}
                        options={districtList}
                        value={
                          district || districtList.includes(district)
                            ? district
                            : ""
                        }
                        disabled={!state}
                        freeSolo
                        onChange={(e, newValue) => setDistrict(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              districtList.includes(district)
                                ? "District"
                                : "Please Select District(City)"
                            }
                            variant="outlined"
                            margin="dense"
                            onChange={(event, newValue) => {
                              if (!districtList.includes(newValue)) {
                                setDistrict(newValue);
                              }
                            }}
                          />
                        )}
                      />
                      <Autocomplete
                        fullWidth
                        value={
                          pincode || pincodeList.includes(pincode)
                            ? pincode
                            : ""
                        }
                        error={pincodeError}
                        helperText={pincodeHelperText}
                        options={pincodeList}
                        disabled={!district}
                        freeSolo
                        onChange={(e, newValue) => setPincode(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="dense"
                            label={
                              pincodeList.includes(pincode)
                                ? "Pincode"
                                : "Please Select Postal Code"
                            }
                            onChange={(event, newValue) => {
                              if (!pincodeList.includes(newValue)) {
                                setPincode(newValue);
                              }
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                      <TextField
                        type="text"
                        label="Enter Area Name"
                        onChange={(e) => setArea(e.target.value)}
                        value={area}
                        margin="dense"
                        error={areaError}
                        helperText={areaHelperText}
                        fullWidth
                      />
                      <TextField
                        label="Established Year"
                        value={collegeEstablishedYear}
                        onChange={(e) => {
                          const inputValueyear = e.target.value;
                          setCollegeEstablishedYear(inputValueyear);
                          setEstablishedYearError(false);
                          setEstablishedYearHelperText("");
                        }}
                        margin="dense"
                        error={establishedYearError}
                        helperText={establishedYearHelperText}
                        inputProps={{
                          maxLength: 4,
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: Boolean(collegeEstablishedYear), // Set shrink to true if there is a value
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item md={6}>
                      <Grid item md={8}>
                        <Autocomplete
                          multiple
                          id="scholarship-autocomplete"
                          options={scholorshipList}
                          getOptionLabel={(option) => option.scholarshipName}
                          value={scholorshipList?.filter((company) =>
                            scholarShipId?.includes(company.id.toString())
                          )}
                          onChange={handleScholarshipChange}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Scholarship" />
                          )}
                          renderTags={(value, getTagProps) =>
                            value?.map((option, index) => (
                              <Chip
                                key={index}
                                label={option.scholarshipName}
                                {...getTagProps({ index })}
                                onDelete={() => handleRemoveScholarship(option)}
                              />
                            ))
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        type="text"
                        value={scholorShipCutOff}
                        label="ScholorShip Cut Off"
                        onChange={(e) => setScholorShipCutOff(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        fullWidth
                        value={
                          defaultInstituteBrand
                            ? defaultInstituteBrand.brandName
                            : null
                        }
                        options={instituteBrand?.map((item) => item.brandName)}
                        onChange={(event, newValue) => {
                          const selectedBrandName = instituteBrand?.find(
                            (item) => item.brandName === newValue
                          );

                          if (selectedBrandName) {
                            const selectedBrandNameId = selectedBrandName.id;
                            setInstituteBrandId(selectedBrandNameId);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={instituteBrandError}
                            helperText={instituteBrandHelperText}
                            {...params}
                            label="Select Institute Brand"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Autocomplete
                        multiple
                        id="placement-companies-autocomplete"
                        options={placementCompaniesList}
                        getOptionLabel={(option) => option.companyName}
                        value={placementCompaniesList.filter((company) =>
                          placementCompanyId.includes(company.id.toString())
                        )}
                        onChange={handlePlacementChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Placement Companies"
                            error={placementCompanyError}
                            helperText={placementCompanyHelperText}
                          />
                        )}
                        renderTags={(value, getTagProps) =>
                          value?.map((option, index) => (
                            <Chip
                              key={index}
                              label={option.companyName}
                              {...getTagProps({ index })}
                              onDelete={() => handleRemovepc(option)}
                            />
                          ))
                        }
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        type="text"
                        value={emails}
                        autoComplete="off"
                        onChange={(e) => setEmails(e.target.value)}
                        label="Emails"
                        placeholder="Enter Emails comma Separate Each Email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        type="url"
                        value={website}
                        autoComplete="off"
                        onChange={(e) => setWebsite(e.target.value)}
                        label="Website"
                        placeholder="Enter College Website URL"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        type="text"
                        value={contactNumbers}
                        label="Contact Numbers"
                        autoComplete="off"
                        onChange={(e) => setContactNumbers(e.target.value)}
                        placeholder="Enter Contact Numbers comma Separate Each Number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        type="text"
                        value={trustName}
                        label="Trust Name"
                        autoComplete="off"
                        onChange={(e) => setTrustName(e.target.value)}
                        placeholder="Enter College Trust Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          College Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={collegeCategory}
                          label="College Category"
                          onChange={(e) => setCollegeCategory(e.target.value)}
                        >
                          <MenuItem value="co-ed">co-ed</MenuItem>
                          <MenuItem value="Boys">Boys</MenuItem>
                          <MenuItem value="Girls">Girls</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        type="file"
                        accept="video/*"
                        multiple={true}
                        ref={fileInputRef}
                        style={{ display: "none" }} // hide the default file input
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        onClick={handleClick}
                      >
                        Upload Video
                      </Button>
                      <Typography variant="body2" color="textSecondary">
                        Select one or multiple video files.
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>New Videos</Typography>
                      <Box sx={{ display: "flex", overflowX: "auto" }}>
                        {videoFiles.map((file, index) => (
                          <Box key={index} style={{ margin: "0 5px" }}>
                            <video width="300" height="300" controls>
                              <source
                                src={URL.createObjectURL(file)}
                                type="video/mp4"
                              />
                            </video>
                            <br />
                            <button
                              type="button"
                              onClick={() => handleRemoveFile(index)}
                            >
                              Remove
                            </button>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>Previous Videos</Typography>
                      <Box sx={{ display: "flex", overflowX: "auto" }}>
                        {videoUrl.map((file, index) => (
                          <Box key={index} style={{ margin: "0 5px" }}>
                            <video width="300" height="300" controls>
                              <source
                                src={file.mediaFilePath}
                                type="video/mp4"
                              />
                            </video>
                            <br />
                            <button
                              type="button"
                              onClick={() => handleRemovePreviousFile(index)}
                            >
                              Remove
                            </button>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        type="text"
                        value={inputValue}
                        label="YouTube Video URL"
                        variant="outlined"
                        error={youtubeVideoError}
                        helperText={youtubeVideoHelperText}
                        fullWidth
                        onChange={(e) => {
                          setInputValue(e.target.value);
                          setYoutubeVideoError(false);
                          setYoutubeVideoHelperText("");
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Button variant="contained" onClick={handleAddVideo}>
                        Add Video
                      </Button>
                    </Grid>
                    <Grid item md={6}>
                      <List>
                        {videoLinks.map((video, index) => (
                          <ListItem key={index}>
                            <ListItemText primary={video.mediaFilePath} />
                            <IconButton
                              onClick={() => handleRemoveVideo(index)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Admission Summary
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {summaryImage ? (
                            <>
                              <input
                                type="text"
                                value={summaryImageUrl}
                                sx={{ width: "100px" }}
                                disabled
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    summaryImageUrl
                                  );
                                  toast.success("Copied", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSummaryImageUrl(null);
                                  setSummaryImage(null);
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                style={{ cursor: "pointer" }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                              <TextField
                                id="file-upload"
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                onChange={validateSummaryImageUpload}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>

                      <TextEditor
                        initialValue={admissionSummary}
                        onEditorChange={setAdmissionSummary}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Placement Company Summary
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {summaryImage ? (
                            <>
                              <input
                                type="text"
                                value={summaryImageUrl}
                                sx={{ width: "100px" }}
                                disabled
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    summaryImageUrl
                                  );
                                  toast.success("Copied", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSummaryImageUrl(null);
                                  setSummaryImage(null);
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                style={{ cursor: "pointer" }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                              <TextField
                                id="file-upload"
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                onChange={validateSummaryImageUpload}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <TextEditor
                        initialValue={placementCompanySummary}
                        onEditorChange={setPlacementCompanySummary}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Cut Off Summary
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {summaryImage ? (
                            <>
                              <input
                                type="text"
                                value={summaryImageUrl}
                                sx={{ width: "100px" }}
                                disabled
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    summaryImageUrl
                                  );
                                  toast.success("Copied", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSummaryImageUrl(null);
                                  setSummaryImage(null);
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                style={{ cursor: "pointer" }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                              <TextField
                                id="file-upload"
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                onChange={validateSummaryImageUpload}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <TextEditor
                        initialValue={cutOffSummary}
                        onEditorChange={setCutOffSummary}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Scholarship Summary
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {summaryImage ? (
                            <>
                              <input
                                type="text"
                                value={summaryImageUrl}
                                sx={{ width: "100px" }}
                                disabled
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    summaryImageUrl
                                  );
                                  toast.success("Copied", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSummaryImageUrl(null);
                                  setSummaryImage(null);
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                style={{ cursor: "pointer" }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                              <TextField
                                id="file-upload"
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                onChange={validateSummaryImageUpload}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <TextEditor
                        initialValue={scholarshipSummar}
                        onEditorChange={setScholarshipSummary}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Course Fee Summary
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {summaryImage ? (
                            <>
                              <input
                                type="text"
                                value={summaryImageUrl}
                                sx={{ width: "100px" }}
                                disabled
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    summaryImageUrl
                                  );
                                  toast.success("Copied", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSummaryImageUrl(null);
                                  setSummaryImage(null);
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                style={{ cursor: "pointer" }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                              <TextField
                                id="file-upload"
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                onChange={validateSummaryImageUpload}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <TextEditor
                        initialValue={courseFeeSummary}
                        onEditorChange={setCourseFeeSummary}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Result Summary
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {summaryImage ? (
                            <>
                              <input
                                type="text"
                                value={summaryImageUrl}
                                sx={{ width: "100px" }}
                                disabled
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    summaryImageUrl
                                  );
                                  toast.success("Copied", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSummaryImageUrl(null);
                                  setSummaryImage(null);
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                style={{ cursor: "pointer" }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                              <TextField
                                id="file-upload"
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                onChange={validateSummaryImageUpload}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <TextEditor
                        initialValue={resultSummary}
                        onEditorChange={setResultSummary}
                      />
                    </Grid>

                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Q & A Summary
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {summaryImage ? (
                            <>
                              <input
                                type="text"
                                value={summaryImageUrl}
                                sx={{ width: "100px" }}
                                disabled
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    summaryImageUrl
                                  );
                                  toast.success("Copied", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSummaryImageUrl(null);
                                  setSummaryImage(null);
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                style={{ cursor: "pointer" }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                              <TextField
                                id="file-upload"
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                onChange={validateSummaryImageUpload}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <TextEditor
                        initialValue={questionAnswerSummary}
                        onEditorChange={setQuestionAnswerSummary}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Enter College Description
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {summaryImage ? (
                            <>
                              <input
                                type="text"
                                value={summaryImageUrl}
                                sx={{ width: "100px" }}
                                disabled
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    summaryImageUrl
                                  );
                                  toast.success("Copied", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSummaryImageUrl(null);
                                  setSummaryImage(null);
                                }}
                              >
                                <RestartAltIcon />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                style={{ cursor: "pointer" }}
                              >
                                <Button
                                  variant="contained"
                                  component="span"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                              <TextField
                                id="file-upload"
                                type="file"
                                accept=".png, .jpg, .jpeg, .webp"
                                onChange={validateSummaryImageUpload}
                                style={{ display: "none" }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <TextEditor
                        initialValue={collegeDescription}
                        onEditorChange={setCollegeDescription}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="meta-description-static"
                        label="Enter Meta Description"
                        onChange={handleMetaDescriptionChange}
                        value={metaDescription ?? ""}
                        error={metaDescriptionError}
                        helperText={metaDescriptionHelperText}
                        multiline
                        rows={5} // Adjust the number of rows as needed
                        variant="outlined"
                        fullWidth
                      />
                      <Typography variant="caption" color="#bdc3c7">
                        Character Count: {metaDescription?.length} /{" "}
                        {maxCharacters}
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <TextField
                        id="schema-script-static"
                        label="Enter Schema Script"
                        onChange={(e) => setSchemaScript(e.target.value)}
                        value={schemaScript || ""}
                        multiline
                        rows={5} // Adjust the number of rows as needed
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="keywords-multiline-static"
                        label="Enter KeyWords"
                        onChange={(e) => setKeyWords(e.target.value)}
                        value={keyWords || ""}
                        multiline
                        rows={5} // Adjust the number of rows as needed
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={12} sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        type="submit"
                        // disabled={isFileUploadFetching}
                        sx={{
                          background: "#405189",
                          borderRadius: 5,
                          border: 0,
                          color: "white",
                          height: 48,
                          padding: "0 30px",

                          boxShadow: "0 3px 5px 2px #405189",
                          "&:hover": {
                            //you want this to be the same as the backgroundColor above
                            backgroundColor: "#405189",
                          },
                        }}
                        disabled={updateLoading || fetching}
                      >
                        {fetching ? "Loading Data..." : "Save Changes"}
                        {updateLoading && <CircularProgress size={20} />}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            </Container>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh", // Adjust the height as needed
              }}
            >
              <CircularProgress size={40} />
            </Box>
          )}
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Remove Video</DialogTitle>
        <DialogContent>
          Are you sure you want to remove this video?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleCloseDialog} color="error">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CollegeEdit;
