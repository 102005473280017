import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  IconButton,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import SearchIcon from "@mui/icons-material/Search";
import { apiList, invokeApi } from "../../../services/apiServices";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import { LoadingButton } from "@mui/lab";

const ProgramList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;

  const [programList, setProgramList] = useState([]);
  const [programFilter, setProgramFilter] = useState([]);
  const [instituteTypeList, setInstituteTypeList] = useState([]);

  const [instituteTypeId, setInstituteTypeId] = useState(null);
  const [instituteTypeIdError, setInstituteTypeIdError] = useState(false);
  const [instituteTypeIdHelperText, setInstituteTypeIdHelperText] =
    useState("");

  const [search, setSearch] = useState("");

  const [isProgramFetching, setIsProgramFetching] = useState(true);

  const [programName, setProgramName] = useState("");
  const [programNameHelperText, setPrgoramNameHelperText] = useState("");
  const [programNameError, setProgramNameError] = useState(false);

  const [isAddProgramDialogOpen, setIsAddProgramDialogOpen] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [id, setId] = useState("");

  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const openEditProgram = () => {
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  const openAddProgram = () => {
    setIsAddProgramDialogOpen(!isAddProgramDialogOpen);
  };

  const handleDeleteModal = () => {
    setdeleteConfirmation(!deleteConfirmation);
  };

  const clearAllCache = () => {
    setSaveLoading(false);
    setUpdateLoading(false);
    setProgramName("");
    setProgramNameError(false);
    setInstituteTypeIdError(false);
    setInstituteTypeIdHelperText("");
    setPrgoramNameHelperText("");
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const validateProgram = () => {
    let validationErrors = false;
    if (!programName) {
      setPrgoramNameHelperText("Please enter the course");
      setProgramNameError(true);
      setSaveLoading(false);
      setUpdateLoading(false);
      validationErrors = true;
    }
    if (!instituteTypeId) {
      setInstituteTypeIdError(true);
      setInstituteTypeIdHelperText("Please Select Institute Type");
      setSaveLoading(false);
      setUpdateLoading(false);
      validationErrors = true;
    }
    if (!validationErrors) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getProgram = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setProgramList(response.data.programs);
          setProgramFilter(response.data.programs);
          setIsProgramFetching(false);
        } else {
          alert(
            "Something went wrong while getting Programs Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting Programs Details. Please try again later!!"
        );
      }
    };
    const getInstituteType = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getInsituteType,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setInstituteTypeList(response.data.instituteTypes);
        } else {
          alert(
            "Something went wrong while getting Instiute Type Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting Instiute Type Details. Please try again later!!"
        );
      }
    };
    if (isProgramFetching) {
      getProgram();
      getInstituteType();
    }
  }, [cookies, isProgramFetching]);

  const saveProgram = async () => {
    let validate = await validateProgram();
    if (validate) {
      let params = {
        programName: programName,
        instituteTypeId: instituteTypeId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveProgram,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Program Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          openAddProgram();
          clearAllCache();
          setIsProgramFetching(true);
        } else if (response.data.responseCode === "GM003") {
          openAddProgram(false);
          toast.info("Program Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          clearAllCache();
        } else {
          alert(
            "Something went wrong while saving the program Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while saving the program Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  const updateProgram = async () => {
    var validationError = await validateProgram();
    if (validationError) {
      let params = {
        id: id,
        programName: programName,
        instituteTypeId: instituteTypeId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateProgram,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Program Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          openEditProgram();
          clearAllCache();
          setIsProgramFetching(true);
        } else if (response.data.responseCode === "GM003") {
          openEditProgram(false);
          toast.info("Program Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          clearAllCache();
        } else {
          alert(
            "Something went wrong while Edit program Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Edit program Details. Please try again later!!"
        );
      }
    }
  };

  const deleteProgram = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateProgram,
      params,
      cookies
    );
    if (response?.status >= 200 && response?.status < 300) {
      if (response.data.responseCode === "200") {
        handleDeleteModal();
        toast.success("Program Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsProgramFetching(true);
      } else if (response.data.responseCode === "GM002") {
        handleDeleteModal();
        toast.info(
          "This Program Mapped to Some College You Can't Delete This Program!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        alert(
          "Something went wrong while delete program Details. Please try again later!"
        );
      }
    } else if (response?.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete program Details. Please try again later!!"
      );
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      width: "15%",
    },
    {
      name: "Program Name",
      selector: (row) => row.programName,
      sortable: true,
    },
    {
      name: "Institute Type",
      selector: (row) => row.instituteType,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setId(row.id);
                  setProgramName(row.programName);
                  setInstituteTypeId(row.instituteTypeId);
                  setIsEditDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setProgramName(row.programName);
                  setInstituteTypeId(row.instituteTypeId);
                  setIsEditDialogOpen(true);
                  setId(row.id);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setId(row.id);
                  setdeleteConfirmation(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = programList.filter((item) => {
      return item.programName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setProgramFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CollegeMetadataTabs tabActive={1}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={() => setIsAddProgramDialogOpen(true)}
        >
          <AddIcon />
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={programFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isProgramFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      {/* add program  */}
      <Dialog
        open={isAddProgramDialogOpen}
        onClose={() => {
          openAddProgram();
          clearAllCache();
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            openAddProgram();
            clearAllCache();
          }}
        />
        <DialogTitle>Add New Program</DialogTitle>
        <DialogContent>
          <Autocomplete
            fullWidth
            options={instituteTypeList.map((item) => item.instituteType)}
            onChange={(event, newValue) => {
              const selectedInstituteType = instituteTypeList.find(
                (item) => item.instituteType === newValue
              );

              if (selectedInstituteType) {
                const selectedInstituteTypeId = selectedInstituteType.id;
                setInstituteTypeId(selectedInstituteTypeId);
              }
              setInstituteTypeIdError(false);
              setInstituteTypeIdHelperText("");
            }}
            renderInput={(params) => (
              <TextField
                error={instituteTypeIdError}
                helperText={instituteTypeIdHelperText}
                {...params}
                label="Select Institute Type"
                variant="outlined"
              />
            )}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Program Name *"
            name="name"
            value={programName}
            onChange={(ev) => {
              setProgramName(ev.target.value);
              setProgramNameError(false);
              setPrgoramNameHelperText("");
            }}
            helperText={programNameHelperText}
            error={programNameError}
          />

          <LoadingButton
            loading={saveLoading}
            onClick={() => {
              setSaveLoading(true);
              saveProgram();
            }}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </DialogContent>
      </Dialog>
      {/* edit program  */}
      <Dialog
        open={isEditDialogOpen}
        onClose={() => {
          openEditProgram();
          clearAllCache();
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            openEditProgram();
            clearAllCache();
          }}
        />
        <DialogTitle>Edit Program</DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            fullWidth
            options={instituteTypeList.map((item) => item.instituteType)}
            value={
              instituteTypeList.find((item) => item.id === instituteTypeId)
                ?.instituteType
            }
            onChange={(event, newValue) => {
              const selectedInstituteType = instituteTypeList.find(
                (item) => item.instituteType === newValue
              );

              if (selectedInstituteType) {
                const selectedInstituteTypeId = selectedInstituteType.id;
                setInstituteTypeId(selectedInstituteTypeId);
              }
              setInstituteTypeIdError(false);
              setInstituteTypeIdHelperText("");
            }}
            renderInput={(params) => (
              <TextField
                error={instituteTypeIdError}
                helperText={instituteTypeIdHelperText}
                {...params}
                label="Select Institute Type"
                variant="outlined"
              />
            )}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Program Name *"
            name="name"
            value={programName}
            helperText={programNameHelperText}
            error={programNameError}
            onChange={(ev) => {
              setProgramName(ev.target.value);
              setPrgoramNameHelperText("");
              setProgramNameError(false);
            }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={updateLoading}
            onClick={() => {
              setUpdateLoading(true);
              updateProgram();
            }}
            variant="contained"
          >
            update
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <DeleteModal
        DeleteFunction={deleteProgram}
        handleIsDeleteModal={handleDeleteModal}
        Open={deleteConfirmation}
      />
    </>
  );
};

export default ProgramList;
