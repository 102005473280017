import React, { useEffect, useRef, useState } from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SideBar from "./SideBar";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { config } from "../../../../config/config";

const Info = () => {
  const boxRef = useRef();
  const mapRef = useRef();
  const libraries = ["places"];
  const [college, setCollege] = useState([]);
  const [showLess, setShowLess] = useState(true);

  let markerPosition = {
    lat: college.latitude,
    lng: college.longitude,
  };

  const handleIsOpen = () => {
    if (boxRef.current) {
      boxRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setShowLess((lessMore) => !lessMore);
  };

  return (
    <>
      <CollegeDetailHeader activeTab={0} transferCollegeData={setCollege}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Paper
              sx={{
                p: 3,
              }}
            >
              <Box
                color={"#282828"}
                ref={boxRef}
                sx={{ height: showLess ? "auto" : "200px", overflow: "hidden" }}
              >
                {college.collegeFullDescription ? (
                  <>
                    <Box
                      sx={{
                        height: "45px",
                        width: "auto",
                        backgroundColor: "#F2F2F2",
                        padding: "5px",
                      }}
                    >
                      <Typography
                        display="flex"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ fontSize: { md: "18px", xs: "14px" } }}
                      >
                        School of Creative-Art, Design and Media Studies
                      </Typography>
                    </Box>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: college.collegeFullDescription,
                      }}
                      sx={{ py: 2 }}
                    />
                  </>
                ) : null}
                <Box></Box>
                {college.collegeCourses && college.collegeCourses.length > 0 ? (
                  <>
                    <Box
                      sx={{
                        height: "45px",
                        width: "auto",
                        backgroundColor: "#F2F2F2",
                        padding: "5px",
                      }}
                    >
                      <Typography
                        display="flex"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ fontSize: { md: "18px", xs: "14px" } }}
                      >
                        School of Creative-Art, Design and Media Studies
                      </Typography>
                    </Box>
                    <Table
                      sx={{
                        width: "100%",
                        overflowX: "auto", // Enable horizontal scrolling on small screens
                        border: "1px solid #ddd",
                        my: 2,
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#162E7C" }}>
                          <TableCell sx={{ color: "#fff" }}>Course</TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            Specialization
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {college.collegeCourses?.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                borderBottom: "1px solid #ddd",
                                borderRight: "1px solid #ddd",
                                fontSize: "16px",
                                "@media (max-width:700px)": {
                                  fontSize: "14px", // Adjust font size for small screens
                                  width: "100%", // Adjust width to take the full screen
                                },
                              }}
                            >
                              {row.courseName}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "1px solid #ddd",
                                width: "300px",
                                fontSize: "16px",
                                "@media (max-width:700px)": {
                                  fontSize: "14px", // Adjust font size for small screens
                                  width: "100%", // Adjust width to take the full screen
                                },
                              }}
                            >
                              {row.courseSpecializationName}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {college.collegeCourses?.map((item) => (
                      <Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            marginBottom: "10px",
                            fontSize: { xs: "14px", md: "18px" },
                            fontWeight: "600",
                          }}
                        >
                          {item.courseName} Specialization:
                        </Typography>
                        <Typography
                          paddingLeft={"30px"}
                          sx={{ fontSize: { xs: "14px", md: "18px" } }}
                          height="6"
                        >
                          <ul sx={{ paddingLeft: "20px" }}>
                            <li>{item.courseSpecializationName}</li>
                          </ul>
                        </Typography>
                      </Typography>
                    ))}
                  </>
                ) : null}
                <br />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "60px",
                    background: showLess
                      ? null
                      : "linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.34))",
                    left: "0",
                    bottom: "40px",
                  }}
                ></Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleIsOpen();
                  }}
                >
                  {showLess ? "Show Less" : "Show More"}
                </Button>
              </Box>
            </Paper>
          </Grid>
          <SideBar />
          <Grid item xs={12}>
            <LoadScript
              googleMapsApiKey={config.googleMapKey}
              libraries={libraries}
            >
              <GoogleMap
                ref={mapRef}
                mapContainerStyle={{ height: "400px" }}
                center={markerPosition}
                zoom={17}
              >
                <Marker position={markerPosition} draggable={false} />
              </GoogleMap>
            </LoadScript>
          </Grid>
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default Info;
