import {
  Button,
  IconButton,
  Box,
  Divider,
  Modal,
  Typography,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../colleges/DeleteModal";
import CollegeMetadataTabs from "../colleges/CollegeMetadataTabs";

const TrainingInstituteProgram = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;
  // add training institute program modal state
  const [addModal, setAddModal] = useState(false);
  // edit training institute program modal state
  const [editModal, setEditModal] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [search, setSearch] = useState("");
  // training institute program name state
  const [trainingInstituteProgramName, setTrainingInstituteProgramName] =
    useState("");
  // textfiled error condition state
  const [trainingInstituteProgramError, setTrainingInstituteProgramError] =
    useState(false);
  // error message for textfiled state
  const [
    trainingInstituteProgramHelperText,
    setTrainingInstituteProgramHelperText,
  ] = useState("");
  // add training institute program save button loading state
  const [saveLoading, setSaveLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  // data fetching depends on this state if true call the fetch function
  const [
    isTrainingInstituteProgramFetching,
    setIsTrainingInstituteProgramFetching,
  ] = useState(true);
  // edit save id state
  const [id, setId] = useState("");
  // fetch data set to this state
  const [trainingInstituteProgram, setTrainingInstituteProgram] = useState([]);
  const [trainingInstituteProgramFilter, setTrainingInstituteProgramFilter] =
    useState([]);

  const handleIsAddModal = () => {
    setAddModal(!addModal);
  };
  const handleIsEditModal = () => {
    setEditModal(!editModal);
  };
  const handleIsDeleteModal = () => {
    setDeleteConfirmation(!deleteConfirmation);
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  useEffect(() => {
    const getAllTrainingInstituteProgram = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getTrainingInstitutePrograms,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setTrainingInstituteProgram(response.data.trainingInstitutePrograms);
          setTrainingInstituteProgramFilter(
            response.data.trainingInstitutePrograms
          );
          setTrainingInstituteProgramError(false);
          setIsTrainingInstituteProgramFetching(false);
        } else {
          alert(
            "Something went wrong while getting the training institute program Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the training institute program Details. Please try again later!!"
        );
      }
    };
    if (isTrainingInstituteProgramFetching) {
      getAllTrainingInstituteProgram();
    }
  }, [cookies, isTrainingInstituteProgramFetching]);

  const saveTrainingInstituteProgram = async () => {
    var validation = true;
    if (!trainingInstituteProgramName) {
      setSaveLoading(false);
      setTrainingInstituteProgramError(true);
      setTrainingInstituteProgramHelperText("Enter Training Institute Program");
      validation = false;
    }
    if (validation) {
      let params = {
        trainingInstituteProgram: trainingInstituteProgramName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveTrainingInstituteProgram,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Training Institute Program Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSaveLoading(false);
          handleIsAddModal();
          setIsTrainingInstituteProgramFetching(true);
          setTrainingInstituteProgramError(false);
          setTrainingInstituteProgramHelperText("");
        } else if (response.data.responseCode === "GM003") {
          handleIsAddModal(false);
          toast.info("Training Institute program Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSaveLoading(false);
          setTrainingInstituteProgramError(false);
          setTrainingInstituteProgramHelperText("");
          setTrainingInstituteProgramName("");
        } else {
          alert(
            "Something went wrong while saving the Training Institute Program Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while saving the Training Institute Program Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  const deleteTrainingInstituteProgram = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateTrainingInstituteProgram,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Training Institute Program Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsTrainingInstituteProgramFetching(true);
        handleIsDeleteModal();
      } else if (response.data.responseCode === "GM002") {
        handleIsDeleteModal();
        toast.info(
          "This training Institute Program Mapped to Some College You Can't Delete This Training Institute Program!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        alert(
          "Something went wrong while delete course Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete course Details. Please try again later!!"
      );
    }
  };

  //edit training institute program
  const updateTrainingInstituteProgram = async () => {
    var validation = true;
    if (!trainingInstituteProgramName) {
      setUpdateLoading(false);
      setTrainingInstituteProgramError(true);
      setTrainingInstituteProgramHelperText("Enter Training Institute Program");
      validation = false;
    }
    if (validation) {
      let params = {
        id: id,
        trainingInstituteProgram: trainingInstituteProgramName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateTrainingInstituteProgram,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Training Institute Program Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          handleIsEditModal();
          setUpdateLoading(false);
          setTrainingInstituteProgramError(false);
          setTrainingInstituteProgramHelperText("");
          setTrainingInstituteProgramName("");
          setIsTrainingInstituteProgramFetching(true);
        } else if (response.data.responseCode === "GM003") {
          handleIsEditModal(false);
          toast.info("Training Institute Program Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateLoading(false);
          setTrainingInstituteProgramError(false);
          setTrainingInstituteProgramHelperText("");
          setTrainingInstituteProgramName("");
        } else {
          alert(
            "Something went wrong while Edit course Details. Please try again later!"
          );
          setUpdateLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Edit course Details. Please try again later!!"
        );
        setUpdateLoading(false);
      }
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Training Institute Program Name",
      selector: (row) => row.trainingInstituteProgram,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  handleIsEditModal();
                  setTrainingInstituteProgramName(row.trainingInstituteProgram);
                  setId(row.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  handleIsEditModal();
                  setTrainingInstituteProgramName(row.trainingInstituteProgram);
                  setId(row.id);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  handleIsDeleteModal();
                  setId(row.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = trainingInstituteProgram.filter((item) => {
      return item.trainingInstituteProgram
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    });
    setTrainingInstituteProgramFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CollegeMetadataTabs tabActive={10}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={() => {
            handleIsAddModal();
            setSaveLoading(false);
          }}
        >
          <AddIcon></AddIcon>
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={trainingInstituteProgramFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isTrainingInstituteProgramFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      {/* delete confirmation modal  */}
      <DeleteModal
        handleIsDeleteModal={handleIsDeleteModal}
        Open={deleteConfirmation}
        DeleteFunction={deleteTrainingInstituteProgram}
      />
      {/* edit modal  */}
      <Modal
        open={editModal}
        onClose={() => {
          handleIsEditModal();
          setTrainingInstituteProgramName("");
          setTrainingInstituteProgramError(false);
          setUpdateLoading(false);
          setTrainingInstituteProgramHelperText("");
        }}
        sx={{
          backdropFilter: editModal ? "blur(1px)" : "none",
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsEditModal();
                setTrainingInstituteProgramName("");
                setUpdateLoading(false);
                setTrainingInstituteProgramError(false);
                setTrainingInstituteProgramHelperText("");
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="medium" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              gap: "10px",
            }}
          >
            <TextField
              id="training-Institute-program-basic"
              label="Training Institute Program *"
              variant="outlined"
              type="text"
              autoComplete="off"
              sx={{ my: 2 }}
              value={trainingInstituteProgramName}
              onChange={(e) => {
                setTrainingInstituteProgramName(e.target.value);
                setTrainingInstituteProgramError(false);
                setTrainingInstituteProgramHelperText("");
              }}
              error={trainingInstituteProgramError}
              helperText={trainingInstituteProgramHelperText}
              fullWidth
            />
            <Button
              variant="contained"
              type="submit"
              onClick={() => {
                setUpdateLoading(true);
                updateTrainingInstituteProgram();
              }}
              disabled={updateLoading}
            >
              Update
              {updateLoading && <CircularProgress size={20} />}
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* training institute program list modal  */}
      <Modal
        open={addModal}
        onClose={() => {
          handleIsAddModal();
          setTrainingInstituteProgramName("");
          setTrainingInstituteProgramError(false);
          setTrainingInstituteProgramHelperText("");
        }}
        sx={{
          backdropFilter: addModal ? "blur(1px)" : "none",
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsAddModal();
                setTrainingInstituteProgramError(false);
                setTrainingInstituteProgramHelperText("");
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="medium" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              gap: "10px",
            }}
          >
            <TextField
              id="training-Institute-program-basic"
              label="Training Institute Program *"
              variant="outlined"
              autoComplete="off"
              sx={{ my: 2 }}
              onChange={(e) => {
                setTrainingInstituteProgramName(e.target.value);
                setTrainingInstituteProgramError(false);
                setTrainingInstituteProgramHelperText("");
              }}
              error={trainingInstituteProgramError}
              helperText={trainingInstituteProgramHelperText}
              fullWidth
            />
            <Button
              variant="contained"
              type="submit"
              onClick={() => {
                setSaveLoading(true);
                saveTrainingInstituteProgram();
              }}
              disabled={saveLoading}
            >
              Save
              {saveLoading && <CircularProgress size={20} />}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TrainingInstituteProgram;
