import React from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import {
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SideBar from "./SideBar";

const Gallery = () => {
  const [college, setCollege] = useState([]);

  const imageMedia =
    college.collegeMediaFiles?.filter((item) => item.mediaType === "Image") ||
    [];
  const videoMedia =
    college.collegeMediaFiles?.filter((item) => item.mediaType === "video") ||
    [];
  const youtubeMedia =
    college.collegeMediaFiles?.filter((item) => item.mediaType === "youtube") ||
    [];

  return (
    <>
      <CollegeDetailHeader activeTab={6} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <Paper sx={{ p: 3 }}>
              {imageMedia.length > 0 ? (
                <>
                  <Typography
                    sx={{
                      backgroundColor: "#F2F2F2",
                      p: 1,
                      fontWeight: "bold",
                      color: "#000",
                      my: 2,
                    }}
                    variant="subtitle1"
                  >
                    {college.collegeName} Images
                  </Typography>
                  <ImageList
                    sx={{ width: "100%", height: 450 }}
                    cols={3}
                    rowHeight={164}
                  >
                    {imageMedia.map((ImagePath) => (
                      <ImageListItem>
                        <img
                          srcSet={ImagePath.mediaFilePath}
                          src={ImagePath.mediaFilePath}
                          alt={ImagePath.collegeName}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              ) : null}
              {videoMedia.length > 0 ? (
                <>
                  <Typography
                    sx={{
                      backgroundColor: "#F2F2F2",
                      p: 1,
                      fontWeight: "bold",
                      color: "#000",
                      my: 2,
                    }}
                    variant="subtitle1"
                  >
                    {college.collegeName} Videos
                  </Typography>
                  <ImageList
                    sx={{ width: "100%", height: 450 }}
                    cols={3}
                    rowHeight={164}
                  >
                    {videoMedia.map((item) => (
                      <ImageListItem>
                        <video width="300" height="300" controls>
                          <source src={item.mediaFilePath} type="video/mp4" />
                        </video>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              ) : null}
              {youtubeMedia.length > 0 ? (
                <>
                  <Typography
                    sx={{
                      backgroundColor: "#F2F2F2",
                      p: 1,
                      fontWeight: "bold",
                      color: "#000",
                      my: 2,
                    }}
                    variant="subtitle1"
                  >
                    {college.collegeName} Youtube Videos
                  </Typography>
                  <ImageList
                    sx={{ width: "100%", height: 450 }}
                    cols={3}
                    rowHeight={164}
                  >
                    {youtubeMedia.map((item) => (
                      <ImageListItem>
                        <iframe
                          width="560"
                          height="315"
                          src={item.mediaFilePath}
                          frameborder="0"
                          title={college.collegeName}
                          allowfullscreen
                        ></iframe>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              ) : null}
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default Gallery;
