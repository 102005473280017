import React, { useEffect, useRef, useState } from "react";
import Header from "../../../general-components/ui-components/Header";
import Footer from "../../../general-components/ui-components/Footer";
import {
  Box,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Rating,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";

const SchoolDetails = () => {
  const { schoolId } = useParams();
  const [cookies] = useCookies();
  const [schoolFetching, setSchoolFetching] = useState(true);
  const [school, setSchool] = useState(null);

  const [activeTab, setActiveTab] = useState(0);
  const overviewRef = useRef(null);
  const feeStructureRef = useRef(null);
  const basicDetailsRef = useRef(null);
  const galleryRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    switch (newValue) {
      case 0:
        if (overviewRef.current) {
          overviewRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case 1:
        if (feeStructureRef.current) {
          feeStructureRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case 2:
        if (basicDetailsRef.current) {
          basicDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case 3:
        if (galleryRef.current) {
          galleryRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getSchool = async () => {
      let params = {
        id: schoolId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getSchool,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setSchool(response.data.school);
        } else {
          alert(
            "Something went wrong while getting the school Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the school Details. Please try again later!!"
        );
      }
    };
    if (schoolFetching) {
      getSchool();
      setSchoolFetching(false);
    }
  }, [cookies, schoolFetching]);
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: "#bdc3c7", py: 5 }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={4} component={Paper} sx={{ p: 2 }}>
              <img
                src={school?.schoolLogo}
                alt={school?.schoolName}
                // height="300px"
                width="100%"
                style={{ objectFit: "cover" }}
              />
            </Grid>
            <Grid item md={8} component={Paper} sx={{ p: 2 }}>
              <Typography variant="h5" component={"h1"}>
                {school?.schoolName}
              </Typography>
              <Typography variant="body1" component={"h2"} sx={{ mt: 1 }}>
                {school?.address ? school?.address + ", " : null}
                {school?.city ? school?.city + ", " : null}
                {school?.state ? school?.state + ", " : null}
                {school?.country}
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                5.0
                <Rating
                  name="read-only"
                  sx={{ fontSize: { md: "20px", xs: "8px" } }}
                  value={5}
                  readOnly
                />
                (542 Reviews)
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={4}>
                  <Typography variant="overline">Board</Typography>
                  <Typography variant="body1">{school?.schoolBoard}</Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography variant="overline">
                    Gender Classification
                  </Typography>
                  <Typography variant="body1">
                    {school?.schoolGenderClassification}
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography variant="overline">Established Year</Typography>
                  <Typography variant="body1">
                    {school?.establishedYear}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Tabs Section */}
          <Box
            sx={{
              bgcolor: "white",
              borderRadius: 2,
              boxShadow: 1,
              position: "sticky",
              top: 0,
              zIndex: 1,
              marginTop: 2,
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{
                "& .MuiTab-root": {
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                  "&.Mui-selected": {
                    color: "blue",
                  },
                },
              }}
            >
              <Tab label="Overview" />
              <Tab label="Fees Structure" />
              <Tab label="Basic Details" />
              {school?.schoolGalleryImages?.length > 0 && (
                <Tab label="Gallery" />
              )}
            </Tabs>
          </Box>

          {/* Tab Content */}
          <Box sx={{ p: 2, bgcolor: "white", mt: 2 }}>
            <div ref={overviewRef} style={{ paddingTop: "60px" }}>
              {" "}
              {/* Adjust padding as needed */}
              <Typography variant="h6">About {school?.schoolName}</Typography>
              <Typography variant="body1">
                <Box
                  dangerouslySetInnerHTML={{
                    __html: school?.description,
                  }}
                />
              </Typography>
            </div>

            <div ref={feeStructureRef} style={{ paddingTop: "60px" }}>
              {" "}
              {/* Adjust padding as needed */}
              <Typography variant="h6">Fees Structure</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Education Type</TableCell>
                      <TableCell>Fees</TableCell>
                      <TableCell>First Language</TableCell>
                      <TableCell>Second Language</TableCell>
                      <TableCell>Third Language</TableCell>
                      <TableCell>Subjects</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {school?.eduTypeDetails?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.educationType}</TableCell>
                        <TableCell>{item.educationTypeFee}</TableCell>
                        <TableCell>{item.firstLanguage}</TableCell>
                        <TableCell>{item.secondLanguage}</TableCell>
                        <TableCell>{item.thirdLanguage}</TableCell>
                        <TableCell>{item.subjects.join(", ")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div ref={basicDetailsRef} style={{ paddingTop: "60px" }}>
              <Typography variant="h6">Basic Details</Typography>

              <Typography variant="body1" sx={{ mt: 1 }}>
                <strong>Address:</strong> {school?.address}
              </Typography>
              <Typography variant="body1">
                <strong>City:</strong> {school?.city}
              </Typography>
              <Typography variant="body1">
                <strong>Pincode:</strong> {school?.pincode}
              </Typography>
              <Typography variant="body1">
                <strong>State:</strong> {school?.state}
              </Typography>
              <Typography variant="body1">
                <strong>Country:</strong> {school?.country}
              </Typography>
              <Typography variant="body1">
                <strong>Established Year:</strong> {school?.establishedYear}
              </Typography>
              <Typography variant="body1">
                <strong>Board:</strong> {school?.schoolBoard}
              </Typography>
              <Typography variant="body1">
                <strong>Gender Classification:</strong>{" "}
                {school?.schoolGenderClassification}
              </Typography>
            </div>

            {school?.schoolGalleryImages?.length > 0 && (
              <div ref={galleryRef} style={{ paddingTop: "60px" }}>
                {" "}
                {/* Adjust padding as needed */}
                <Typography variant="h6">School Gallery Images</Typography>
                <ImageList
                  sx={{ width: "100%", height: 450 }}
                  cols={3}
                  rowHeight={164}
                >
                  {school?.schoolGalleryImages?.map((item, index) => (
                    <ImageListItem key={index}>
                      <img
                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                        alt="gallery image"
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </div>
            )}
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default SchoolDetails;
