import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../general-components/ui-components/Header";
import Footer from "../../general-components/ui-components/Footer";
import SubscribeNewsLetter from "../home/home-sections/SubscribeNewsLetter";
import { emailValidation, mobileNoValidation } from "../../../common/common";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const ContactUs = () => {
  const backgroundImageUrl = "/contactUs.png";
  const [cookies] = useCookies();
  const [saveLoading, setSaveLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const emailValidate = emailValidation(email);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneHelperText, setPhoneHelperText] = useState("");
  const mobileValidate = mobileNoValidation(phone);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [messageHelperText, setMessageHelperText] = useState("");

  const cacheClear = () => {
    setSaveLoading(false);
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setMessageError(false);
    setNameHelperText("");
    setEmailHelperText("");
    setPhoneHelperText("");
    setMessageHelperText("");
  };

  const saveContactUs = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    let validate = true;
    if (!emailValidate) {
      setEmailError(true);
      setEmailHelperText("Please Enter Valid Email");
      validate = false;
      setSaveLoading(false);
    }
    if (!mobileValidate) {
      setPhoneError(true);
      setPhoneHelperText("Enter Valid Mobile Number");
      validate = false;
      setSaveLoading(false);
    }
    if (!name) {
      setNameError(true);
      setNameHelperText("Please Enter Your Name");
      validate = false;
      setSaveLoading(false);
    }
    if (!message) {
      setMessageError(true);
      setMessageHelperText("Please Enter Your Request");
      validate = false;
      setSaveLoading(false);
    }
    if (validate) {
      let params = {
        name: name,
        email: email,
        mobileNumber: phone,
        message: message,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.contactUs,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Our Team Will Get Back To You", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          cacheClear();
        } else {
          alert(
            "Something went wrong while saving the program Details. Please try again later!"
          );
          cacheClear();
        }
      } else {
        alert(
          "Something went wrong while saving the program Details. Please try again later!!"
        );
        cacheClear();
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          flexDirection: "column",
          height: "100%",
          minHeight: "100vh", // Set a minimum height for smaller screens
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black", // Color of the overlay
            opacity: 0.3, // Adjust the opacity of the overlay
          },
        }}
      >
        <Grid container sx={{ zIndex: "30", gap: "30px" }} maxWidth={"lg"}>
          <Grid
            item
            md={7}
            xs={10}
            sx={{ backgroundColor: "#fff", p: 5, m: { md: 0, xs: 5 } }}
          >
            <Box sx={{ maxWidth: "70%" }}>
              <Typography
                variant="h5"
                sx={{
                  color: "#F5374D",
                  fontSize: { xs: "18px" },
                }}
              >
                CONTACT US
              </Typography>
              <Typography sx={{ mt: 1, fontSize: { md: "18px", xs: "12px" } }}>
                Have a question? Need Some Help? Or Just want to say hello? We
                would love to hear from you..
              </Typography>
              <Box sx={{ mt: 2, fontSize: { md: "18px", xs: "12px" } }}>
                <Typography sx={{ fontSize: { xs: "12px", md: "18px" } }}>
                  We are Located at
                </Typography>
                <Typography sx={{ fontSize: { xs: "12px", md: "18px" } }}>
                  JP Nagar, 6th Phase, Bengaluru - 78
                </Typography>
              </Box>
              <Box sx={{ mt: 2, fontSize: { md: "18px", xs: "12px" } }}>
                <Typography sx={{ fontSize: { xs: "12px", md: "18px" } }}>
                  Drop in a Mail
                </Typography>
                <Typography sx={{ fontSize: { xs: "12px", md: "18px" } }}>
                  contact@getmystudies.com
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            xs={10}
            sx={{
              backgroundColor: "#fff",
              p: "5%",
              m: { md: 0, xs: 5 },
              marginTop: { xs: "-30px" },
            }}
          >
            <form onSubmit={saveContactUs} style={{ textAlign: "center" }}>
              <Typography variant="h5" sx={{ fontSize: { xs: "18px" } }}>
                GET IN TOUCH WITH US
              </Typography>
              <TextField
                placeholder="Name"
                fullWidth
                value={name}
                error={nameError}
                helperText={nameHelperText}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError(false);
                  setNameHelperText("");
                }}
                margin="dense"
              />
              <TextField
                placeholder="Email"
                fullWidth
                error={emailError}
                helperText={emailHelperText}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(false);
                  setEmailHelperText("");
                }}
                margin="dense"
              />
              <TextField
                placeholder="Mobile Number"
                fullWidth
                value={phone}
                error={phoneError}
                helperText={phoneHelperText}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setPhoneError(false);
                  setPhoneHelperText("");
                }}
                margin="dense"
              />
              <textarea
                placeholder="Enter Message....."
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setMessageError(false);
                  setMessageHelperText("");
                }}
                margin="dense"
                style={{
                  width: "100%",
                  marginTop: "15px",
                  padding: "10px", // Adjust the padding as needed
                  fontSize: "16px", // Adjust the font size as needed
                  color: "#333", // Placeholder color
                  resize: "none",
                  borderColor: messageError ? "red" : undefined,
                }}
                rows="4"
              />
              {messageError ? (
                <Typography sx={{ fontSize: "0.75rem", color: "#d32f2f" }}>
                  {messageHelperText}
                </Typography>
              ) : null}
              <LoadingButton
                loading={saveLoading}
                type="submit"
                loadingIndicator="Loading…"
                sx={{ p: "10px 15px" }}
                variant="contained"
              >
                <span>Submit</span>
              </LoadingButton>
            </form>
          </Grid>
        </Grid>
      </Box>
      {/* <Container sx={{ my: 3 }}> */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7778.120794684255!2d77.58560880000003!3d12.9038379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1704193805615!5m2!1sen!2sin"
        width="100%"
        height="350"
        title="get my studies"
        style={{ border: "0", my: 3 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
      {/* </Container> */}
      <SubscribeNewsLetter />
      <Footer />
    </>
  );
};

export default ContactUs;
