const actionTypes = {
  USER_GET_REQUEST: "USER_GET_REQUEST",
  USER_GET_SUCCESS: "USER_GET_SUCCESS",
  USER_GET_FAILURE: "USER_GET_FAILURE",

  LOGIN_DRAWER_REQUEST: "LOGIN_DRAWER_REQUEST",

  USER_LOGOUT: "USER_LOGOUT",

  RESET: "RESET",
};

export default actionTypes;
