import axios from "axios";

import { config } from "../config/config";

const packageJson = require("../../package.json");

export const invokeApi = async (url, params, cookies) => {
  try {
    let headers = {
      "Content-Type": "application/json",

      appversion: packageJson.version,

      platform: "web",
    };

    if (
      cookies &&
      cookies[config.cookieName] &&
      cookies[config.cookieName].token &&
      cookies[config.cookieName].loginUserId
    ) {
      headers.Authorization = "Bearer " + cookies[config.cookieName].token;

      headers.loginUserId = cookies[config.cookieName].loginUserId;
    }

    if (
      cookies &&
      cookies[config.sessionCookie] &&
      cookies[config.sessionCookie].sessionId
    ) {
      headers.sessionId = cookies[config.sessionCookie].sessionId;
    }

    return await axios.post(url, params, { headers: headers });
  } catch ({ response }) {
    return response;
  }
};

export const invokeFormDataApi = async (url, formData, cookies) => {
  try {
    let headers = {
      "Content-Type": "multipart/form-data",
      appversion: packageJson.version,
      platform: "web",
    };
    if (
      cookies &&
      cookies[config.cookieName] &&
      cookies[config.cookieName].token &&
      cookies[config.cookieName].loginUserId
    ) {
      headers.Authorization = "Bearer " + cookies[config.cookieName].token;
      headers.loginUserId = cookies[config.cookieName].loginUserId;
    }
    if (
      cookies &&
      cookies[config.sessionCookie] &&
      cookies[config.sessionCookie].sessionId
    ) {
      headers.sessionId = cookies[config.sessionCookie].sessionId;
    }
    return await axios.post(url, formData, { headers: headers });
  } catch ({ response }) {
    return response;
  }
};

export const apiList = {
  // login api
  userLogin: "/user/login",
  userSignUp: "/user/signup",
  userChangePwd: "/user/changePassword",
  // getmycollege service

  // university api
  getUniversities: "/universities/getUniversities",
  singleUniversity: "/universities/getUniversity",
  saveUniversity: "/universities/addUniversity",
  updateUniversity: "/universities/updateUniversity",

  // placement companies api
  getPlacementCompanies: "/universities/getPlacementCompanies",
  singlePlacementCompany: "/universities/getPlacementCompany",
  savePlacementCompany: "/universities/addPlacementCompany",
  updatePlacementCompany: "/universities/updatePlacementCompany",

  // college type api
  getCollegeTypes: "/courses/getCollegeTypes",
  singleCollegeType: "/courses/getCollegeType",
  saveCollegeType: "/courses/addCollegeType",
  updateCollegeType: "/courses/updateCollegeType",

  // insitute type api
  getInsituteType: "/courses/getInstituteTypes",
  singleInstituteType: "/courses/getInstituteType",
  saveInstituteType: "/courses/addInstituteType",
  updateInstituteType: "/courses/updateInstituteType",

  // training institute program apis
  getTrainingInstitutePrograms: "/courses/getTrainingInstitutePrograms",
  singleTrainingInstituteProgram: "/courses/getTrainingInstituteProgram",
  saveTrainingInstituteProgram: "/courses/addTrainingInstituteProgram",
  updateTrainingInstituteProgram: "/courses/updateTrainingInstituteProgram",

  // get country area maping api
  getAddress: "/courses/getCountryPincodeMapping",
  getAddressList: "/courses/getAddress",
  addAddress: "/courses/saveAddress",

  // amenitites api
  getAmenitites: "/courses/getAmenities",
  singleAmenities: "/courses/getAmenity",
  saveAmenities: "/courses/addAmenity",
  updateAmenities: "/courses/updateAmenity",

  // streams
  getPrograms: "/courses/getPrograms",
  updateProgram: "/courses/updateProgram",
  saveProgram: "/courses/addProgram",
  getProgram: "/courses/getProgram",

  // courses api
  getCourses: "/courses/getCourses",
  singleCourse: "/courses/getCourse",
  saveCourse: "/courses/addCourse",
  updateCourse: "/courses/updateCourse",
  getLeadHistory: "/courses/getLeadHistory",

  // college api
  getCollege: "/courses/getCollege",

  getColleges: "/courses/getColleges",

  saveCollege: "/courses/addCollege",
  updateCollege: "/courses/updateCollege",

  // institute brand api
  getInstituteBrands: "/courses/getInstituteBrands",
  singleIntituteBrand: "/courses/getInstituteBrand",
  saveInstituteBrand: "/courses/addInstituteBrand",
  updateInstituteBrand: "/courses/updateInstituteBrand",

  uploadFile: "/upload/uploadFile",
  uploadVideo: "/upload/uploadVideoFile",

  //Scholarship api
  getScholarship: "/universities/getScholarship",
  updateScholarship: "/universities/updateScholarship",
  saveScholarship: "/universities/addScholarship",
  getScholorShips: "/universities/getScholarships",

  //Leading api
  saveLead: "/universities/addLead",
  getLeads: "/universities/getLeads",
  updateLead: "/universities/updateLead",
  assignLeads: "/universities/assignLeads",
  getLead: "/universities/getLead",

  //users
  saveUser: "/user/addUser",
  getUsers: "/user/getUsers",
  updateUser: "/user/updateUser",
  getUser: "/user/getUser",
  updateUserRoles: "/user/updateUserRoles",

  // team leader Mapping
  getTeamLeaders: "/universities/getCollegeTeamLeaders",
  removeTeamLeaders: "/universities/removeCollegeTeamLeader",
  addCollegeTeamLeader: "/universities/addCollegeTeamLeader",
  getUnAssignedCollege: "/courses/getUnAssignedColleges",

  // marketing executive mapping
  getMarketingExecutiveMapping: "/universities/getCollegeMktngExecs",
  addMarketingExecutiveMapping: "/universities/addCollegeMktngExec",
  removeMarketingExecutiveMapping: "/universities/removeCollegeMktngExec",

  saveSpecialization: "/universities/addCourseSpecialization",
  getAllSpecialization: "/universities/getCourseSpecializations",
  getSpecialization: "/universities/getCourseSpecialization",
  updateSpecialization: "/universities/updateCourseSpecialization",

  saveExam: "/universities/addExam",
  updateExam: "/universities/updateExam",
  getExams: "/universities/getExams",
  getExam: "/universities/getExam",

  //Blogs
  getBlogs: "/blog/getBlogs",
  updateBlog: "/blog/updateBlog",
  getBlogCategories: "/blog/getBlogCategories",
  getBlogSubCategories: "/blog/getBlogSubCategories",

  getBlogCategoriesHierarchy: "/blog/getBlogCategoriesHierarchy",

  addBlog: "/blog/addBlog",
  getBlog: "/blog/getBlog",
  reviewBlog: "/blog/reviewBlog",
  addBlogCategory: "/blog/addBlogCategory",
  addBlogSubCategory: "/blog/addBlogSubCategory",
  updateBlogCategory: "/blog/updateBlogCategory",
  updateBlogSubCategory: "/blog/updateBlogSubCategory",

  searchCollege: "/courses/searchSuggestions",
  saveNewsLetter: "/user/addNewsletterSubscriber",
  contactUs: "/user/addContactUsData",

  addReview: "/universities/addCollegeReview",
  getReviews: "/universities/getCollegeReviews",
  updateReview: "/universities/updateCollegeReviews",

  // below the school apis

  addSchool: "/school/addSchool",
  getSchool: "/school/getSchool",
  getSchools: "/school/getSchools",
  updateSchool: "/school/updateSchool",
  changeSchoolStatus: "/school/changeSchoolStatus",
  getSchoolsBySearch: "/school/schoolsBySearch",

  // training institute apis
  addIntitute: "/training/addInstitute",
  getAllInstitutes: "/training/getTrainingInstitutes",
  getInstitute: "/training/getTrainingInstitute",
  changeInstituteStatus: "/training/changeStatus",
  editInstitute: "/training/updateTrainingInstitute",
  getInstitutesBySearch: "/training/trainingInstituteBySearch",
};
