import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress,
  DialogActions,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import SearchIcon from "@mui/icons-material/Search";
import { apiList, invokeApi } from "../../../services/apiServices";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";

const ExamsList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError, logout } = globalState.userReducer;

  const [examNamesList, setExamNamesList] = useState([]);
  const [examNamesListFilter, setExamNamesListFilter] = useState([]);

  const [search, setSearch] = useState("");

  const [isExamListFetching, setIsExamListFetching] = useState(true);

  const [examName, setExamName] = useState("");
  const [examNameHelperText, setExamNameHelperText] = useState("");
  const [examNameError, setExamNameError] = useState(false);

  const [isAddExamDialogOpen, setIsAddExamDialogOpen] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [id, setId] = useState("");

  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const openEditExam = () => {
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  const openAddExam = () => {
    setIsAddExamDialogOpen(!isAddExamDialogOpen);
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const handleClose = () => {
    setdeleteConfirmation(!deleteConfirmation);
  };

  const validateExam = () => {
    let validationErrors = false;
    if (!examName) {
      setExamNameHelperText("Please enter the course");
      setExamNameError(true);
      setSaveLoading(false);
      setUpdateLoading(false);
      validationErrors = true;
    }

    if (!validationErrors) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getExamsList = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getExams,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setExamNamesList(response.data.exams);
          setExamNamesListFilter(response.data.exams);
          setIsExamListFetching(false);
        } else {
          alert(
            "Something went wrong while getting Exam Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting Exam Details. Please try again later!!"
        );
      }
    };
    if (isExamListFetching) {
      getExamsList();
    }
  }, [cookies, isExamListFetching]);

  const saveExam = async () => {
    let validate = await validateExam();
    if (validate) {
      let params = {
        examName: examName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveExam,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Exam Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          openAddExam();
          setSaveLoading(false);
          setIsExamListFetching(true);
          setExamName("");
          setExamNameHelperText("");
          setExamNameError(false);
        } else if (response.data.responseCode === "GM003") {
          openAddExam(false);
          toast.info("Exam Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSaveLoading(false);
          setExamName("");
        } else {
          alert(
            "Something went wrong while saving the Exam Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while saving the Exam Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  const updateExam = async () => {
    var validationError = await validateExam();
    if (validationError) {
      let params = {
        id: id,
        examName: examName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateExam,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Exam Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateLoading(false);
          openEditExam();
          setIsExamListFetching(true);
          setExamName("");
          setExamNameError(false);
          setExamNameHelperText("");
        } else if (response.data.responseCode === "GM003") {
          openEditExam(false);
          toast.info("Exam Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateLoading(false);
          setExamName("");
        } else {
          alert(
            "Something went wrong while Edit Exam Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Edit Exam Details. Please try again later!!"
        );
      }
    }
  };

  const deleteExam = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateExam,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Exam Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsExamListFetching(true);
        handleClose();
      } else if (response.data.responseCode === "GM002") {
        handleClose();
        toast.info(
          "This Exam Mapped to Some College You Can't Delete This Exam!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setExamName("");
        setSaveLoading(false);
      } else {
        alert(
          "Something went wrong while delete Exam Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete Exam Details. Please try again later!!"
      );
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Exam Name",
      selector: (row) => row.examName,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setId(row.id);
                  setExamName(row.examName);
                  setIsEditDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setId(row.id);
                  setExamName(row.examName);
                  setIsEditDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setId(row.id);
                  setdeleteConfirmation(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = examNamesList?.filter((item) => {
      return item.examName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setExamNamesListFilter(result);
  }, [search]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CollegeMetadataTabs tabActive={8}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={() => setIsAddExamDialogOpen(true)}
        >
          <AddIcon></AddIcon>
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={examNamesListFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isExamListFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      {/* add Exam  */}
      <Dialog
        open={isAddExamDialogOpen}
        onClose={() => {
          openAddExam();
          setExamName("");
          setExamNameHelperText("");
          setExamNameError(false);
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            openAddExam();
            setExamName("");
            setExamNameHelperText("");
            setExamNameError(false);
          }}
        />
        <DialogTitle>Add New Exam</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Exam Name *"
            name="name"
            value={examName}
            onChange={(ev) => {
              setExamName(ev.target.value);
              setExamNameError(false);
              setExamNameHelperText("");
            }}
            helperText={examNameHelperText}
            error={examNameError}
          />

          <Button
            variant="contained"
            onClick={() => {
              setSaveLoading(true);
              saveExam();
            }}
            disabled={saveLoading}
          >
            Add Exam
            {saveLoading && <CircularProgress size={20} />}
          </Button>
        </DialogContent>
      </Dialog>
      {/* edit Exam  */}
      <Dialog
        open={isEditDialogOpen}
        onClose={() => {
          openEditExam();
          setExamName("");
          setUpdateLoading(false);
          setExamNameHelperText("");
          setExamNameError(false);
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            openEditExam();
            setUpdateLoading(false);
            setExamName("");
            setExamNameHelperText("");
            setExamNameError(false);
          }}
        />
        <DialogTitle>Edit Exam</DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            margin="normal"
            label="Exam Name *"
            name="name"
            value={examName}
            helperText={examNameHelperText}
            error={examNameError}
            onChange={(ev) => {
              setExamName(ev.target.value);
              setExamNameHelperText("");
              setExamNameError(false);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setUpdateLoading(true);
              updateExam();
            }}
            disabled={updateLoading}
          >
            Save Changes
            {updateLoading && <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteModal
        DeleteFunction={deleteExam}
        handleIsDeleteModal={handleClose}
        Open={deleteConfirmation}
      />
    </>
  );
};

export default ExamsList;
