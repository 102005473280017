import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  DialogActions,
  Card,
  Typography,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  FormHelperText,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DashboardNav from "../home/DashboardNav";
import DashboardHeader from "../home/DashboardHeader";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import { useEffect } from "react";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";
import Pagination from "@mui/material/Pagination";
import { useSelector } from "react-redux";

const LeadsList = () => {
  const [cookies] = useCookies();
  const [openAssignModal, setOpenAssignModal] = useState(false);

  const globalState = useSelector((state) => state);
  const { userData } = globalState.userReducer;
  // selected id's for assigning
  const [checkedIds, setCheckedIds] = useState([]);

  const [leadList, setLeadList] = useState([]);
  const [leadFilteredList, setLeadFilteredList] = useState([]);
  const [usersData, setUsersData] = useState(null);

  const [leadStartDate, setLeadStartDate] = useState(null);
  const [leadEndDate, setLeadEndDate] = useState(null);

  const [leadDetailsDialogOpen, setLeadDetailsDialogOpen] = useState(false);

  const [fakeModalOpen, setFakeModalOpen] = useState(false);
  const [convertedModalOpen, setConvertedModalOpen] = useState(false);
  const [closedModalOpen, setClosedModalOpen] = useState(false);
  const [nextFollowUpModalOpen, setNextFollowModalOpen] = useState(false);

  const [nextFollowupDate, setNextFollowupDate] = useState(null);
  const [nextFollowupDateError, setNextFollowDateError] = useState(false);
  const [nextFollowDateHelperText, setNextFollowDateHelperText] = useState("");
  const [callNotes, setCallNotes] = useState("");
  const [callNotesError, setCallNotesError] = useState(false);
  const [callNotesHelperText, setCallNotesHelperText] = useState("");
  const [leadId, setLeadId] = useState(null);
  const [leadData, setLeadData] = useState(null);
  const [leadDataFetching, setLeadDataFetching] = useState(false);

  const [leadStatus, setLeadStatus] = useState("");

  const [leadsHistory, setLeadsHistory] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);

  const offset = 0;
  const [totalLeads, setTotalLeads] = useState(0);
  const [leadsFectching, setLeadsFetching] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filtersModal, setFiltersModal] = useState(false);
  const [usersFetching, setUsersFetching] = useState(true);
  const [leadType, setLeadType] = useState(null);
  const [leadSource, setLeadSource] = useState(null);
  const [assignedFlag, setAssignedFlag] = useState(false);
  const [assignedToId, setAssignedToId] = useState(null);

  const [assingnigLeadIds, setAssigningLeadIds] = useState([]);
  const [assigningId, setAssigningId] = useState(null);

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [leadIdsError, setLeadIdsError] = useState(false);
  const [leadIdsHelperText, setLeadIdsHelperText] = useState("");

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setLeadsFetching(true);
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setLeadsFetching(true);
    setLimit(newSize);
    setCurrentPage(1);
  };

  const handleCheckboxChange = (id) => {
    setAssigningLeadIds((prevIds) => {
      const isChecked = prevIds.includes(id);
      if (isChecked) {
        return prevIds.filter((checkedId) => checkedId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  useEffect(() => {
    const getLeads = async () => {
      let params = {
        offset: (currentPage - 1) * limit,
        limit,
        leadStartDate: leadStartDate,
        leadEndDate: leadEndDate,
        leadType: leadType,
        leadSource: leadSource,
        assignedTo: assignedToId,
        unAssignedFlag: assignedFlag,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getLeads,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setIsSubmiting(false);
          setLeadsFetching(false);
          setLeadList(response.data.leads);
          setTotalLeads(response.data.leadsCount);
          setLeadFilteredList(response.data.leads);
          setFiltersModal(false);
          setFilterName("");
        } else {
          alert(
            "Something went wrong while getting Leads Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting Leads Details. Please try again later!!"
        );
      }
    };

    const getUsers = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getUsers,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setUsersData(response.data.users);
          if (userData?.user?.roles?.indexOf("Admin")) {
            console.log("inside");
          }
          setUsersFetching(false);
        } else {
          alert(
            "Something went wrong while getting User Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting User Details. Please try again later!!"
        );
      }
    };

    if (leadsFectching) {
      getLeads();
    }
    if (
      (usersFetching && userData?.users?.roles?.includes("Admin")) ||
      userData?.users?.roles?.includes("Team Leader") ||
      userData?.users?.roles?.includes("College Admin")
    ) {
      getUsers();
    }
  }, [cookies, currentPage, limit, offset, leadsFectching, usersFetching]);

  useEffect(() => {
    const getLead = async () => {
      let params = {
        id: leadId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getLead,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setLeadData(response.data.lead);
          setLeadDataFetching(false);
        } else {
          alert(
            "Something went wrong while getting Lead Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting Lead Details. Please try again later!!"
        );
      }
    };
    if (leadDataFetching) {
      getLead();
    }
  }, [leadDataFetching]);

  useEffect(() => {
    const getLeadHistory = async () => {
      let params = {
        leadId: leadId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getLeadHistory,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setLeadsHistory(response.data.leadHistory);
          setLeadDataFetching(false);
        } else {
          alert(
            "Something went wrong while getting Lead History. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting Lead History. Please try again later!!"
        );
      }
    };
    if (leadDataFetching) {
      getLeadHistory();
    }
  }, [leadDataFetching]);

  const updateLead = async () => {
    setIsSubmiting(true);
    let params = {
      id: leadId,
      status: leadStatus ? leadStatus : null,
      callNotes: callNotes,
      nextFollowupDate: nextFollowupDate,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateLead,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        setFakeModalOpen(false);
        setConvertedModalOpen(false);
        setClosedModalOpen(false);
        setNextFollowModalOpen(false);
        setLeadsFetching(true);
        setLeadDataFetching(false);
        setCallNotes("");
        setCallNotesError(false);
        setCallNotesHelperText("");
        setLeadStatus("");
        setNextFollowupDate("");
        setIsSubmiting(false);
      } else {
        alert(
          "Something went wrong while getting update Details. Please try again later!"
        );
      }
    } else {
      alert(
        "Something went wrong while getting update Details. Please try again later!!"
      );
    }
  };

  const assignLeads = async () => {
    setIsSubmiting(true);
    let params = {
      userId: assigningId,
      leads: assingnigLeadIds,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.assignLeads,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        setOpenAssignModal(false);
        setLeadsFetching(true);
        setAssigningLeadIds([]);
      } else {
        alert(
          "Something went wrong while assigning the leads. Please try again later!"
        );
      }
    } else {
      alert(
        "Something went wrong while assigning the leads. Please try again later!!"
      );
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{ flexGrow: 1, p: 2, backgroundColor: "#F6F8FB" }}
        >
          <DashboardHeader />

          {userData?.users?.roles?.includes("Admin") ||
          userData?.users?.roles?.includes("Team Leader") ||
          userData?.users?.roles?.includes("College Admin") ? (
            <Button
              variant="contained"
              onClick={() => {
                setOpenAssignModal(true);
              }}
              sx={{
                margin: "15px",
              }}
              disabled={!assingnigLeadIds.length > 0}
            >
              Assign
            </Button>
          ) : (
            <></>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "90%",
            }}
          >
            {!leadsFectching ? (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">
                          <Typography>ID</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Full Name</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Mobile Number</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Email</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Courses</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Assigned to</Typography>

                          {userData?.users?.roles?.includes("Admin") ||
                          userData?.users?.roles?.includes("Team Leader") ||
                          userData?.users?.roles?.includes("College Admin") ? (
                            <IconButton
                              size="small"
                              aria-label="filter"
                              aria-controls="filter-popup"
                              aria-haspopup="true"
                              onClick={() => {
                                setFilterName("Assigned To");
                                setFiltersModal(true);
                              }}
                            >
                              <FilterAltIcon />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Lead Source</Typography>
                          <IconButton
                            size="small"
                            aria-label="filter"
                            aria-controls="filter-popup"
                            aria-haspopup="true"
                            onClick={() => {
                              setFilterName("Lead Source");
                              setFiltersModal(true);
                            }}
                          >
                            <FilterAltIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Lead Type</Typography>

                          <IconButton
                            size="small"
                            aria-label="filter"
                            aria-controls="filter-popup"
                            aria-haspopup="true"
                            onClick={() => {
                              setFilterName("Lead Type");
                              setFiltersModal(true);
                            }}
                          >
                            <FilterAltIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>Lead Date</Typography>
                          <IconButton
                            onClick={() => {
                              setFilterName("Lead Date");
                              setFiltersModal(true);
                            }}
                            size="small"
                            aria-label="filter"
                            aria-controls="filter-popup"
                            aria-haspopup="true"
                          >
                            <FilterAltIcon />
                          </IconButton>
                        </TableCell>

                        <TableCell align="center">
                          <Typography>Action</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leadFilteredList?.map((leads, idx) => (
                        <TableRow key={leads.id}>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #ddd",
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            <Checkbox
                              onChange={() => handleCheckboxChange(leads.id)}
                              checked={assingnigLeadIds.includes(leads.id)}
                            />
                          </TableCell>
                          <TableCell>{leads.id}</TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              textDecoration: "underLine",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setLeadId(leads.id);
                              setLeadDetailsDialogOpen(true);
                              setLeadDataFetching(true);
                            }}
                          >
                            {" "}
                            {leads.studentName}
                          </TableCell>
                          <TableCell align="center">
                            {leads.mobileNumber}
                          </TableCell>
                          <TableCell align="center"> {leads.email}</TableCell>
                          <TableCell align="center">
                            {leads.courseNames.map((name) => name + ", ")}
                          </TableCell>
                          <TableCell align="center">{leads.name}</TableCell>
                          <TableCell align="center">
                            {leads.leadSource}
                          </TableCell>
                          <TableCell align="center">{leads.leadType}</TableCell>
                          <TableCell>
                            {new Date(leads.createdDate).getDate() +
                              "-" +
                              (new Date(leads.createdDate).getMonth() + 1) +
                              "-" +
                              new Date(leads.createdDate).getFullYear()}
                          </TableCell>
                          <TableCell align="center">
                            <TableCell align="center">
                              <Stack direction="column" spacing={1}>
                                <Chip
                                  label="Fake"
                                  variant="Contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setLeadId(leads.id);
                                    setLeadStatus("Fake");
                                    setFakeModalOpen(true);
                                  }}
                                />
                                <Chip
                                  label="Converted"
                                  variant="Contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setLeadId(leads.id);
                                    setLeadStatus("Converted");
                                    setConvertedModalOpen(true);
                                  }}
                                />
                                <Chip
                                  label="Not Available"
                                  variant="Contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    setLeadId(leads.id);
                                    setLeadStatus("Not Available");
                                    setClosedModalOpen(true);
                                  }}
                                />
                              </Stack>
                            </TableCell>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "15px",
                  }}
                >
                  <Pagination
                    count={Math.ceil(totalLeads / limit)}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                  Rows per page:
                  <Select
                    value={limit}
                    onChange={handlePageSizeChange}
                    style={{
                      fontSize: "0.8rem",
                      width: "80px",
                      height: "30px",
                    }}
                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                  >
                    <MenuItem value={5} style={{ fontSize: "0.8rem" }}>
                      5
                    </MenuItem>
                    <MenuItem value={10} style={{ fontSize: "0.8rem" }}>
                      10
                    </MenuItem>
                    <MenuItem value={15} style={{ fontSize: "0.8rem" }}>
                      15
                    </MenuItem>
                    <MenuItem value={20} style={{ fontSize: "0.8rem" }}>
                      20
                    </MenuItem>
                    <MenuItem value={25} style={{ fontSize: "0.8rem" }}>
                      25
                    </MenuItem>
                  </Select>
                </Box>
              </>
            ) : (
              <>
                {" "}
                <CircularProgress sx={{ width: "auto" }} />
              </>
            )}
          </Box>
        </Box>
      </Box>

      {/* Assigning Modal  */}
      <Dialog
        open={openAssignModal}
        onClose={() => {
          setOpenAssignModal(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          Assign
          <IconButton
            style={{ marginLeft: "250px" }}
            edge="end"
            color="inherit"
            onClick={() => {
              setOpenAssignModal(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <FormControl variant="standard" sx={{ width: "150px" }}>
              <InputLabel>Assigned To </InputLabel>
              <Select
                label="Lead Type"
                value={assigningId}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
                onChange={(event) => {
                  setAssigningId(event.target.value);
                }}
              >
                {usersData?.map((el, id) => (
                  <MenuItem
                    value={el.id}
                    onClick={() => {
                      setAssigningId(el.id);
                    }}
                  >
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
              {leadIdsError && (
                <FormHelperText error>{leadIdsHelperText}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmiting && assigningId}
              onClick={() => {
                if (assingnigLeadIds.length > 0) {
                  assignLeads();
                } else {
                  setLeadIdsError(true);
                  setLeadIdsHelperText("Please select atleast one lead");
                }
              }}
            >
              Submit
              {isSubmiting ? (
                <CircularProgress size={24} sx={{ ml: 2 }} />
              ) : (
                <></>
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Filters Modal */}
      <Dialog
        open={filtersModal}
        onClose={() => {
          setFiltersModal(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          Apply Filters
          <IconButton
            style={{ marginLeft: "250px" }}
            edge="end"
            color="inherit"
            onClick={() => {
              setFiltersModal(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {filterName === "Lead Date" && (
            <>
              <label>Start Date</label>
              <TextField
                variant="outlined"
                fullWidth
                type="date"
                value={leadStartDate}
                onChange={(event) => {
                  setLeadStartDate(event.target.value);
                }}
              />
              <label>End Date</label>
              <TextField
                variant="outlined"
                fullWidth
                type="date"
                value={leadEndDate}
                onChange={(event) => {
                  setLeadEndDate(event.target.value);
                }}
              />
            </>
          )}
          {filterName === "Lead Type" && (
            <>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="standard" sx={{ width: "150px" }}>
                  <InputLabel>Lead Type </InputLabel>
                  <Select
                    label="Lead Type"
                    value={leadType}
                    onChange={(ev) => {
                      setLeadType(ev.target.value);
                    }}
                  >
                    <MenuItem value={"Own"}>Own</MenuItem>
                    <MenuItem value={"College"}>College</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          )}

          {filterName === "Lead Source" && (
            <>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="standard" sx={{ width: "150px" }}>
                  <InputLabel>Lead Source </InputLabel>
                  <Select
                    label="Lead Type"
                    value={leadSource}
                    onChange={(ev) => {
                      setLeadSource(ev.target.value);
                    }}
                  >
                    <MenuItem value={"Online"}>Online</MenuItem>
                    <MenuItem value={"Offline"}>Offline</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          )}

          {filterName === "Assigned To" && (
            <>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControl variant="standard" sx={{ width: "150px" }}>
                  <InputLabel>Assigned To </InputLabel>
                  <Select
                    label="Lead Type"
                    value={assignedToId}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value="True"
                      onClick={() => {
                        setAssignedFlag(true);
                      }}
                    >
                      None
                    </MenuItem>

                    {usersData.map((el, id) => (
                      <MenuItem
                        value={el.id}
                        onClick={() => {
                          setAssignedToId(el.id);
                        }}
                      >
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmiting}
              onClick={() => {
                setLeadsFetching(true);
                setIsSubmiting(true);
                setFiltersModal(false);
              }}
            >
              Submit
              {isSubmiting ? (
                <CircularProgress size={24} sx={{ ml: 2 }} />
              ) : (
                <></>
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Lead Details Dialog */}
      <Dialog
        open={leadDetailsDialogOpen}
        PaperProps={{
          style: {
            width: "90%",
            maxWidth: "none",
            height: "90%",
            maxHeight: "none",
          },
        }}
      >
        <DialogTitle id="success-title" sx={{ textAlign: "center" }}>
          Lead Details{" "}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              margin: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                margin: "10px",
              }}
              onClick={() => {
                setLeadStatus("Fake");
                setFakeModalOpen(true);
              }}
            >
              Fake
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "10px",
              }}
              onClick={() => {
                setLeadStatus("Converted");
                setConvertedModalOpen(true);
              }}
            >
              Converted
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "10px",
              }}
              onClick={() => {
                setLeadStatus("Not Available");
                setClosedModalOpen(true);
              }}
            >
              Not Available
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "10px",
              }}
              onClick={() => {
                setNextFollowModalOpen(true);
              }}
            >
              Next Follow Update
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "90%",
            }}
          >
            {leadDataFetching ? (
              <CircularProgress sx={{ width: "auto" }} />
            ) : (
              <Card
                variant="outlined"
                sx={{
                  width: "85%",
                  margin: "auto",
                  maxHeight: "90%",
                  overflowY: "auto",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="Body1" sx={{ mb: 1 }}>
                      Student Name: {leadData?.studentName}
                    </Typography>
                    <Typography variant="Body1" sx={{ mb: 1 }}>
                      Mobile Number: {leadData?.mobileNumber}
                    </Typography>
                    {leadData?.createdDate && (
                      <Typography variant="Body1" sx={{ mb: 1 }}>
                        Created Date:{" "}
                        {format(new Date(leadData?.createdDate), "dd-MMM-yyyy")}{" "}
                        {format(parseISO(leadData?.createdDate), "hh:mm a")}
                      </Typography>
                    )}
                    <Typography variant="Body1" sx={{ mb: 1 }}>
                      Status: {leadData?.status}
                    </Typography>
                    <Typography variant="Body1" sx={{ mb: 1 }}>
                      Payment Status: {leadData?.paymentStatus}
                    </Typography>
                  </Box>
                  {leadsHistory?.length > 0 ? (
                    <>
                      <Typography variant="h5">Comments:</Typography>
                      {leadsHistory.map((el, id) => (
                        <>
                          {el.statusChangeFrom && el.statusChangeTo && (
                            <>
                              <Typography
                                variant="Body1"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  mb: 1,
                                }}
                              >
                                {el?.createdDate && (
                                  <>
                                    {format(
                                      new Date(el?.createdDate),
                                      "dd-MMM-yyyy"
                                    )}{" "}
                                    {format(
                                      parseISO(el?.createdDate),
                                      "hh:mm a"
                                    )}
                                  </>
                                )}
                                <br></br>
                                Status has been changed to {
                                  el.statusChangeTo
                                }{" "}
                                from {el.statusChangeFrom}
                              </Typography>
                              <hr></hr>
                            </>
                          )}

                          {el.callNotes && (
                            <>
                              <Typography
                                variant="Body1"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  mb: 1,
                                }}
                              >
                                {el?.createdDate && (
                                  <>
                                    {format(
                                      new Date(el?.createdDate),
                                      "dd-MMM-yyyy"
                                    )}{" "}
                                    {format(
                                      parseISO(el?.createdDate),
                                      "hh:mm a"
                                    )}
                                  </>
                                )}
                                <br></br>
                                {el.callNotes}
                                <br></br>
                                {el.nextFollowupDate && (
                                  <>
                                    Next Followup Date:
                                    {format(
                                      new Date(el?.nextFollowupDate),
                                      "dd-MMM-yyyy"
                                    )}{" "}
                                    {format(
                                      parseISO(el?.nextFollowupDate),
                                      "hh:mm a"
                                    )}
                                  </>
                                )}
                              </Typography>
                              <hr></hr>
                            </>
                          )}

                          {el.assignedByName && (
                            <>
                              <Typography
                                variant="Body1"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  mb: 1,
                                }}
                              >
                                {format(
                                  new Date(el?.assignedDate),
                                  "dd-MMM-yyyy"
                                )}{" "}
                                {format(parseISO(el?.assignedDate), "hh:mm a")}
                                <br></br>
                                {leadData?.studentName} is Assigned by{" "}
                                {el.assignedByName}
                              </Typography>
                              <hr></hr>
                            </>
                          )}

                          {el.assignToName && (
                            <>
                              <Typography
                                variant="Body1"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  mb: 1,
                                }}
                              >
                                {el?.createdDate && (
                                  <>
                                    {format(
                                      new Date(el?.createdDate),
                                      "dd-MMM-yyyy"
                                    )}{" "}
                                    {format(
                                      parseISO(el?.createdDate),
                                      "hh:mm a"
                                    )}
                                  </>
                                )}
                                <br></br>
                                {leadData?.studentName} is Assigned to{" "}
                                {el.assignToName}
                              </Typography>
                              <hr></hr>
                            </>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </CardContent>
              </Card>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLeadDetailsDialogOpen(false);
            }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>

      {/* Fake Dialog */}
      <Dialog open={fakeModalOpen}>
        <DialogTitle id="success-title">Fake </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Call Notes *"
              value={callNotes}
              onChange={(ev) => {
                setCallNotes(ev.target.value);
              }}
              error={callNotesError}
              helperText={callNotesHelperText}
              multiline
              rows={4}
              style={{ width: 400 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFakeModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmiting}
            onClick={() => {
              if (callNotes) {
                updateLead();
              } else {
                setCallNotesError(true);
                setCallNotesHelperText("Please enter the notes");
              }
            }}
          >
            Submit
            {isSubmiting ? (
              <CircularProgress size={24} sx={{ ml: 2 }} />
            ) : (
              <></>
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Convert Dialog */}
      <Dialog open={convertedModalOpen}>
        <DialogTitle id="success-title">Converted </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Call Notes *"
              value={callNotes}
              onChange={(ev) => {
                setCallNotes(ev.target.value);
              }}
              error={callNotesError}
              helperText={callNotesHelperText}
              multiline
              rows={4}
              style={{ width: 400 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConvertedModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmiting}
            onClick={() => {
              if (callNotes) {
                updateLead();
              } else {
                setCallNotesError(true);
                setCallNotesHelperText("Please enter the notes");
              }
            }}
          >
            Submit
            {isSubmiting ? (
              <CircularProgress size={24} sx={{ ml: 2 }} />
            ) : (
              <></>
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Not Available Dialog */}
      <Dialog open={closedModalOpen}>
        <DialogTitle id="success-title"> Not Available</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Call Notes *"
              value={callNotes}
              onChange={(ev) => {
                setCallNotes(ev.target.value);
              }}
              error={callNotesError}
              helperText={callNotesHelperText}
              multiline
              rows={4}
              style={{ width: 400 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setClosedModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmiting}
            onClick={() => {
              if (callNotes) {
                updateLead();
              } else {
                setCallNotesError(true);
                setCallNotesHelperText("Please enter the notes");
              }
            }}
          >
            Submit
            {isSubmiting ? (
              <CircularProgress size={24} sx={{ ml: 2 }} />
            ) : (
              <></>
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Followup Dailog */}
      <Dialog open={nextFollowUpModalOpen}>
        <DialogTitle id="success-title"> </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Select Date"
                value={nextFollowupDate}
                onChange={(newValue) => {
                  setNextFollowupDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                sx={{ margin: "5px" }}
              />
              <TextField
                id="outlined-multiline-flexible"
                label="Call Notes *"
                value={callNotes}
                onChange={(ev) => {
                  setCallNotes(ev.target.value);
                }}
                error={callNotesError}
                helperText={callNotesHelperText}
                multiline
                rows={4}
                style={{ width: 400 }}
                sx={{ margin: "5px" }}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNextFollowModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmiting}
            onClick={() => {
              if (nextFollowupDate && callNotes) {
                updateLead();
              } else {
                setCallNotesError(true);
                setCallNotesHelperText("Please enter the call notes");
              }
            }}
          >
            Submit
            {isSubmiting ? (
              <CircularProgress size={24} sx={{ ml: 2 }} />
            ) : (
              <></>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeadsList;
